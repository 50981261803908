import { IRetailer } from '@prf/shared/domain';

export class LoadRetailers {
  static type = '[Retailers] LoadRetailers';
  constructor() {}
}

export class UpdateRetailer {
  static type = '[Retailers] Update Retailer';
  constructor(public payload: { entity: IRetailer }) {}
}

export class CreateRetailer {
  static type = '[Retailers] Create New Retailer';
  constructor(public payload: { entity: IRetailer }) {}
}
