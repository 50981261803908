import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { EIngredientUnit, IIngredient, PerfoEntityType } from "@prf/shared/domain";
import {
  CreateIngredient,
  LoadIngredients,
  UpdateIngredient,
} from './ingredients.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateIngredientMutation,
  CreateIngredientMutationVariables,
  CreateIngredientService,
  GetAllIngredientsService,
  IngredientFieldsFragment,
  UpdateIngredientService,
} from '../../graphql/ingredients-operations.generated';
import { MutationResult } from 'apollo-angular';
import { UpdateIngredientInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';

type LocalModel = IIngredient;
type LocalStateModel = IngredientsStateModel;
type LocalStateContext = StateContext<IngredientsStateModel>;

export interface IngredientsStateModel {
  items: LocalModel[];
}

@State<IngredientsStateModel>({
  name: 'ingredients',
  defaults: {
    items: [],
  },
})
@Injectable()
export class IngredientsState
  extends BaseEntityState<
    {
      fetchAll: GetAllIngredientsService;
      create: CreateIngredientService;
      update: UpdateIngredientService;
    },
    IIngredient
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'ingredient';

  constructor(
    protected getAllIngredientsService: GetAllIngredientsService,
    protected createIngredientService: CreateIngredientService,
    protected updateIngredientService: UpdateIngredientService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllIngredientsService,
        create: createIngredientService,
        update: updateIngredientService,
      },
      toastService,
    );
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  protected getLoadAction(): object {
    return new LoadIngredients();
  }

  protected mapGqlEntityToUiEntity(
    entity: IngredientFieldsFragment,
  ): IIngredient {
    return {
      id: entity.id,
      name: entity.name,
      unit: EIngredientUnit[entity.unit as keyof typeof EIngredientUnit],
    };
  }

  @Action(LoadIngredients)
  protected loadIngredients(
    ctx: LocalStateContext,
    action: LoadIngredients,
  ): Observable<any> {
    return this.loadEntities<IngredientFieldsFragment[]>(ctx, 'ingredients');
  }

  @Action(CreateIngredient)
  createIngredient(
    ctx: LocalStateContext,
    action: CreateIngredient,
  ): Observable<MutationResult<CreateIngredientMutation>> {
    return this.createEntity<CreateIngredientMutationVariables>(
      ctx,
      action.payload.entity,
      'createIngredient',
    );
  }

  @Action(UpdateIngredient)
  updateIngredient(
    ctx: LocalStateContext,
    action: UpdateIngredient,
  ): Observable<any> {
    return this.updateEntity<UpdateIngredientInput>(
      ctx,
      action.payload.entity,
      'updateIngredient',
    );
  }
}
