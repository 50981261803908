import {
  createPerfoTableConfig,
  PerfoDataTableCellRenderType,
} from '../../shared/components/data-table/data-table.component';
import { IRetailer } from '@prf/shared/domain';

export const RetailersTableConfig = createPerfoTableConfig<IRetailer>([
  {
    header: 'Name',
    field: 'retailerName',
    sortable: true,
  },
  {
    header: 'Händler-Nr.',
    field: 'retailerNumber',
    sortable: true,
    customRender: { monoFont: true },
  },
  // {
  //   header: 'Rabatt (%)',
  //   field: 'discount',
  //   sortable: true,
  //   customRender: { monoFont: true },
  // },
  {
    header: 'E-Mail',
    field: 'invoiceEmail',
    sortable: false,
  },
  {
    header: 'Telefon',
    field: 'phone',
    sortable: false,
  },
  {
    header: 'Rechnungsadresse',
    field: 'billingAddress',
    sortable: false,
    customRender: { renderType: PerfoDataTableCellRenderType.ADDRESS },
  },
  {
    header: 'GLN',
    field: 'gln',
    sortable: true,
    customRender: { monoFont: true },
  },
]);
