import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { ButtonModule } from 'primeng/button';
import { Select, Store } from '@ngxs/store';
import { ToggleSidebar } from '../../../../state/ui.actions';
import { Observable } from 'rxjs';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem } from 'primeng/api';
import { RouteState } from '../../../../state/route.state';
import { DialogModule } from 'primeng/dialog';
import {
  HeaderViewModel,
  HeaderViewModelQueries,
} from '../../../../view-models/header-view-model.queries';
import { CreateEntityDialogService } from '../../../../shared/services/dialogs/create-entity-dialog/create-entity-dialog.service';
import { PerfoEntityType } from '@prf/shared/domain';
import { PerfoHotkeysService } from '../../../../shared/services/hotkeys/perfo-hotkeys.service';

@Component({
  selector: 'prf-layout-header',
  standalone: true,
  imports: [
    CommonModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    DialogModule,
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  // TODO: Refactor: Change into @Input() - header should be dumb. Consider vm$ observable.
  // TODO:           -------> put routeName into viewModel!
  @Select(RouteState.routeName) routeName$!: Observable<string>;

  @Select(HeaderViewModelQueries.getViewModel)
  viewModel$!: Observable<HeaderViewModel>;

  addEntityButtonItems: MenuItem[];

  readonly DEFAULT_ADD_NEW_ENTITY_TYPE: PerfoEntityType = 'product';

  readonly ADD_ENTITY_BUTTON_CONFIG: {
    label: string;
    entityType: PerfoEntityType;
    hotkey: string;
  }[] = [
    { label: 'Neue Lieferung', entityType: 'delivery', hotkey: 'l' },
    { label: 'Neuer Markt', entityType: 'market', hotkey: 'm' },
    { label: 'Neuer Händler', entityType: 'retailer', hotkey: 'h' },
    { label: 'Neuer Artikel', entityType: 'product', hotkey: 'a' },
    { label: 'Neue Rohware', entityType: 'ingredient', hotkey: 'z' },
    { label: 'Neuer Benutzer', entityType: 'user', hotkey: 'b' },
    { label: 'Neues Fahrzeug', entityType: 'vehicle', hotkey: 'f' },
  ];

  constructor(
    private createEntityDialogService: CreateEntityDialogService,
    private hotkeysService: PerfoHotkeysService,
    private store: Store
  ) {
    this.addEntityButtonItems = this.ADD_ENTITY_BUTTON_CONFIG.map(
      ({ label, entityType }) => ({
        label,
        command: () => {
          this.onClickNewEntity(entityType);
        },
      })
    );

    this.initCreateEntityHotkeys();
  }

  onClickSidebarToggle(): void {
    this.store.dispatch(new ToggleSidebar());
  }

  onClickDefaultNew(entityType: PerfoEntityType | null): void {
    let newEntityType = this.DEFAULT_ADD_NEW_ENTITY_TYPE;

    const IGNORE_FOR_ENTITY_TYPES: PerfoEntityType[] = ['invoice'];
    if (entityType && IGNORE_FOR_ENTITY_TYPES.includes(entityType)) {
      // Keep ignored entity type mapped to default.
    } else if (entityType) {
      newEntityType = entityType;
    }

    this.onClickNewEntity(newEntityType);
  }

  onClickNewEntity(entityType: PerfoEntityType): void {
    this.createEntityDialogService.showCreateDialogForType(entityType);
  }

  private initCreateEntityHotkeys() {
    // TODO: hotkey "nn" -> new entity for current view, ie Artikel is open -> nn leads to open Artikel dialog (n>a).

    this.ADD_ENTITY_BUTTON_CONFIG.forEach((buttonConfig) => {
      if (buttonConfig.hotkey) {
        this.hotkeysService.addHotkeyWithCallback(
          `n>${buttonConfig.hotkey}`,
          () => {
            this.onClickNewEntity(buttonConfig.entityType);
          }
        );
      }
    });
  }
}
