import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UserFieldsFragment = { __typename?: 'UserEntity', id: number, firstName: string, lastName: string, role: Types.EUserRole, status: Types.EUserStatus, username: string, createdAt?: any | null, updatedAt?: any | null };

export type GetAllUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'UserEntity', id: number, firstName: string, lastName: string, role: Types.EUserRole, status: Types.EUserStatus, username: string, createdAt?: any | null, updatedAt?: any | null }> };

export type CreateUserMutationVariables = Types.Exact<{
  input: Types.CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserEntity', id: number, firstName: string, lastName: string, role: Types.EUserRole, status: Types.EUserStatus, username: string, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserEntity', id: number, firstName: string, lastName: string, role: Types.EUserRole, status: Types.EUserStatus, username: string, createdAt?: any | null, updatedAt?: any | null } };

export const UserFieldsFragmentDoc = gql`
    fragment UserFields on UserEntity {
  id
  firstName
  lastName
  role
  status
  username
  createdAt
  updatedAt
}
    `;
export const GetAllUsersDocument = gql`
    query GetAllUsers {
  users {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllUsersService extends Apollo.Query<GetAllUsersQuery, GetAllUsersQueryVariables> {
    override document = GetAllUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(createUserInput: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserService extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    override document = CreateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
  updateUser(id: $id, updateUserInput: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserService extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    override document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }