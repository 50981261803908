import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IMarket } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { MarketsTableConfig } from './table.config';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from "rxjs";
import {
  MarketsPageViewModel,
  MarketsPageViewModelQueries,
} from '../../view-models/market-view-model.queries';
import { ProductDetailsComponent } from '../../shared/components/product/product-details/product-details.component';
import { RecipeEditorComponent } from '../products/components/recipe-editor/recipe-editor.component';
import { TabViewModule } from 'primeng/tabview';
import { MarketDetailsComponent } from '../../shared/components/market/market-details/market-details.component';
import { UpdateMarket } from '../../state/entities/markets.actions';
import { ScrollPanelModule } from "primeng/scrollpanel";
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";

@Component({
  selector: 'prf-markets-view',
  standalone: true,
  imports: [
    // TODO: Extract shared module... view module
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    ProductDetailsComponent,
    RecipeEditorComponent,
    TabViewModule,
    MarketDetailsComponent,
    ScrollPanelModule,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './markets-view.component.html',
  styleUrls: ['./markets-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketsViewComponent {
  public TableConfig = MarketsTableConfig;

  selectedEntity: IMarket | null = null;
  loading = false;
  updateInProgress = false;

  @Select(MarketsPageViewModelQueries.getViewModel)
  viewModel$!: Observable<MarketsPageViewModel>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    // TODO: Check typing... IEntity generic event to specific IProduct
    this.selectedEntity = $event as IMarket;
  }

  handleUpdateEntity(entity: IMarket) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateMarket({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        // TODO: 1. Check if selectedEntity should be re-assigned again, so that the details get reloaded.
        // 2. Check if selectedEntity should be stored in the Store.
        this.cdRef.markForCheck();
      });
  }

  onClickCloseMarketDetails(): void {
    this.selectedEntity = null;
  }
}
