<div class="flex flex-row">
  <div class="col">
    <ng-container *ngIf="viewModel$ | async as viewModel">
      <prf-data-table
        [rowData]="viewModel.retailers"
        [tableColumns]="TableConfig.columns"
        [loading]="loading"
        [selectedEntity]="selectedEntity"
        (selectionChange)="onEntitySelectionChange($event)" />
    </ng-container>
  </div>
  <div *ngIf="selectedEntity"
       prfToggleEntityDetailsExpand
       class="col col-data-details">
    <prf-retailer-details [entity]="selectedEntity"
                          [updateInProgress]="updateInProgress"
                          [showClose]="true"
                          (clickClose)="onClickCloseRetailerDetails()"
                          (updateEntity)="handleUpdateEntity($event)"
    />
  </div>
</div>
