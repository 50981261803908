import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { UiState } from './state/ui.state';
import { filter, Observable } from 'rxjs';
import { CommonModule } from '@angular/common';
import { PrimeNGConfig } from 'primeng/api';
import { primeNgConfig, primeNgTranslations } from './app.config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UpdateNavigationInfo } from './state/route.actions';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ToastService } from './shared/services/toast/toast.service';
import { MessageModule } from 'primeng/message';
import { ToastModule } from 'primeng/toast';

import { PerfoHotkeysService } from './shared/services/hotkeys/perfo-hotkeys.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    DynamicDialogModule,
    MessageModule,
    ToastModule,
  ],
  providers: [ToastService],
  selector: 'prf-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private router: Router,
    private store: Store,
    private hotkeysService: PerfoHotkeysService,
  ) {
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
      )
      .subscribe((event: NavigationEnd) => {
        this.store.dispatch(new UpdateNavigationInfo(event.url));
      });
  }

  @Select(UiState.isSidebarOpen) isSidebarOpen$!: Observable<boolean>;

  // TODO: Extract primeNg config into config file.
  ngOnInit() {
    this.primengConfig.filterMatchModeOptions =
      primeNgConfig.filterMatchModeOptions;
    this.primengConfig.setTranslation(primeNgTranslations);
    this.hotkeysService.enableHotkeys();
  }
}
