import { createPerfoTableConfig } from '../../shared/components/data-table/data-table.component';
import { DataTableDelivery } from '@prf/shared/domain';

export const DeliveriesTableConfig = createPerfoTableConfig<DataTableDelivery>([
  {
    header: 'Lieferdatum',
    field: 'deliveryDateLabel',
    sortable: true,
    customSort: (a, b) => (a.deliveryDate as string).localeCompare(b.deliveryDate as string),
    customRender: {
      monoFont: true,
    },
  },
  {
    header: 'Lieferschein-Nr',
    field: 'deliverySlipNumber',
    sortable: true,
    customRender: {
      monoFont: true,
    },
  },
  {
    header: 'Marktname',
    field: 'marketNameLabel',
    sortable: true,
  },
  {
    header: 'Lieferadresse',
    field: 'marketDeliveryAddressLabel',
    sortable: true,
    width: 300,
  },
  {
    header: 'Fahrer',
    field: 'driverLabel',
    sortable: true,
  },
  // {
  //   header: 'Fahrzeug',
  //   field: 'vehicleId',
  //   sortable: true,
  // },
  // {
  //   header: 'Fahrer Notiz',
  //   field: 'driverNote',
  //   sortable: false,
  // },
  {
    header: 'Typ',
    field: 'type',
    sortable: true,
  },
  {
    header: 'LS versendet',
    field: 'deliverySlipEmailSent',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  // STATUS SPALTE: z.B. - Lieferschein/Kommissionarschein bereits (n-mal) gelöscht.
]);
