import { IDelivery } from "@prf/shared/domain";
import * as dayjs from "dayjs";
import {
  GroupedDeliveries
} from "../../../../view-models/delivery-planning-draggable-week-overview-view-model.queries";
import { CalendarWeekInfo } from "../../../../state/ui.state";

export abstract class GroupingStrategy {
  // TODO: Refactor: pass map with id -> entity-ref for instant map/object[lookup]
  abstract group(deliveries: IDelivery[], calendarWeekInfo: CalendarWeekInfo): GroupedDeliveries[];

  protected initializeWeekdays(calendarWeekInfo: CalendarWeekInfo): { [key: string]: IDelivery[] } {
    const weekdays: { [key: string]: IDelivery[] } = {};

    if (!calendarWeekInfo.rangeDates || calendarWeekInfo.rangeDates.length !== 2) {
      throw new Error('Invalid rangeDates');
    }

    let currentDate = dayjs(calendarWeekInfo.rangeDates[0]);
    const endDate = dayjs(calendarWeekInfo.rangeDates[1]);

    while (currentDate.isBefore(endDate) || currentDate.isSame(endDate, 'day')) {
      weekdays[currentDate.format('YYYY-MM-DD')] = [];
      currentDate = currentDate.add(1, 'day');
    }

    return weekdays;
  }



  protected formatDate(date: string): string {
    return dayjs(date).format('dddd, DD.MM.YYYY');
  }
}
