<ng-container *ngIf="viewModel$ | async as viewModel">

  <!--  calendar should be KW centered/focussed ... -->
  <!--  manual ranges is sub-action, more specific -->

  <!--  ALLGEMEINER kalender in sidebar  zB, dort wird aktuelles datum und
  aktuelle KW angezeiegt... ansonsten keine weitere funktion -->


  <!--  td.p-datepicker-weeknumber -> span: with num-->

<!--  <pre style="position: absolute; z-index: 99999999; outline: 2px dashed yellow; background: rgba(255,255,255,0.96);">-->
<!--    {{viewModel.selectedDetailsDeliveryState.delivery|json}}-->
<!--  </pre>-->

  <div class="flex">
    <p-calendar [ngModel]="viewModel.tableRangeDates"
                (ngModelChange)="onTableRangeDatesSelect($event)"
                selectionMode="range"
                placeholder="Zeitraum wählen"
                [showIcon]="true"
                [showClear]="true"
                [showButtonBar]="true"
                [readonlyInput]="true"
                [numberOfMonths]="2"
                [selectOtherMonths]="true"
                [showWeek]="true"
    />

    <button type="button"
            pButton
            icon="pi pi-file-pdf"
            pTooltip="Exportiert Kommissionierscheine für alle angezeigten Lieferungen (nach Filterung)"
            class="p-button-secondary ml-4"
            label="KS exportieren"
            [loading]="compactDeliverySlipCollectionExportInProgress"
            (click)="onClickOpenCompactDeliverySlipsCollection(viewModel.deliveries)">
    </button>

    <button type="button"
            pButton
            icon="pi pi-file-pdf"
            pTooltip="Exportiert Lieferscheine für alle angezeigten Lieferungen (nach Filterung)"
            class="p-button-secondary ml-2"
            label="LS exportieren"
            [loading]="deliverySlipCollectionExportInProgress"
            (click)="onClickOpenDeliverySlipsCollection(viewModel.deliveries)">
    </button>
  </div>

  <!--  <pre>Filtern -> Drucken -> Es wird nur aktive LIVE-Ansicht (gefiltert) gedruckt. Alles in eine PDF.</pre>-->
  <!--  <button class="ml-5">Drucken: Kommissionierscheine</button>-->

  <div class="flex flex-row">
    <div class="col">
      <prf-data-table
        [rowData]="viewModel.deliveries"
        [tableColumns]="TableConfig.columns"
        [loading]="loading"
        [selectedEntity]="viewModel.selectedDetailsDeliveryState.delivery"
        (selectionChange)="onEntitySelectionChange($event)"
        (tableFilter)="onTableFilter($event)"
      />
    </div>
    <div *ngIf="viewModel.selectedDetailsDeliveryState.delivery"
         prfToggleEntityDetailsExpand
         class="col col-data-details">
      <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 20px)' }">
        <div class="card">
          <p-tabView [activeIndex]="0">
            <p-tabPanel header="Details">
              <prf-delivery-details [entity]="viewModel.selectedDetailsDeliveryState.delivery"
                                    [updateInProgress]="updateInProgress"
                                    [showClose]="true"
                                    (clickClose)="onClickCloseDeliveryDetails()"
                                    (entityChange)="handleUpdateEntity($event)" />
            </p-tabPanel>
<!--            TODO: deactivate editor when delivery is greater/equal status DELIVERED or hasPostDeliveryCorrections:true-->
            <p-tabPanel header="Planlieferung">
              <prf-delivery-products-editor [delivery]="viewModel.selectedDetailsDeliveryState.delivery"
                                            [products]="viewModel.products"
              />
            </p-tabPanel>
            <p-tabPanel header="Ausgeliefert">
              <prf-delivery-delivered-view [delivery]="viewModel.selectedDetailsDeliveryState.delivery"
                                           [products]="viewModel.products"
                                           (clickClose)="onClickCloseDeliveryDetails()"
              />
            </p-tabPanel>
          </p-tabView>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</ng-container>
