<!--<pre>Meta-Infos zur Mail, z.B. vollständiger Marktname. Lieferung vom...</pre>-->
<div class="card pt-3">
  <div class="p-fluid">
    <div class="p-field prf-field">
      <label for="recipientEmail">Empfänger E-Mail</label>
      <!--      <input id="recipientEmail"-->
      <!--             type="text"-->
      <!--             pInputText-->
      <!--            [(ngModel)]="recipientEmail"-->
      <!--      />-->
      <p-chips id="recipientEmail"
               [(ngModel)]="recipientEmails"
               separator=","
               placeholder="E-Mail-Adresse"
               [addOnBlur]="true"
               [addOnTab]="true"
               [max]="2" />

    </div>
    <div class="p-field prf-field">
      <label for="subject">Betreff</label>
      <input id="subject"
             type="text"
             pInputText
             [(ngModel)]="subject"
      />
    </div>
    <div class="p-field prf-field">
      <label for="mailText">Nachricht</label>
      <p-editor id="mailText" [(ngModel)]="editorText" [style]="{ height: '286px' }">
        <ng-template pTemplate="header">
        <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
        </span>
          <span class="ql-formats">
            <button class="ql-list" value="ordered"></button>
            <button class="ql-list" value="bullet"></button>
        </span>
          <!--<span class="ql-formats">
            <select class="ql-color"></select>
            <select class="ql-background"></select>
          </span>-->
        </ng-template>
      </p-editor>
    </div>
<!--    <div class="p-field prf-field">-->
<!--      Anhänge: [PDF-Klickbar] [Anhang-/Büroklammer-/PDF-Icon]-->
<!--    </div>-->
    <div class="p-field prf-field">
      <!-- TODO: confirmPopup -->
      <p-button label="Senden"
                icon="pi pi-send"
                styleClass="p-button-primary"
                [disabled]="shouldDisableSendMailButton()"
                (click)="onClickSendMail()"
      />
    </div>
  </div>
</div>



