<div *ngIf="product" class="card">

  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="productNo">Artikel-Nr.</label>
      <input id="productNo" type="text"
             [(ngModel)]="product.productNo"
             [disabled]="updateInProgress"
             (ngModelChange)="handleProductNoChange(product.productNo)"
             pInputText />
    </div>
    <div class="p-field prf-field">
      <label for="description">Beschreibung</label>
      <input id="description" type="text"
             [(ngModel)]="product.description"
             [disabled]="updateInProgress"
             pInputText />
    </div>
    <div class="p-field prf-field">
      <label for="ean">EAN</label>
      <input id="ean" type="text"
             [(ngModel)]="product.ean"
             [disabled]="updateInProgress"
             pInputText
      />
    </div>
    <div class="p-field prf-field">
      <label for="category">Kategorie</label>
      <p-dropdown id="category" [(ngModel)]="product.category"
                  [options]="productCategoryOptions"
                  appendTo="body"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
    <div class="p-field prf-field">
      <label for="weight">Gewicht (Eingabe in Gramm)</label>
      <input id="weight" type="text"
             [(ngModel)]="product.pack"
             [disabled]="updateInProgress"
             pInputText />
    </div>


    <div class="p-field prf-field">
      <label for="netPrice">Nettopreis (€)</label>
      <input id="netPrice" type="number"
             [(ngModel)]="product.netPrice"
             [disabled]="updateInProgress"
             pInputText />
    </div>
    <div class="p-field prf-field">
      <label for="grossPrice">Bruttopreis UVP (€)</label>
      <input id="grossPrice" type="number"
             [(ngModel)]="product.grossPrice"
             [disabled]="updateInProgress"
             pInputText />
    </div>
    <div class="p-field prf-field mb-6">
      <label for="vatRate">Mehrwertsteuersatz</label>
      <p-dropdown id="vatRate"
      [(ngModel)]="product.vatRate"
                  [options]="[
                      {label: '7 %', value: 7}
                  ]"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
  </div>

  <div class="flex">
    <div class="flex justify-content-start ml-2 flex-grow-1">
      <p-button *ngIf="showClose"
                [outlined]="true"
                label="Schließen"
                icon="pi pi-times"
                styleClass="p-button-secondary"
                (click)="clickClose.emit()"
      />
    </div>
    <div class="flex justify-content-end">
      <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
                [loading]="updateInProgress"
                [disabled]="updateInProgress"
                styleClass="p-button-primary"
                (click)="onClickSave()"
      ></p-button>
    </div>

  </div>

</div>
