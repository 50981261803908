import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IVehicle } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from "rxjs";
import { RetailerDetailsComponent } from '../../shared/components/retailer/retailer-details/retailer-details.component';
import { IngredientDetailsComponent } from '../../shared/components/ingredient/ingredient-details/ingredient-details.component';
import { VehiclesTableConfig } from './table.config';
import { UpdateVehicle } from '../../state/entities/vehicles.actions';
import { VehiclesPageViewModel, VehiclesPageViewModelQueries } from "../../view-models/vehicle-view-model.queries";
import { VehicleDetailsComponent } from "../../shared/components/vehicles/vehicle-details/vehicle-details.component";
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";

type LocalModel = IVehicle;

@Component({
  selector: 'prf-vehicles-view',
  standalone: true,
  imports: [
    // TODO: Extract shared module... view module
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    RetailerDetailsComponent,
    IngredientDetailsComponent,
    VehicleDetailsComponent,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './vehicles-view.component.html',
  styleUrls: ['./vehicles-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// IngredientsViewComponent.ts
export class VehiclesViewComponent {
  public TableConfig = VehiclesTableConfig;

  selectedEntity: LocalModel | null = null;
  loading = false;
  updateInProgress = false;

  @Select(VehiclesPageViewModelQueries.getViewModel)
  viewModel$!: Observable<VehiclesPageViewModel>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    this.selectedEntity = $event as LocalModel;
  }

  handleUpdateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateVehicle({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }
}
