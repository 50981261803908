<ul style="margin: 26px 0;">
  <li>
    <b>{{ delivery.marketId | market:'marketName' }}<br />
      {{ delivery.marketId | market:'deliveryAddress' }}</b>
  </li>
  <li>
    Lieferschein-Nr {{ delivery.deliverySlipNumber }}
  </li>
  <li>
    Lieferung am {{ delivery.deliveryDate | prfDate }}
  </li>
  <li>
    Lieferungstyp: {{ delivery.type }}
  </li>
  <li *ngIf="delivery?.driverId">
    Fahrer: {{ (delivery.driverId || null) | user: 'username' }}
  </li>
  <li *ngIf="delivery.vehicleId">
    Fahrzeug: {{ delivery.vehicleId }}
  </li>
  <li *ngIf="delivery.driverNote">
    Notiz vom Fahrer: {{ delivery.driverNote }}
  </li>
</ul>

@if (isDeliveredView && delivery.processedDelivery) {
  <p-divider />
  <ul style="margin: 26px 0;">
    <li>Auslieferungszeitpunkt: {{delivery.processedDelivery.deliveredAtMarketDate | prfDate: 'DD.MM.YYYY, HH:mm' }} Uhr</li>
    <li>Entgegengenommen durch: {{delivery.processedDelivery.signatureName}}</li>
    @if(delivery.processedDelivery.messageFromMarket) {
      <li>Nachricht vom Markt: <p-message class="market-message" severity="info" [text]="delivery.processedDelivery.messageFromMarket"/></li>
    } @else {
      <li>Keine Nachricht vom Markt erhalten</li>
    }
  </ul>
}

<div class="flex justify-content-end mb-5" *ngIf="!(isDelivered || isCompleted)">
  <p-button label="Liefervorlage einfügen"
            styleClass="p-button-secondary ml-3"
            icon="pi pi-book"
            (click)="onClickInsertTemplateDelivery()">
  </p-button>
</div>

<div class="table-container" [class.is-delivered]="isDeliveredView">
  <div class="table-row header font-bold">
    <ng-container *ngIf="!isDeliveredView">
      <div class="table-cell font-bold">Artikel</div>
      <div class="table-cell font-bold">Menge</div>
      <div class="table-cell action-btn-wp"></div>
    </ng-container>
    <ng-container *ngIf="isDeliveredView">
      <div class="table-cell font-bold">Artikel</div>
      <div class="table-cell font-bold">Soll-Menge</div>
      <div class="table-cell font-bold">Ist-Menge</div>
      <div class="table-cell font-bold">Rücknahme</div>
    </ng-container>
  </div>

  <div *ngIf="deliveryProducts.length === 0" class="no-products-message">
    <i>Noch keine Artikel zugewiesen.</i>
    <i><br /><br />TODO: No saved deliverProducts? Init with 1 row.</i>
  </div>

  <!--TODO: ng animate in: repeated item -->
  <ng-container *ngIf="!isDeliveredView">
    <div class="table-row" *ngFor="let deliveryProduct of deliveryProducts; let index = index; let last = last;">
      <div class="table-cell">
        <prf-entity-dropdown [entityType]="'product'"
                             [(ngModel)]="deliveryProduct.productId"
                             [disabled]="shouldDisablePreDeliveryAmountEditing()"
                             (selectEntity)="checkForNewDeliveryProductRow()"
        ></prf-entity-dropdown>
      </div>
      <div class="table-cell">
        <div class="p-inputgroup">
          <input type="number" pInputText class="p-inputtext-sm"
                 placeholder="Menge"
                 [tabindex]="index + 1"
                 [disabled]="shouldDisablePreDeliveryAmountEditing()"
                 [class.text-red-500]="quantityIsAbove99(deliveryProduct.targetQuantity)"
                 [class.font-bold]="quantityIsAbove99(deliveryProduct.targetQuantity)"
                 [(ngModel)]="deliveryProduct.targetQuantity"
                 (ngModelChange)="checkForNewDeliveryProductRow()"
          />
        </div>
      </div>
      <div class="table-cell action-button-wp">
        <button icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-text"
                pButton
                tabindex="-1"
                *ngIf="canDeleteProduct(last)"
                (click)="onClickDeleteDeliveryProduct(deliveryProduct)"
        ></button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isDeliveredView">
    <div class="table-row" *ngFor="let deliveryProduct of deliveryProducts">
      <div class="table-cell">
        <prf-entity-dropdown [entityType]="'product'"
                             [(ngModel)]="deliveryProduct.productId"
                             [disabled]="true"
        ></prf-entity-dropdown>
      </div>
      <div class="table-cell">
        <div class="p-inputgroup">
          <input type="number" pInputText class="p-inputtext-sm"
                 placeholder="Soll-Menge"
                 [min]="0"
                 [class.text-red-500]="quantityIsAbove99(deliveryProduct.targetQuantity)"
                 [class.font-bold]="quantityIsAbove99(deliveryProduct.targetQuantity)"
                 [(ngModel)]="deliveryProduct.targetQuantity"
                 [disabled]="true"
          />
        </div>
      </div>
      <div class="table-cell">
        <div class="p-inputgroup">
          <input type="number" pInputText class="p-inputtext-sm"
                 placeholder="Ist-Menge"
                 [min]="0"
                 [class.text-red-500]="quantityIsAbove99(deliveryProduct.actualQuantity)"
                 [class.font-bold]="quantityIsAbove99(deliveryProduct.actualQuantity)"
                 [(ngModel)]="deliveryProduct.actualQuantity"
                 [disabled]="!isManualEditingDeliveryProducts || isSavingDeliveryProducts"
          />
        </div>
      </div>
      <div class="table-cell">
        <div class="p-inputgroup">
          <input type="number" pInputText class="p-inputtext-sm"
                 placeholder="Rücknahme"
                 [min]="0"
                 [class.text-red-500]="quantityIsAbove99(deliveryProduct.returnQuantity)"
                 [class.font-bold]="quantityIsAbove99(deliveryProduct.returnQuantity)"
                 [(ngModel)]="deliveryProduct.returnQuantity"
                 [disabled]="!isManualEditingDeliveryProducts || isSavingDeliveryProducts"
          />
        </div>
      </div>
    </div>
  </ng-container>

</div>

<div class="button-container flex justify-content-end mt-5">
  <!--<p-button label="Artikel hinzufügen"
            styleClass="p-button-primary p-button-sm"
            icon="pi pi-plus"
            (click)="onClickAddDeliveryProduct()"
  ></p-button>-->

  <ng-container *ngIf="!isDeliveredView && !(isDelivered || isCompleted)">
    <p-button label="Liefermengen speichern"
              styleClass="p-button-primary ml-3"
              icon="pi pi-save"
              [loading]="isSavingDeliveryProducts"
              (click)="onClickSaveDeliveryProducts()">
    </p-button>
  </ng-container>

  <ng-container *ngIf="isDeliveredView">
    <!-- TODO: Add Hinweis: Diese manuelle Bearbeitung/Anpassung macht ursprünglichen Markt-Lieferschein ungültig, ie Unterschrift/Mitarbeitrname werden verworfen.-->
    <p-button *ngIf="isManualEditingDeliveryProducts"
              [outlined]="true"
              (click)="clickClose.emit()"
              label="Verwerfen"
              icon="pi pi-times"
              styleClass="p-button-secondary"
    />

    <div *ngIf="delivery.hasPostDeliveryCorrections && !isManualEditingDeliveryProducts">
      <i class="pi pi-info-circle"></i>
      Lieferung wurde bereits nachträglich bearbeitet.
    </div>

    <button *ngIf="!isManualEditingDeliveryProducts"
            type="button"
            pButton
            icon="pi pi-lock"
            label="Mengen-Bearbeitung aktivieren"
            class="p-button-primary"
            [disabled]="isDeliveryCompleted()"
            (click)="onClickManualEditDeliveryProducts()"
    >
    </button>

    <button *ngIf="isManualEditingDeliveryProducts"
            [loading]="isSavingDeliveryProducts"
            type="button"
            pButton
            icon="pi pi-save"
            label="Liefermengen speichern"
            (click)="onClickSaveDeliveryProducts(true)"
            class="p-button-primary ml-2"
    >
    </button>
  </ng-container>
</div>

<!-- Statistiken zu Rücknahmen-Quote ... pro Produkt etc -->
<!-- Retourenquoten können "im Rahmen" liegen, Grenzwert nicht überschritten -> zB grün anzeigen. Bei Überschreitung rot anzeigen-->
