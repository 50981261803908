import { Selector } from '@ngxs/store';
import { IVehicle } from '@prf/shared/domain';
import { VehiclesState } from '../state/entities/vehicles.state';

export interface VehiclesPageViewModel {
  vehicles: IVehicle[];
}

export class VehiclesPageViewModelQueries {
  @Selector([VehiclesState.items])
  static getViewModel(vehicles: IVehicle[]): VehiclesPageViewModel {
    return {
      vehicles: [...vehicles]
    };
  }
}
