<h3>Kalenderwoche {{calendarWeekNumber}}</h3>
<ul>
  <li class="mb-1">Anzahl der Lieferungen in KW: <b>{{servedDeliveries.length}}</b></li>
  <li>Anzahl der NICHT BELIEFERTEN Märkte in KW: <b>{{notServedMarkets.length}}</b></li>
</ul>

<hr>

<h3>Nicht belieferte Märkte</h3>
<div *ngFor="let market of notServedMarkets" class="mb-4 market-entry">
  <ul>
    <li class="font-bold">{{ market.marketName }}</li>
    <li>{{ market.operator }}</li>
    <li>Kunden-Nr.: {{ market.customerNumber }}</li>
    <li>Lieferadresse: {{ formatAddress(market.deliveryAddress) }}</li>
  </ul>
</div>
