import { CreatableEntity, IDelivery, IDeliveryProduct } from '@prf/shared/domain';
import { DraggedDeliveryUpdateFields } from '../../view-models/delivery-planning-draggable-week-overview-view-model.queries';

export class LoadAllDeliveries {
  static type = '[Deliveries] Load All Deliveries';

  constructor() {}
}

export class LoadDelivery {
  static readonly type = '[Deliveries] Load Delivery';
  constructor(public payload: { deliveryId: number }) {}
}

export class LoadSpecificDeliveries {
  static readonly type = '[Deliveries] Load Specific Deliveries';
  constructor(public payload: { deliveries: IDelivery[] }) {}
}

export class CreateDelivery {
  static type = '[Deliveries] Create New Delivery';

  constructor(public payload: { entity: CreatableEntity<IDelivery> }) {}
}

export class UpdateDelivery {
  static type = '[Deliveries] Update Delivery';

  constructor(public payload: { entity: IDelivery }) {}
}

export class SendDeliverySlipEmail {
  static readonly type = '[Deliveries] Send Delivery Slip Email';
  constructor(public payload: { delivery: IDelivery, recipientEmails: string[]; subject: string; message: string; }) {}
}

export class UpdateDraggedDelivery {
  static type = '[Deliveries] Update Dragged Delivery';
  constructor(public payload: { entity: IDelivery; updateFields: DraggedDeliveryUpdateFields }) {}
}

export class CreateNewDeliveriesFromPlanning {
  static readonly type = '[Deliveries] Create New Deliveries From Planning';

  constructor(public payload: { deliveries: IDelivery[] }) {}
}

// DeliveryProducts

export class UpdateDeliveryProducts {
  static type = '[Deliveries] Update Delivery Products';
  constructor(
    public payload: { deliveryId: number; entities: CreatableEntity<IDeliveryProduct>[], hasPostDeliveryCorrections: boolean },
  ) {}
}
