import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { CommonModule } from '@angular/common';
import { DropdownModule } from "primeng/dropdown";
import { InputTextModule } from "primeng/inputtext";
import { PaginatorModule } from "primeng/paginator";
import { IAddress } from "@prf/shared/domain";

@Component({
  selector: 'prf-address-input',
  standalone: true,
  imports: [CommonModule, DropdownModule, InputTextModule, PaginatorModule],
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressInputComponent {
  @Input({ required: true })
  address!: IAddress;

  @Input()
  label: string = 'Adresse';

  @Input()
  disabled: boolean = false;
}
