import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { EUserRole, IDelivery, IUser } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { EntityDropdownComponent } from '../../entity-dropdown/entity-dropdown.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';

type LocalModel = IDelivery;

@Component({
  selector: 'prf-delivery-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    EntityDropdownComponent,
    SelectButtonModule,
    InputSwitchModule,
    TooltipModule,
  ],
  templateUrl: './delivery-details.component.html',
  styleUrls: ['./delivery-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: LocalModel;
  private _entity!: LocalModel;

  protected deliveryTypeOptions = [
    { label: 'Hauptlieferung', value: 'PRIMARY' },
    { label: 'Nachlieferung', value: 'RESUPPLY' },
  ];
  protected deliveryStatusOptions = [
    { label: 'Erstellt', value: 'CREATED' },
    { label: 'Lieferbereit', value: 'READY_TO_DELIVER' },
    { label: 'Ausgeliefert', value: 'DELIVERED' },
    { label: 'Abgeschlossen (fakturiert)', value: 'COMPLETED' },
  ];

  @Input() set entity(entity: LocalModel | null) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): LocalModel {
    return this._entity;
  }

  @Output()
  entityChange = new EventEmitter<LocalModel>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    this.entityChange.emit(this.entity);
  }

  // TODO: Refactor: Integrate into entity-dropdown-comp, in order to not re-define this method everywhere the driver-filtering is needed.
  // TODO: THIS IS NOT an IEntity -- this is the dropdown option itself, ie value/label/role?
  dropdownOptionsFilterUserForDrivers(user: any): boolean {
    // empty option: value === null
    return (user as IUser).role === EUserRole.DRIVER || user.value === null;
  }
}
