import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IKeyValue, IMarket } from "@prf/shared/domain";
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { EntityDropdownComponent } from '../../entity-dropdown/entity-dropdown.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { AddressInputComponent } from "../../form/address-input/address-input.component";
import { DropdownModule } from "primeng/dropdown";

@Component({
  selector: 'prf-market-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    EntityDropdownComponent,
    InputSwitchModule,
    TooltipModule,
    AddressInputComponent,
    DropdownModule,
  ],
  templateUrl: './market-details.component.html',
  styleUrls: ['./market-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  // updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: IProduct;
  private _entity!: IMarket;

  @Input() set entity(entity: IMarket) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): IMarket {
    return this._entity;
  }

  @Output()
  createEntity = new EventEmitter<IMarket>();

  @Output()
  updateEntity = new EventEmitter<IMarket>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    if (this.isCreate) {
      // TODO: Merge into (entityChange) ? let the parent decide if it is a create or update.
      this.createEntity.emit(JSON.parse(JSON.stringify(this.entity)));
    } else {
      this.updateEntity.emit(JSON.parse(JSON.stringify(this.entity)));
    }
  }

  onClickAddCustomReceiptTextPos1() {
    if (this.entity.customReceiptTextsPos1 === null || this.entity.customReceiptTextsPos1 === undefined) {
      this.entity.customReceiptTextsPos1 = [];
    }

    this.entity.customReceiptTextsPos1.push({
      key: "",
      value: ""
    })
  }

  onClickAddCustomReceiptTextPos2() {
    if (this.entity.customReceiptTextsPos2 === null || this.entity.customReceiptTextsPos2 === undefined) {
      this.entity.customReceiptTextsPos2 = [];
    }

    this.entity.customReceiptTextsPos2.push({
      key: "",
      value: ""
    })
  }

  onClickDeleteCustomReceiptTextPos1(customReceiptText: IKeyValue) {
    if (this.entity.customReceiptTextsPos1) {
      this.entity.customReceiptTextsPos1 = this.entity.customReceiptTextsPos1.filter(text =>
        text.key !== customReceiptText.key || text.value !== customReceiptText.value
      );
    }
  }

  onClickDeleteCustomReceiptTextPos2(customReceiptText: IKeyValue) {
    if (this.entity.customReceiptTextsPos2) {
      this.entity.customReceiptTextsPos2 = this.entity.customReceiptTextsPos2.filter(text =>
        text.key !== customReceiptText.key || text.value !== customReceiptText.value
      );
    }
  }
}
