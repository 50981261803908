<div class="flex align-items-center">
  <div style="flex-basis: 110px;">Dokumente-Vorschau:</div>
  <!--  TODO: loading indication for all buttons -->
  <button type="button"
          pButton
          icon="pi pi-file-pdf"
          label="Kommissionierschein"
          class="p-button-secondary mr-2"
          (click)="onClickOpenCompactDeliverySlip(delivery)"
  >
  </button>
  <button type="button"
          pButton
          icon="pi pi-file-pdf"
          label="Lieferschein"
          class="p-button-secondary mr-2"
          (click)="onClickOpenDeliverySlip(delivery)"
  >
  </button>
  <button type="button"
          pButton
          icon="pi pi-file-pdf"
          label="Rechnung"
          class="p-button-secondary mr-2"
          (click)="onClickOpenInvoice(delivery)"
  >
  </button>
</div>

<p-divider />

@if (deliveredImages) {
  <div class="card">
    <div class="flex align-items-center">
      <div style="flex-basis: 110px;">Fotos ({{ deliveredImages ? deliveredImages.length : '0' }}):</div>
      <div *ngIf="deliveredImages" class="grid" style="max-width: 90vw;">
        <div *ngFor="let image of deliveredImages; let index = index" class="photo-wrapper">
          <img [src]="image.itemImageSrc" loading="lazy" style="cursor: pointer; height: 90px;"
               (click)="imageClick(index)" />
        </div>
      </div>
    </div>
    <p-galleria
      [value]="deliveredImages"
      [(visible)]="displayCustom"
      [(activeIndex)]="activeIndex"
      [circular]="true"
      [fullScreen]="true"
      [showItemNavigators]="true"
      [showThumbnails]="false"
      (keydown)="onKeyDownGalleria($event)"
    >
      <ng-template pTemplate="item" let-item>
        <img [src]="item.itemImageSrc" loading="lazy" style="max-height: 100vh; max-width: 100vw; display: block;" />
      </ng-template>
    </p-galleria>
  </div>


  <!--  <p-galleria [value]="deliveredImages"-->
    <!--              [showItemNavigators]="true"-->
    <!--              [showThumbnails]="false"-->
    <!--              [showIndicators]="true"-->
    <!--              [showIndicatorsOnItem]="true"-->
    <!--              [containerStyle]="{ 'max-width': '640px', 'margin-top': '2em' }">-->
    <!--    <ng-template pTemplate="item" let-item>-->
    <!--      <img [src]="item.itemImageSrc" style="width: 100%; display: block;" />-->
    <!--    </ng-template>-->
    <!--&lt;!&ndash;    <ng-template pTemplate="thumbnail" let-item>&ndash;&gt;-->
    <!--&lt;!&ndash;      <div class="grid grid-nogutter justify-content-center">&ndash;&gt;-->
    <!--&lt;!&ndash;        <img [src]="item.thumbnailImageSrc" style="display: block;" />&ndash;&gt;-->
    <!--&lt;!&ndash;      </div>&ndash;&gt;-->
    <!--&lt;!&ndash;    </ng-template>&ndash;&gt;-->
    <!--  </p-galleria>-->


  <p-divider />
}

<ng-container *ngIf="shouldShowMarkAsDeliveredButton()">
  <button type="button"
          pButton
          icon="pi pi-check-circle"
          label="Manuell auf AUSGELIEFERT setzen"
          class="p-button-primary"
          [loading]="isSetManuallyAsDeliveredInProgress"
          (click)="onClickMarkManuallyAsDelivered($event, delivery)"
  >
  </button>
</ng-container>

<ng-container *ngIf="shouldShowDeliveryEditView()">
  <prf-delivery-products-editor [delivery]="delivery"
                                [isDeliveredView]="true"
                                [products]="products"
                                (clickClose)="clickClose.emit()"
  />
</ng-container>


<p-divider />


<ng-container *ngIf="shouldShowDeliveryCompletionActions()">
  <!--  HINWEIS: grün, dass lieferschein bereits versendet wurde-->

  <div class="flex justify-content-end button-container-full-w">
    <button type="button"
            pButton
            icon="pi pi-envelope"
            label="Lieferschein versenden"
            [label]="delivery.deliverySlipEmailSent ? 'Lieferschein ERNEUT versenden' : 'Lieferschein versenden'"
            class="p-button-secondary mr-2"
            [loading]="isSendDeliverySlipEmailInProgress"
            [disabled]="shouldDisableSendDeliverySlipEmail()"
            (click)="onClickSendDeliverySlipEmail()"
    >
    </button>

    <!--  Hinweis: wenn man abschließen will, also Rechnung erstellen, obwohl man den LS noch gar nicht versendet hat.  -->
    <!--    GGF hinweis ergänzen/append in CONFIRM-POPUP-->
    <ng-container *ngIf="shouldShowCompleteDeliveryAction()">
      <button type="button"
              pButton
              icon="pi pi-check-square"
              label="Lieferung abschließen"
              class="p-button-secondary mr-2"
              [disabled]="shouldDisableCompleteDeliveryAction()"
              [loading]="isSetDeliveryAsCompletedInProgress"
              (click)="onClickCompleteDelivery($event, delivery)"
      >
      </button>
    </ng-container>
    <ng-container *ngIf="isCompletedDelivery()">
      <div>
        <i class="pi pi-info-circle"></i>
        Lieferung wurde bereits abgeschlossen (fakturiert).
      </div>
    </ng-container>


    <!--  EMPFÄNGER MAIL ANZEIGEN! -->
    <!--  klick öffnet dialog mit standard email text. -->
    <!--     rechung erhält aktion für rechnung per mail versenden - dialog analog zu Lieferschein versenden  -->
    <!--     rechung erhält attr "bereits  versendet" in tabellenübersicht  -->
    <!--  mails immer BCC archiv@paldo.de-->
    <!--  mails immer BCC beleg-ausgang@paldo.de-->
  </div>
  <!--  <div class="mt-3">-->
  <!--    Entwicklungs-Hinweis: Abschließen einer Lieferung ist aktuell deaktiviert.-->
  <!--  </div>-->
</ng-container>


<!--<button type="button"-->
<!--        pButton-->
<!--        icon="pi pi-file-pdf"-->
<!--        class="p-button-secondary mr-2"-->
<!--        (click)="onClickTransformIntoInvoice(delivery)"-->
<!--&gt;-->
<!--  TEST: Rechnung ableiten-->
<!--</button>-->

<!--<pre>-->

<!--Tatsächliche Lieferung - inkl. Retouren etc.-->

<!--Grundsätzlich nicht editierbar-->
<!--  -> nur vom Admin freischaltbar, falls der Fahrer "Fehler macht".-->
<!--Admin muss hier "notfalls" auch selber die Lieferung abschließen können.-->
<!--z.B., wenn Tablet defekt, Akku leer o.Ä.-->

<!--Festhalten/Dokumentieren von "Auslieferung durch Fahrer / Admin"-->

<!--(!) Sonstige Dokumente hochladen, zur Lieferung-->


<!-- - Timestamps der Auslieferung / Abschluss durch Fahrer (kurz nach Unterschrift)-->
<!-- - Fotos-->
<!-- - Abgeschlossener Lieferschein - was wurde tatsächlich ausgeliefert-->
<!--  - Bei Divergenz zwischen Plan/Tatsächlich-Menge -> Zeile markieren/hervorheben-->
<!-- - Festhalten/Anzeigen wer konkret unterschrieben hat-->

<!--VIEW: PDF öffnen/anzeigen. PDF herunterladen.-->

<!-- INFOS-->
<!--AKTION: 1. Lieferschein versenden - vorher ansehbar vom Manager - an den Markt (hinterlegte Mail)-->
<!--AKTION: 2. "verschickten Lieferschein" in Rechnung umwandeln-->
<!--        2a -> Rechnung landet im Rechnungswesen.-->
<!--              Dort ist es dann möglich die Rechnung an die hinterlegte Adresse zu verschicken.-->

<!--</pre>-->


<!--<p-image src="https://primefaces.org/cdn/primeng/images/galleria/galleria1.jpg" alt="Image" width="250" [preview]="true"></p-image>-->

<p-confirmPopup />

<!--<p-dialog header="Lieferschein versenden"-->
<!--          *ngIf="isSendDeliverSlipEmailDialogOpen"-->
<!--          [visible]="true"-->
<!--          [closeOnEscape]="true"-->
<!--          (visibleChange)="isSendDeliverSlipEmailDialogOpen = $event;"-->
<!--          [style]="{width: '600px'}">-->
<!--&lt;!&ndash;  <pre>&ndash;&gt;-->
<!--&lt;!&ndash;    {{delivery|json}}&ndash;&gt;-->
<!--&lt;!&ndash;    "deliverySlipNumber": "1 02 0006 0003",&ndash;&gt;-->
<!--&lt;!&ndash;    "deliveryDate": "2024-01-15",&ndash;&gt;-->
<!--&lt;!&ndash;    "isActive": true,&ndash;&gt;-->
<!--&lt;!&ndash;    "marketId": 8,&ndash;&gt;-->
<!--&lt;!&ndash;  </pre>&ndash;&gt;-->
<!--  <prf-send-email-dialog type="DELIVERY_SLIP" />-->
<!--</p-dialog>-->
