<!--NOTES-->
<!--Grundsätzlich ist es so, dass die Fahrer erst einmal bleiben, bei Bedarf aber dann geändert werden. D.h., dass die fahre auch mit in die Folge Woche beziehungsweise Ziel Woche genommen werden können erst einmal. Man muss aber die Möglichkeit haben, diese auch zu ändern.-->
<!-- Ausgangswoche = Zielwoche - 2 KW -->

<ng-container *ngIf="viewModel$ | async as viewModel">

  <div class="mt-4 flex flex-column" style="height: calc(100% - 25px);">
    <p-steps [model]="dialogStepItems"
             [activeIndex]="activeStepIndex"
             [readonly]="true" />

    <div class="flex modal-main-content-wp">
      <div class="flex mt-7 flex-1">
        <ng-container [ngSwitch]="activeStepIndex">
          <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="step1Template"></ng-container>
          <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="step2Template"></ng-container>
          <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="step3Template"></ng-container>
          <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="step4Template"></ng-container>
          <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="step5Template"></ng-container>
        </ng-container>
      </div>

      <ng-container *ngIf="activeStepIndex !== 0 && viewModel.selectedDetailsDelivery">
        <!--    TODO: reset selectedEntity on NEXT/PREV step move -->
        <prf-delivery-details class="ml-4"
                              [entity]="viewModel.selectedDetailsDelivery"
                              [showClose]="true"
                              (clickClose)="onClickCloseDeliveryDetails()"
                              (entityChange)="onSaveDeliveryDetails($event)" />
      </ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="dialogFooter"></ng-container>
  </div>

  <!--TEMPLATES, to be extracted into components -->
  <!-- Step 1 Template -->
  <ng-template #step1Template>
    <div class="source-week flex-1 flex flex-column align-items-center">
      <h2>Ausgangswoche <span class="calendar-week-number">{{ viewModel.sourceWeekInfo.calendarWeekNumber }}</span></h2>
      <p-calendar selectionMode="range"
                  [ngModel]="viewModel.sourceWeekInfo.rangeDates"
                  [selectOtherMonths]="true"
                  [inline]="true"
                  [showWeek]="true"
                  (onSelect)="onWeekDateSelect($event, 'source')"
      />
    </div>
    <div class="flex align-items-center">
      <i class="pi pi-arrow-right" style="font-size: 3rem; color: #4d5669;"></i>
    </div>
    <div class="target-week flex-1 flex flex-column align-items-center">
      <h2>Zielwoche <span class="calendar-week-number">{{ viewModel.targetWeekInfo.calendarWeekNumber }}</span></h2>
      <p-calendar selectionMode="range"
                  [selectOtherMonths]="true"
                  [ngModel]="viewModel.targetWeekInfo.rangeDates"
                  [inline]="true"
                  [showWeek]="true"
                  (onSelect)="onWeekDateSelect($event, 'target')"
      />
    </div>
  </ng-template>

  <!-- Step 2 Template -->
  <ng-template #step2Template>

    <div class="w-full">
      <ng-container *ngFor="let otherGroup of viewModel.otherGroups">
        <p-panel class="panel-planning-other-group"
                 [toggleable]="true"
                 [collapsed]="true"
                 [style]="{marginBottom: '20px'}">
          <ng-template pTemplate="header">
            <div class="flex text-primary font-semibold text-lg w-full">
              {{ otherGroup.title }}
            </div>
          </ng-template>
          <ng-container *ngFor="let delivery of otherGroup.deliveries">
            <prf-delivery-planning-item [delivery]="delivery"
                                        [isSelected]="delivery.id === viewModel.selectedDetailsDelivery?.id"
                                        (clickDetails)="onClickShowDeliveryItemDetails(delivery)"
            />
          </ng-container>
        </p-panel>
      </ng-container>

      <!--       </ DYNAMIC GROUPS >-->

      <ng-container *ngFor="let weekdayGroup of viewModel.weekdayGroups">
        <p-panel [style]="{marginBottom: '20px'}">
          <ng-template pTemplate="header">
            <div class="flex text-primary font-semibold text-lg w-full">
              <div class="flex-1 pl-2" style="letter-spacing: 1px;">
                {{ weekdayGroup.sourceDate | prfDate }}
                ({{weekdayGroup.sourceDeliveries.length}} Lieferungen)
              </div>

              <div class="flex-1 text-center" style="letter-spacing: 1px;">
              <span style="padding: 6px 11px;
                           background: white;
                           border-radius: 3px;
                           font-size: 1em;
                           color: #4d5669;
                           text-transform: uppercase;">{{ weekdayGroup.title }}</span>
              </div>

              <div class="flex-1 text-right" style="letter-spacing: 1px;">
                {{ weekdayGroup.targetDate | prfDate }}
                ({{weekdayGroup.targetDeliveries.length}} Lieferungen)
              </div>

            </div>
          </ng-template>
          <div>
            <p-pickList [source]="weekdayGroup.sourceDeliveries"
                        [target]="weekdayGroup.targetDeliveries"
                        [dragdrop]="false"
                        [showSourceControls]="false"
                        [showTargetControls]="false"
                        (onMoveToTarget)="onPickListMoveToTarget($event, weekdayGroup.targetDate)"
                        (onMoveAllToTarget)="onPickListMoveToTarget($event, weekdayGroup.targetDate)"
            >
              <!--              TODO: enable filtering by * - in order to work, market, driver etc need to be non-only-ID -->
              <!--              filterBy="deliveryDate"-->
              <!--              sourceFilterPlaceholder="Filter"-->
              <!--              targetFilterPlaceholder="Filter"-->
              <ng-template let-delivery pTemplate="item">

                <prf-delivery-planning-item [delivery]="delivery"
                                            [showBorder]="false"
                                            [isSelected]="delivery.id === viewModel.selectedDetailsDelivery?.id"
                                            (clickDetails)="onClickShowDeliveryItemDetails(delivery)"
                />

              </ng-template>
            </p-pickList>
          </div>
        </p-panel>

      </ng-container>

    </div>


  </ng-template>

  <!-- Step 3 Template -->

  <ng-template #step3Template>
    <div>
      <prf-delivery-planning-draggable-view [deliveries]="viewModel.plannedTargetWeekDeliveries"
                                            [groupingType]="'BY_DRIVER'"
                                            [calendarWeekInfo]="viewModel.targetWeekInfo"
                                            [showDeliveryItemDetailsButton]="false"
      />
      <!--                                            (selectEntityForEdit)="onClickEditEntity($event)"-->

    </div>
  </ng-template>


  <!-- Step 4 Template -->

  <ng-template #step4Template>
    <div>
      <!--      <span style="color: grey;">-->
      <!--        "Generallieferschein", bzw. relative Veränderung für ALLE Lieferscheine. z.B. ALLE Oliven 101 um 20% erhöhen.-->
      <!--        Beispiele siehe call-IMG-20231116-WA0010.jpg-->
      <!--        Extra UI-Feld für "auf 0 setzen".-->
      <!--        Mindestmenge = 2 Pro Produkte-->
      <!--      </span>-->

      <h2>Alle Lieferscheine <span *ngIf="isFetchingPlanningDeliveries"
                                   style="font-style: italic; font-size: 0.8em; color: #6b6b6b;">(Aktualisierung läuft...)</span>
      </h2>
      <div class="card flex justify-content-center flex-column mt-5 ml-7" style="width: 510px;">
        <div *ngFor="let productQuantity of viewModel.productQuantities" class="mb-5" style="position: relative;">
          <label class="font-bold">
            {{ productQuantity.product.productNo }}
            - {{ productQuantity.product.description }}
            <span class="text-red-500"
                  *ngIf="productQuantity.relativeChangeAll === -100"> - Menge wird auf 0 gesetzt!</span>
            <!--            TODO: Handle -100 => 0 inside viewModel -->
          </label>
          <div class="p-inputgroup w-full">
            <input type="text"
                   pInputText
                   class="w-full text-center"
                   style="padding-left: 48px !important"
                   [disabled]="isFetchingPlanningDeliveries"
                   [ngModel]="productQuantity.relativeChangeAll"
                   (ngModelChange)="calculateSingleProductUpdatedTotalAmountViaRelativAll(productQuantity, $event, viewModel)" />
            <!--            Handle min max for input -->
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p-slider [ngModel]="productQuantity.relativeChangeAll"
                    (ngModelChange)="calculateSingleProductUpdatedTotalAmountViaRelativAll(productQuantity, $event, viewModel)"
                    [min]="-100"
                    [max]="100"
                    [tabindex]="-1"
                    [disabled]="isFetchingPlanningDeliveries"
                    class="w-full" />
          <div class="font-bold"
               style="position: absolute; right: -42px; top: 29px;">
            <!--            TODO: extract into temp map values -> later used for request -->
            <!--            TODO: Refactor: calc upon ngModelChange, and only use direct value getter here -->
            <span>{{ productQuantity.totalAmountWithRelativeChange }}</span>
          </div>
        </div>

        <!--        TODO: Gesamt/Alle Lieferscheine-->
        <div class="mb-5" style="position: relative;">
          <label class="font-bold">
            Gesamt
          </label>
          <div class="p-inputgroup w-full">
            <input type="text"
                   pInputText
                   class="w-full text-center"
                   style="padding-left: 48px !important"
                   [disabled]="isFetchingPlanningDeliveries"
                   [ngModel]="allRelativeProductQuantity"
                   (ngModelChange)="onAllRelativeChange($event, viewModel)"
            />
            <span class="p-inputgroup-addon">%</span>
          </div>
          <p-slider [ngModel]="allRelativeProductQuantity"
                    (ngModelChange)="onAllRelativeChange($event, viewModel)"
                    [min]="-100"
                    [max]="100"
                    [disabled]="isFetchingPlanningDeliveries"
                    class="w-full" />
          <div class="font-bold"
               style="position: absolute; right: -42px; top: 29px;">
            <span class="text-blue-500">{{ viewModel.productQuantitiesTotalAmount }}</span>
          </div>
        </div>

      </div>

      <!--      </ MAIN >-->


    </div>


  </ng-template>


  <!-- Step 5 Template -->

  <ng-template #step5Template>
    <!--    Lieferungen normal/minimal in linker Spalte/Liste anzeigen.
              - Später kommen hier mehr Details rein. Insbesondere Fahrernotizen, Retourenquoten. -->
    <!--    -->
    <div>
      <h2>Lieferscheine einzeln</h2>

      <div>
        <div style="margin-bottom: 20px;">
          <div class="flex align-items-center">
            <input pInputText
                   [(ngModel)]="deliveriesSingleDeliveryAmountsFilterValue"
                   placeholder="Lieferungen filtern...">
            <div *ngIf="deliveriesSingleDeliveryAmountsFilterValue" class="text-red-500 ml-3">Filter aktiv!</div>
          </div>
          <p>
            Lieferung auswählen um Liefermengen zu bearbeiten.
          </p>
        </div>

      </div>

      <div class="flex">
        <div class="flex-column" style="width: 450px;">
          <ng-container
            *ngFor="let delivery of viewModel.plannedTargetWeekDeliveries | prfFilterDeliveries:deliveriesSingleDeliveryAmountsFilterValue">
            <prf-delivery-planning-item [delivery]="delivery"
                                        [isSelected]="delivery.id === selectedAbsoluteProductQuantitiesDelivery?.id"
                                        (clickDetails)="selectedAbsoluteProductQuantitiesDelivery = delivery;"
            ></prf-delivery-planning-item>
            <!--            TODO: absolute amount OVERRIDE input -->
          </ng-container>
        </div>


        <!--        WORKAROUND TO LOAD ALL DELIVERIES AND RUN VIEW METHOD CALLS in order to calc newTargetQuantity initially -->
        <div style="display: none;">
          <ng-container *ngFor="let delivery of viewModel.plannedTargetWeekDeliveries">
            <ng-container *ngFor="let productQuantity of viewModel.productQuantities">
<!--              {{-->
<!--                calculateSingleProductUpdatedTotalAmountViaAbsoluteDeliveryValue(-->
<!--                  productQuantity,-->
<!--                  viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(delivery.id)!.get(productQuantity.product.id)!.relativeChange,-->
<!--                  viewModel,-->
<!--                  delivery-->
<!--                )-->
<!--              }}-->
              <span>{{
                  getDeliveryProductNewTargetQuantity(
                    delivery,
                    productQuantity,
                    viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries
                      .get(delivery.id)!
                      .get(productQuantity.product.id)!.relativeChange,
                    viewModel
                  )
                }}</span>
            </ng-container>
          </ng-container>
        </div>


        <div *ngIf="selectedAbsoluteProductQuantitiesDelivery" class="ml-6">
          <!--        css display block / none is workaround for newTargetQuantity calc currently being hacked in via view method call -->
          <!--        <div [ngStyle]="{'display': selectedAbsoluteProductQuantitiesDelivery ? 'block' : 'none'}" class="ml-6">-->
          <div style="margin-bottom: 24px;">
            <ul style="padding-left: 15px; width: 510px;">
              <li><b>{{ selectedAbsoluteProductQuantitiesDelivery.marketId | market:'marketName' }}</b></li>
              <li>{{ selectedAbsoluteProductQuantitiesDelivery.deliveryDate|prfDate:'dddd, DD.MM.YYYY' }}</li>
            </ul>
          </div>

          <div *ngFor="let productQuantity of viewModel.productQuantities" class="mb-5" style="position: relative;">
            <label class="font-bold">
              {{ productQuantity.product.productNo }}
              - {{ productQuantity.product.description }}
              <!--              <span class="text-red-500"-->
              <!--                    *ngIf="productQuantity.relativeChangeAll === -100"> - Menge wird auf 0 gesetzt!</span>-->
            </label>
            <div class="p-inputgroup w-full">
              <input type="text"
                     pInputText
                     class="w-full text-center"
                     style="padding-left: 48px !important"
                     [ngModel]="viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(selectedAbsoluteProductQuantitiesDelivery.id)!.get(productQuantity.product.id)!.relativeChange"
                     (ngModelChange)="calculateSingleProductUpdatedTotalAmountViaAbsoluteDeliveryValue(productQuantity, $event, viewModel, selectedAbsoluteProductQuantitiesDelivery)" />
              <!--            Handle min max for input -->
              <span class="p-inputgroup-addon">%</span>
            </div>
            <p-slider
              [ngModel]="viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(selectedAbsoluteProductQuantitiesDelivery.id)!.get(productQuantity.product.id)!.relativeChange"
              (ngModelChange)="calculateSingleProductUpdatedTotalAmountViaAbsoluteDeliveryValue(productQuantity, $event, viewModel, selectedAbsoluteProductQuantitiesDelivery)"
              [min]="-100"
              [max]="100"
              [tabindex]="-1"
              class="w-full" />
            <div class="font-bold"
                 style="position: absolute; right: -42px; top: 29px;">
              <!--            TODO: extract into temp map values -> later used for request -->
              <!--            TODO: Refactor: calc upon ngModelChange, and only use direct value getter here -->
              <!--              <span>{{ productQuantity.totalAmountWithRelativeChange }}</span>-->
              <!--              <span>{{getDeliveryProductNewTargetQuantity(selectedAbsoluteProductQuantitiesDelivery, productQuantity, viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(selectedAbsoluteProductQuantitiesDelivery.id)!.get(productQuantity.product.id)!)}}</span>-->
              <span>{{
                  getDeliveryProductNewTargetQuantity(
                    selectedAbsoluteProductQuantitiesDelivery,
                    productQuantity,
                    viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries
                      .get(selectedAbsoluteProductQuantitiesDelivery.id)!
                      .get(productQuantity.product.id)!.relativeChange,
                    viewModel
                  )
                }}</span>
            </div>

            <!--            NEW TODO: manueller Input von totalAmt, siehe Produktmengen-Absolut-Manueller-Input-1.png -->

          </div>

          <!--        TODO: Lieferscheine einzeln-->
          <!--          CALC CURRENTLY NOT IMPLEMENTED - still wired to relativeAll -->
          <!--          <div class="mb-5" style="position: relative;">-->
          <!--            <label class="font-bold">-->
          <!--              Gesamt-->
          <!--            </label>-->
          <!--            <div class="p-inputgroup w-full">-->
          <!--              <input type="text"-->
          <!--                     pInputText-->
          <!--                     class="w-full text-center"-->
          <!--                     style="padding-left: 48px !important"-->
          <!--                     [ngModel]="allRelativeProductQuantity"-->
          <!--                     (ngModelChange)="onAllRelativeChange($event, viewModel)"-->
          <!--              />-->
          <!--              <span class="p-inputgroup-addon">%</span>-->
          <!--            </div>-->
          <!--            <p-slider [ngModel]="allRelativeProductQuantity"-->
          <!--                      (ngModelChange)="onAllRelativeChange($event, viewModel)"-->
          <!--                      [min]="-100"-->
          <!--                      [max]="100"-->
          <!--                      class="w-full" />-->
          <!--            <div class="font-bold"-->
          <!--                 style="position: absolute; right: -42px; top: 29px;">-->
          <!--              <span class="text-blue-500">{{viewModel.productQuantitiesTotalAmount}}</span>-->
          <!--            </div>-->
          <!--          </div>-->


        </div>
      </div>
    </div>

  </ng-template>

<!--  TODO: Check for button input "size" or "small"=true, instead of p-button-sm -- regression from primeng-->


  <!-- Dialog footer with action buttons and validation info -->
  <ng-template #dialogFooter>
    <div class="flex align-items-center justify-content-end mt-3">
      <div *ngIf="viewModel.validations.areSourceAndTargetWeekEqual"
           class="mr-3 text-red-500"
      >
        <i class="pi pi-exclamation-circle mr-1"></i>
        Bitte prüfen Sie die Ausgangs- und Zielwoche.
      </div>
      <p-button styleClass="p-button-secondary"
                label="Zurück"
                icon="pi pi-chevron-left"
                (click)="onClickPrevStep()"
                [disabled]="!isButtonPrevStepEnabled"
      />
      <p-button *ngIf="!isLastStep"
                styleClass="p-button-secondary ml-2"
                label="Weiter"
                icon="pi pi-chevron-right"
                iconPos="right"
                [loading]="isFetchingPlanningDeliveries"
                (click)="onClickNextStep()"
                [disabled]="!isButtonNextStepEnabled(viewModel.validations)"
      />
      <p-button *ngIf="isLastStep"
                styleClass="p-button-primary ml-2"
                label="Planung abschließen"
                iconPos="right"
                icon="pi pi-check"
                [loading]="isCompletePlanningInProgress"
                [disabled]="isCompletePlanningInProgress"
                (click)="onClickCompletePlanning($event, viewModel)"
      />
    </div>
    <p-confirmPopup />
  </ng-template>

</ng-container>
