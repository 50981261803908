import {
  CreatableEntity,
  IProduct,
  IRecipe,
} from '@prf/shared/domain';

export class LoadProducts {
  static type = '[Products] Load Products';

  constructor() {}
}

export class CreateProduct {
  static type = '[Products] Create New Product';
  constructor(public payload: { entity: CreatableEntity<IProduct> }) {}
}

export class UpdateProduct {
  static type = '[Products] Update Product';
  constructor(public payload: { entity: IProduct }) {}
}

export class UpdateProductRecipe {
  static type = '[Products] Update Product Recipe';
  constructor(public payload: {
    productId: number,
    recipe: IRecipe
  }) {}
}
