import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { MarketPipe } from '../../../pipes/entity.pipe';
import { InputTextModule } from 'primeng/inputtext';
import { EditorModule } from 'primeng/editor';
import { ButtonModule } from 'primeng/button';
import { DELIVERY_SLIP_EMAIL_TEXT, INVOICE_EMAIL_TEXT } from '../email-text-templates';
import { DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChipsModule } from 'primeng/chips';

export interface SendEmailDialogData {
  // TODO: Refactor: If this dialog is needed somewhere else than for sending invoices and deliverySlips -> remove this type prop and pass initial mail text from parent.
  type: 'DELIVERY_SLIP' | 'INVOICE';
  recipientEmail: string | null;
  subject: string;
}

export interface SendEmailCloseResponse {
  recipientEmails: string[];
  subject: string;
  message: string;
}

@Component({
  selector: 'prf-send-email-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ConfirmPopupModule,
    MarketPipe,
    InputTextModule,
    EditorModule,
    ButtonModule,
    DynamicDialogModule,
    ChipsModule,
  ],
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendEmailDialogComponent implements OnInit {
  private confirmationService = inject(ConfirmationService);
  // private cdRef = inject(ChangeDetectorRef);
  // private toastService = inject(ToastService);
  // private dialogService = inject(DialogService);
  private dialogRef = inject(DynamicDialogRef);
  private dialogConfig = inject(DynamicDialogConfig<SendEmailDialogData>);

  recipientEmails: string[] = [];
  subject: string = '';
  editorText: string = DELIVERY_SLIP_EMAIL_TEXT;

  ngOnInit(): void {
    const dialogData: SendEmailDialogData = this.dialogConfig.data;
    switch (dialogData.type) {
      case 'DELIVERY_SLIP':
        this.editorText = DELIVERY_SLIP_EMAIL_TEXT;
        break;
      case 'INVOICE':
        this.editorText = INVOICE_EMAIL_TEXT;
        break;
      default:
        console.error(`Unknown SendEmailDialog type property: "${dialogData.type}"`);
    }

    let emailChipValues: string[] = [];
    if (dialogData.recipientEmail && dialogData.recipientEmail.length > 0) {
      emailChipValues = [dialogData.recipientEmail];
    }
    this.recipientEmails = emailChipValues;
    this.subject = dialogData.subject ?? this.subject;
  }

  shouldDisableSendMailButton(): boolean {
    return (this.recipientEmails.length === 0) || (this.recipientEmails.length > 1) || (this.subject.length === 0);
  }

  onClickSendMail(): void {
    const closeResponse: SendEmailCloseResponse = {
      recipientEmails: this.recipientEmails,
      subject: this.subject,
      message: this.editorText,
    };
    this.dialogRef.close(closeResponse);
  }
}
