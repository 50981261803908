import { IDelivery } from "@prf/shared/domain";
import {
  GroupedDeliveries
} from "../../../../view-models/delivery-planning-draggable-week-overview-view-model.queries";
import { GroupingStrategy } from "./grouping-strategy";
import { CalendarWeekInfo } from "../../../../state/ui.state";

export class DateGroupingStrategy extends GroupingStrategy {
  group(deliveries: IDelivery[], calendarWeekInfo: CalendarWeekInfo): GroupedDeliveries[] {
    // Initialize all weekdays starting from the Monday of the given calendar week.
    const grouped: { [key: string]: IDelivery[] } = this.initializeWeekdays(calendarWeekInfo);

    // Group deliveries by date.
    for (const delivery of deliveries) {
      const date = delivery.deliveryDate as string;
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(delivery);
    }

    // TODO:
    //     return [
//       { title: "Deaktivierte Lieferungen/Märkte", headerIconClass: 'pi-calendar', deliveries: [] },
//       { title: "Aktive Lieferungen/Märkte, die nicht in der Zielwoche sind", headerIconClass: 'pi-calendar', deliveries: [] },
//       ...Object.entries(grouped).map(([date, deliveries]) => ({
//         title: this.formatDate(date),
//         headerIconClass: 'pi-calendar',
//         deliveries,
//       }))
//     ];

    return Object.entries(grouped).map(([date, deliveries]) => ({
      title: this.formatDate(date),
      headerIconClass: 'pi-calendar',
      deliveries,
      deliveriesCount: deliveries.length,
      updateFields: {
        deliveryDate: date
      }
    }));
  }
}
