import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDelivery } from '@prf/shared/domain';
import { CardModule } from 'primeng/card';
import { DragDropModule } from 'primeng/dragdrop';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  DeliveryDragGroupingType,
  GroupedDeliveries,
} from '../../../../view-models/delivery-planning-draggable-week-overview-view-model.queries';
import { Store } from '@ngxs/store';
import { UsersState } from '../../../../state/entities/users.state';
import { PanelModule } from 'primeng/panel';
import { DeliveryPlanningItemComponent } from '../delivery-drag-item/delivery-planning-item.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { UpdateDraggedDelivery } from '../../../../state/entities/deliveries.actions';
import { DateGroupingStrategy } from '../../classes/delivery-grouping-strategies/date-grouping-strategy';
import { GroupingStrategy } from '../../classes/delivery-grouping-strategies/grouping-strategy';
import { DriverGroupingStrategy } from '../../classes/delivery-grouping-strategies/driver-grouping-strategy';
import { PickListPlanningDelivery } from '../../../../shared/components/delivery/plan-new-delivery-week-dialog/plan-new-delivery-week-dialog.component';
import { UpdateTransferredTargetWeekDelivery } from '../../../../state/ui.actions';
import { CalendarWeekInfo } from "../../../../state/ui.state";

@Component({
  selector: 'prf-delivery-planning-draggable-view',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    DragDropModule,
    CdkDropListGroup,
    CdkDropList,
    CdkDrag,
    PanelModule,
    DeliveryPlanningItemComponent,
    ScrollPanelModule,
  ],
  templateUrl: './delivery-planning-draggable-view.component.html',
  styleUrls: ['./delivery-planning-draggable-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryPlanningDraggableViewComponent {
  constructor(private store: Store) {}

  get groupingType(): DeliveryDragGroupingType {
    return this._groupingType;
  }

  // TODO: Refactor: Remove this and pass delivItem as template (slot).
  @Input()
  showDeliveryItemDetailsButton: boolean = true;

  @Input({ required: true }) set groupingType(groupingType: DeliveryDragGroupingType) {
    this._groupingType = groupingType;
    this.updateGroupedDeliveries();
  }

  private _groupingType!: DeliveryDragGroupingType;

  @Input({ required: true }) set deliveries(deliveries: IDelivery[]) {
    // TODO: Check why this is "already" set/called, when a click on DETAILS of a planning items occurs.

    this._availableDeliveries = JSON.parse(JSON.stringify(deliveries));
    this.updateGroupedDeliveries();
  }

  private _calendarWeekInfo!: CalendarWeekInfo
  get calendarWeekInfo(): CalendarWeekInfo {
    return this._calendarWeekInfo;
  }

  @Input({ required: true }) set calendarWeekInfo(value: CalendarWeekInfo) {
    this._calendarWeekInfo = value;
    this.updateGroupedDeliveries();
  }

  private _availableDeliveries!: IDelivery[];
  get availableDeliveries(): IDelivery[] {
    return this._availableDeliveries;
  }

  @Input()
  selectedDetailsDelivery: IDelivery | null = null;

  groupedDeliveries!: GroupedDeliveries[];

  @Output()
  selectEntityForEdit = new EventEmitter<IDelivery>();

  onDrop(
    $event: CdkDragDrop<IDelivery[], IDelivery[], IDelivery>,
    deliveryGroup: GroupedDeliveries,
  ) {
    if ($event.previousContainer === $event.container) {
      moveItemInArray<IDelivery>($event.container.data, $event.previousIndex, $event.currentIndex);
    } else {
      transferArrayItem<IDelivery>(
        $event.previousContainer.data,
        $event.container.data,
        $event.previousIndex,
        $event.currentIndex,
      );

      const droppedDelivery = $event.item.data as PickListPlanningDelivery;

      // Only already existing deliveries should be updated during planning, ie before planning completion.
      // New deliveries will only create, once planning is completed/confirmed.

      // New/transferred delivery.
      if (droppedDelivery.isNewDelivery) {
        // Apply update fields manually
        const updateFields = deliveryGroup.updateFields as any;
        for (const key in updateFields) {
          if (updateFields[key] !== undefined) {
            (droppedDelivery as any)[key] = updateFields[key];
          }
        }
        this.store.dispatch(
          new UpdateTransferredTargetWeekDelivery({
            updatedDelivery: droppedDelivery,
          }),
        );
      } else {
        // Already existing (backend) deliveries.
        this.store.dispatch(
          new UpdateDraggedDelivery({
            entity: droppedDelivery,
            updateFields: deliveryGroup.updateFields,
          }),
        );
      }
    }
  }

  private getGroupingStrategy(groupingType: DeliveryDragGroupingType): GroupingStrategy {
    switch (groupingType) {
      case 'BY_DRIVER':
        return new DriverGroupingStrategy({
          drivers: this.store.selectSnapshot(UsersState.drivers),
        });
      case 'BY_WEEKDAY':
        return new DateGroupingStrategy();
      default:
        throw new Error(`GroupingStrategy for type ${groupingType} doesn't exist.`);
    }
  }

  private updateGroupedDeliveries(): void {
    if (this.groupingType && this.calendarWeekInfo) {
      this.groupedDeliveries = this.getGroupingStrategy(this.groupingType).group(
        this._availableDeliveries,
        this.calendarWeekInfo,
      );
    }
  }
}
