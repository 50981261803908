import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { IMarket, PerfoEntityType } from '@prf/shared/domain';
import { CreateMarket, LoadMarkets, UpdateMarket } from './markets.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateMarketService,
  GetAllMarketsService,
  MarketFieldsFragment,
  UpdateMarketService,
} from '../../graphql/markets-operations.generated';
import { CreateMarketInput, UpdateMarketInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';

type LocalModel = IMarket;
type LocalStateModel = MarketsStateModel;
type LocalStateContext = StateContext<MarketsStateModel>;

export interface MarketsStateModel {
  items: LocalModel[];
}

@State<MarketsStateModel>({
  name: 'markets',
  defaults: {
    items: [],
  },
})
@Injectable()
export class MarketsState
  extends BaseEntityState<
    {
      fetchAll: GetAllMarketsService;
      create: CreateMarketService;
      update: UpdateMarketService;
    },
    IMarket
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'market';

  // TODO: Refactor: Extract into something abstract. Also needed for retailers.state.ts
  // TODO: Refactor: Make sure the name of this property suggests picking is for Create/Update, and not Read.
  private readonly entityPropertiesToPick: Record<keyof IMarket, boolean> = {
    id: true,
    marketName: true,
    billingAddress: true,
    deliveryAddress: true,
    deliverySlipEmail: true,
    billToGLN: true,
    deliverToGLN: true,
    invoiceEmail: true,
    isDirectInvoice: true,
    notes: true,
    operator: true,
    retailerId: true,
    contactPhone: true,
    customReceiptTextsPos1: true,
    customReceiptTextsPos2: true,

    // Skip
    customerNumber: false,
  };

  constructor(
    protected getAllMarketsService: GetAllMarketsService,
    protected createMarketService: CreateMarketService,
    protected updateMarketService: UpdateMarketService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllMarketsService,
        create: createMarketService,
        update: updateMarketService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadMarkets();
  }

  protected mapGqlEntityToUiEntity(entity: MarketFieldsFragment): IMarket {
    return {
      id: entity.id,
      // TODO: Check if nullish coalescing ?? should be used here
      retailerId: entity.retailer?.id || null,
      marketName: entity.marketName,
      customerNumber: entity.customerNumber,
      operator: entity.operator,
      billToGLN: entity.billToGLN,
      deliverToGLN: entity.deliverToGLN,

      billingAddress: {
        street: entity.billingAddress?.street || "",
        houseNumber: entity.billingAddress?.houseNumber || "",
        postalCode: entity.billingAddress?.postalCode || "",
        city: entity.billingAddress?.city || "",
        country: entity.billingAddress?.country || "",
      },

      deliveryAddress: {
        street: entity.deliveryAddress.street,
        houseNumber: entity.deliveryAddress.houseNumber,
        postalCode: entity.deliveryAddress.postalCode,
        city: entity.deliveryAddress.city,
        country: entity.deliveryAddress.country,
      },

      contactPhone: entity.contactPhone,
      invoiceEmail: entity.invoiceEmail,
      deliverySlipEmail: entity.deliverySlipEmail,
      isDirectInvoice: entity.isDirectInvoice,
      // TODO: Check if nullish coalescing ?? should be used here
      notes: entity.notes || "",
      customReceiptTextsPos1:
        entity.customReceiptTextsPos1?.map((crTp1) => {
          return {
            key: crTp1.key,
            value: crTp1.value,
          };
        }) || null,
      customReceiptTextsPos2:
        entity.customReceiptTextsPos2?.map((crTp1) => {
          return {
            key: crTp1.key,
            value: crTp1.value,
          };
        }) || null
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadMarkets)
  protected loadMarkets(ctx: LocalStateContext, action: LoadMarkets): Observable<any> {
    return this.loadEntities<MarketFieldsFragment[]>(ctx, 'markets');
  }

  @Action(CreateMarket)
  createMarket(ctx: LocalStateContext, action: CreateMarket): Observable<any> {
    const entity = this.pickEntityProperties(action.payload.entity);
    return this.createEntity<CreateMarketInput>(ctx, entity, 'createMarket');
  }

  @Action(UpdateMarket)
  updateMarket(ctx: LocalStateContext, action: UpdateMarket): Observable<any> {
    const entity = this.pickEntityProperties(action.payload.entity);

    return this.updateEntity<UpdateMarketInput>(ctx, entity, 'updateMarket');
  }

  private pickEntityProperties(entity: any): IMarket {
    const market: Partial<IMarket> = {};
    Object.keys(this.entityPropertiesToPick).forEach((key) => {
      if (this.entityPropertiesToPick[key as keyof IMarket] && key in entity) {
        market[key as keyof IMarket] = entity[key];
      }
    });
    return market as IMarket;
  }
}
