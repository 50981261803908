import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { APP_ROUTES } from './app.routes';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { PrimeNGConfig } from 'primeng/api/primengconfig';
import { ConfirmationService, FilterMatchMode, MessageService } from 'primeng/api';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { HotkeysModule } from '@ngneat/hotkeys';
import { DialogService } from 'primeng/dynamicdialog';
import { GraphQLModule } from './graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MessagesModule } from 'primeng/messages';
import * as dayjs from 'dayjs';
import 'dayjs/locale/de';
import * as utc from 'dayjs/plugin/utc';
import * as isBetween from 'dayjs/plugin/isBetween';
import * as weekday from 'dayjs/plugin/weekday';
import * as weekOfYear from 'dayjs/plugin/weekOfYear';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { environment } from '../environments/environment';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeDe, 'de');

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initDayJs,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideRouter(APP_ROUTES, withEnabledBlockingInitialNavigation()),

    importProvidersFrom([
      BrowserAnimationsModule,
      MessagesModule,
      HttpClientModule,
      GraphQLModule,
      NgxsModule.forRoot([], {
        developmentMode: !environment.production,
      }),
      // TODO: Disable in production, via env
      NgxsReduxDevtoolsPluginModule.forRoot(),
      HotkeysModule,
    ]),
    MessageService,
    ConfirmationService,
    DialogService,
  ],
};

export function initDayJs(): () => Promise<any> {
  return () =>
    new Promise((resolve) => {
      dayjs.locale('de');
      dayjs.extend(utc);
      dayjs.extend(isBetween);
      dayjs.extend(weekday);
      dayjs.extend(weekOfYear);
      dayjs.extend(isoWeek);
      resolve(true);
    });
}

export const primeNgConfig: Pick<PrimeNGConfig, 'filterMatchModeOptions'> = {
  filterMatchModeOptions: {
    text: [
      FilterMatchMode.CONTAINS,
      FilterMatchMode.NOT_CONTAINS,
      FilterMatchMode.STARTS_WITH,
      FilterMatchMode.ENDS_WITH,
      // FilterMatchMode.EQUALS,
      // FilterMatchMode.NOT_EQUALS
    ],
    numeric: [
      FilterMatchMode.EQUALS,
      FilterMatchMode.NOT_EQUALS,
      FilterMatchMode.LESS_THAN,
      FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
      FilterMatchMode.GREATER_THAN,
      FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
    ],
    date: [
      FilterMatchMode.DATE_IS,
      FilterMatchMode.DATE_IS_NOT,
      FilterMatchMode.DATE_BEFORE,
      FilterMatchMode.DATE_AFTER,
      'between',
    ],
  },
};

// TODO: extract into own config file
export const primeNgTranslations = {
  startsWith: 'Beginnt mit',
  contains: 'Enthält',
  notContains: 'Enthält nicht',
  endsWith: 'Endet mit',
  equals: 'Gleich',
  notEquals: 'Nicht gleich',
  noFilter: 'Kein Filter',
  lt: 'Weniger als',
  lte: 'Weniger als oder gleich',
  gt: 'Mehr als',
  gte: 'Mehr als oder gleich',
  is: 'Ist',
  isNot: 'Ist nicht',
  before: 'Vor',
  after: 'Nach',
  dateIs: 'Datum ist',
  dateIsNot: 'Datum ist nicht',
  dateBefore: 'Datum liegt vor',
  dateAfter: 'Datum liegt nach',
  clear: 'Löschen',
  apply: 'Anwenden',
  matchAll: 'Alle übereinstimmen',
  matchAny: 'Beliebige übereinstimmen',
  addRule: 'Regel hinzufügen',
  removeRule: 'Regel entfernen',
  accept: 'Ja',
  reject: 'Nein',
  choose: 'Auswählen',
  upload: 'Hochladen',
  cancel: 'Stornieren',
  dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  monthNames: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mär',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
  dateFormat: 'dd.mm.yy',
  firstDayOfWeek: 1,
  today: 'Heute',
  weekHeader: 'KW',
  weak: 'Schwach',
  medium: 'Mittel',
  strong: 'Stark',
  passwordPrompt: 'Geben Sie ein Passwort ein',
  emptyMessage: 'Keine Ergebnisse gefunden',
  emptyFilterMessage: 'Keine Ergebnisse gefunden',
};
