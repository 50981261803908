import { Injectable } from '@angular/core';
import { Message, MessageService } from "primeng/api";
import { ENTITY_TYPE_LABELS, PerfoEntityType } from "@prf/shared/domain";

export enum NotificationActionType {
  READ,
  CREATE,
  UPDATE,
  DELETE,
}

@Injectable({
  providedIn: 'root',
  deps: [MessageService],
})
export class ToastService {
  private readonly DEFAULT_MESSAGE_OPTIONS: Partial<Message> = {
    life: 3500,
    closable: true,
    // id?: any;
    // key?: string;
    // sticky?: boolean;
    // closable?: boolean;
    // data?: any;
    // icon?: string;
    // contentStyleClass?: string;
    // styleClass?: string;
    // closeIcon?: string;
  };

  private readonly DEFAULT_SUCCESS_OPTIONS: Partial<Message> = {
    ...this.DEFAULT_MESSAGE_OPTIONS,
    severity: 'success',
  };

  private readonly DEFAULT_ERROR_OPTIONS: Partial<Message> = {
    ...this.DEFAULT_MESSAGE_OPTIONS,
    severity: 'error',
  };

  constructor(private messageService: MessageService) {}

  showSuccess(title: string, detail?: string): void {
    this.messageService.add({
      ...this.DEFAULT_SUCCESS_OPTIONS,
      summary: title,
      detail: detail,
    });
  }

  showError(title: string, detail?: string): void {
    this.messageService.add({
      ...this.DEFAULT_ERROR_OPTIONS,
      summary: title,
      detail: detail,
    });
  }

  // TODO: Refactor: remove optional markers?
  showEntityCrudSuccess(
    entityType: PerfoEntityType,
    actionType: NotificationActionType
  ): void {
    const entityLabel = entityType ? ENTITY_TYPE_LABELS[entityType] : `[UNKNOWN entityType: ${entityType}]`;
    const actionVerb = actionType ? this.getActionVerb(actionType) : "bearbeitet";

    this.messageService.add({
      ...this.DEFAULT_SUCCESS_OPTIONS,
      summary: `${entityLabel} ${actionVerb}`,
      // detail: 'Details (TODO)',
      detail: undefined,
    });
  }

  showEmailSentSuccess(
  ): void {
    this.messageService.add({
      ...this.DEFAULT_SUCCESS_OPTIONS,
      summary: `E-Mail erfolgreich versandt.`,
      // detail: 'Details (TODO)',
      detail: undefined,
    });
  }

  showEmailSentError(
  ): void {
    this.messageService.add({
      ...this.DEFAULT_ERROR_OPTIONS,
      summary: `Fehler beim Senden der E-Mail.`,
      // detail: 'Details (TODO)',
      detail: undefined,
    });
  }

  showEntityCrudError(
    entityType: PerfoEntityType,
    actionType: NotificationActionType,
    error?: any, // Changed type to 'any' to handle complex error object
  ): void {
    console.warn('showEntityCrudError - error', JSON.stringify(error));

    // Check if the error has graphQL error structure and validation errors
    const errorMessage = error?.graphQLErrors?.[0]?.message
    let detailMessage = 'Bitte Seite neu laden (F5).';

    if (errorMessage) {
      // const errorMessages = Object.values(errorMessage).join('. ');
      detailMessage = errorMessage;
    }

    this.messageService.add({
      ...this.DEFAULT_ERROR_OPTIONS,
      summary: 'Fehler',
      detail: detailMessage,
    });
  }

  private getActionVerb(actionType: NotificationActionType): string {
    switch (actionType) {
      case NotificationActionType.CREATE:
        return "angelegt";
      case NotificationActionType.READ:
        return "geladen";
      case NotificationActionType.UPDATE:
        return "aktualisiert";
      case NotificationActionType.DELETE:
        return "gelöscht";
      default:
        return "[UNKNOWN actionType: " + actionType + "]";
    }
  }
}
