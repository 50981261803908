import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IMarket } from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { MarketDetailsComponent } from '../market-details/market-details.component';
import { CreateMarket } from '../../../../state/entities/markets.actions';
import { take } from "rxjs";

@Component({
  selector: 'prf-market-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    MarketDetailsComponent,
  ],
  templateUrl: './market-details-dialog.component.html',
  styleUrls: ['./market-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketDetailsDialogComponent {
  entity: IMarket;
  updateInProgress = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig, // TODO: Type this DynamicDialogConfig<T>
    private store: Store
  ) {
    this.entity = this.dialogConfig.data.entity; // TODO: Use typed data access, via typed config injection.
  }

  handleCreateEntity(entity: IMarket) {
    this.updateInProgress = true;
    this.store
      .dispatch(new CreateMarket({ entity: entity }))
      .pipe(take(1))
      .subscribe((result) => {
        this.updateInProgress = false;
        this.dialogRef.close();
      });
  }
}
