<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="retailerName">Händlername</label>
      <input id="retailerName" type="text" [(ngModel)]="entity.retailerName" pInputText />
    </div>
    <div class="p-field prf-field">
      <label for="retailerNumber">Händler-Nummer</label>
      <input id="retailerNumber"
             type="text"
             [ngModel]="entity.retailerNumber"
             [disabled]="true"
             placeholder="Wird automatisch generiert"
             pInputText />
    </div>
    <!--    <div class="p-field prf-field">-->
    <!--      <label for="discount">Rabatt (%)</label>-->
    <!--      <input id="discount" type="number" [(ngModel)]="entity.discount" pInputText />-->
    <!--    </div>-->

    <prf-address-input [address]="entity.billingAddress"
                       label="Rechnungsadresse" />

    <div class="p-field prf-field">
      <label for="gln">RG-GLN</label>
      <input id="gln"
             type="text"
             [(ngModel)]="entity.gln"
             [maxlength]="20"
             pInputText />
    </div>

    <div class="p-field prf-field">
      <label for="invoiceEmail">E-Mail (Rechnungsversand)</label>
      <span class="p-input-icon-left">
        <i class="pi pi-envelope"></i>
        <input id="invoiceEmail"
               type="email"
               [(ngModel)]="entity.invoiceEmail"
               pInputText />
      </span>
    </div>


    <div class="p-field prf-field">
      <label for="phone">Telefon</label>
      <span class="p-input-icon-left">
        <i class="pi pi-phone"></i>
        <input id="phone" type="tel" [(ngModel)]="entity.phone" pInputText />
      </span>
    </div>
  </div>
</div>

<div class="flex justify-content-between">
  <p-button *ngIf="showClose"
            [outlined]="true"
            label="Schließen"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="clickClose.emit()"
  />

  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
            styleClass="p-button-primary"
            (click)="onClickSave()" />
</div>


