import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { IProduct, PerfoEntityType } from '@prf/shared/domain';
import { CreateProduct, LoadProducts, UpdateProduct } from './products.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateProductMutation,
  CreateProductMutationVariables,
  CreateProductService,
  GetAllProductsService,
  ProductFieldsFragment,
  UpdateProductService,
} from '../../graphql/products-operations.generated';
import { MutationResult } from 'apollo-angular';
import { UpdateProductInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';

type LocalModel = IProduct;
type LocalStateModel = ProductsStateModel;
type LocalStateContext = StateContext<ProductsStateModel>;

export interface ProductsStateModel {
  items: LocalModel[];
}

@State<ProductsStateModel>({
  name: 'products',
  defaults: {
    items: [],
  },
})
@Injectable()
export class ProductsState
  extends BaseEntityState<
    {
      fetchAll: GetAllProductsService;
      create: CreateProductService;
      update: UpdateProductService;
    },
    IProduct
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'product';

  constructor(
    protected getAllProductsService: GetAllProductsService,
    protected createProductService: CreateProductService,
    protected updateProductService: UpdateProductService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllProductsService,
        create: createProductService,
        update: updateProductService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadProducts();
  }

  protected mapGqlEntityToUiEntity(entity: ProductFieldsFragment): LocalModel {
    return {
      id: entity.id,
      category: entity.category,
      description: entity.description,
      ean: entity.ean,
      grossPrice: entity.grossPrice,
      netPrice: entity.netPrice,
      pack: entity.pack,
      productNo: entity.productNo,
      // recipe: entity.recipe,
      uvp: entity.uvp,
      vatRate: entity.vatRate,
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadProducts)
  protected loadProducts(
    ctx: LocalStateContext,
    action: LoadProducts,
  ): Observable<any> {
    return this.loadEntities<ProductFieldsFragment[]>(ctx, 'products');
  }

  @Action(CreateProduct)
  createProduct(
    ctx: LocalStateContext,
    action: CreateProduct,
  ): Observable<MutationResult<CreateProductMutation>> {
    return this.createEntity<CreateProductMutationVariables>(
      ctx,
      action.payload.entity,
      'createProduct',
    );
  }

  @Action(UpdateProduct)
  updateProduct(
    ctx: LocalStateContext,
    action: UpdateProduct,
  ): Observable<any> {
    return this.updateEntity<UpdateProductInput>(
      ctx,
      action.payload.entity,
      'updateProduct',
    );
  }
}
