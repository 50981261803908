<!--<h3>SIDEBAR</h3>-->
<!--           transitionOptions="200ms"-->
<p-sidebar *ngIf="isSidebarOpen$ | async"
           transitionOptions="0ms"
           [modal]="false"
           [showCloseIcon]="false"
           [closeOnEscape]="false"
           [(visible)]="isSidebarVisible">
  <ng-template pTemplate="header">
    <div class="flex flex-row align-items-center flex-grow-1">
      <h3>
        Perfo
        <div class="hosting-stage-name" [ngClass]="hostingStage" [innerHTML]="hostingStage"></div>
      </h3>
      <div class="justify-content-end user-menu">
        <p-avatar icon="pi pi-user"
                  size="normal"
                  class="cursor-pointer ml-2"
                  (click)="userMenu.toggle($event)"
        ></p-avatar>
        <p-menu #userMenu [popup]="true" [model]="userMenuItems"></p-menu>
      </div>
    </div>
    <p-button styleClass="p-button-outlined p-button-secondary m-3"
              icon="pi pi-bars"
              pTooltip="Seitenleiste an/aus"
              size="small"
              [outlined]="true"
              (click)="onClickSidebarToggle()"></p-button>
  </ng-template>

  <ng-template pTemplate="content">
    <!-- TODO: Refactor: make items dynamic via member? -> via ROUTE_CONFIG -->
    <ul style="list-style-type: none;">
      <!-- TODO: give dashboard its own route /dashboard-->
      <li routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }" routerLink="/"><a>Dashboard</a></li>
      <li class="inactive" routerLinkActive="active" routerLink="/reports"><a>Statistiken</a></li>
      <li class="pointer-events-none" routerLinkActive="active"><a>Stammdaten</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/products"><a>Artikel</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/ingredients"><a>Rohware</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/retailers"><a>Händler</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/markets"><a>Märkte</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/users"><a>Benutzer</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/vehicles"><a>Fahrzeuge</a></li>
      <li class="sub-item inactive" routerLinkActive="active" routerLink="/accounts"><a>Konten</a></li>
      <li class="pointer-events-none" routerLinkActive="active"><a>Lieferungen</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/deliveries"><a>Übersicht</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/delivery-planning"><a>Wochenplanung</a></li>
      <li class="sub-item inactive" routerLinkActive="active" routerLink="/tours"><a>Touren (Karte)</a></li>
      <li class="inactive" routerLinkActive="active" routerLink="/production-planning"><a>Produktionsplanung</a></li>
      <li class="pointer-events-none" routerLinkActive="active"><a>Rechnungswesen</a></li>
      <li class="sub-item" routerLinkActive="active" routerLink="/invoices"><a>Rechnungen</a></li>
      <li class="sub-item inactive" routerLinkActive="active" routerLink="/credit-memos"><a>Gutschriften</a></li>
      <!-- - Zahlungen / (Avise)-->
      <li class="sub-item inactive" routerLinkActive="active" routerLink="/payments"><a>Zahlungen</a></li>
      <!-- - Belegstatistiken-->
      <li class="inactive" routerLinkActive="active" routerLink="/logging"><a>Logging</a></li>
      <li class="inactive" routerLinkActive="active" routerLink="/settings"><a>Einstellungen</a></li>
    </ul>
  </ng-template>

<!--  <ng-template pTemplate="footer">-->
<!--    <pre>-->
<!--      TODO: Allg. Kalender-->
<!--      TODO: checkbox for push notifications-->
<!--    </pre>-->
<!--  </ng-template>-->
</p-sidebar>

<!--FOOTER ETC: -->
<!--<p-sidebar [(visible)]="sidebarVisible">-->
<!--  <ng-template pTemplate="header">Header Content</ng-template>-->
<!--  <ng-template pTemplate="content">Body Content</ng-template>-->
<!--  <ng-template pTemplate="footer">Footer Content</ng-template>-->
<!--</p-sidebar>-->
