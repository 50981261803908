import { Selector } from '@ngxs/store';
import { IDelivery, IUser } from '@prf/shared/domain';
import { DeliveriesState } from '../state/entities/deliveries.state';
import { UsersState } from '../state/entities/users.state';
import {
  CalendarWeekInfo,
  CalendarWeekOption,
  DeliveryPlanningNewWeekDialogUiState,
  DeliveryPlanningOverviewUiState,
  UiState
} from "../state/ui.state";
import * as dayjs from 'dayjs';

export type DeliveryDragGroupingType = 'BY_WEEKDAY' | 'BY_DRIVER';

export type DraggedDeliveryUpdateFields = {
  [K in keyof IDelivery]?: IDelivery[K];
};

export interface GroupedDeliveries {
  title: string;
  deliveries?: IDelivery[];
  deliveriesCount: number;
  nestedGroups?: GroupedDeliveries[];
  headerIconClass: string;
  isNotAssignedGroup?: boolean;
  updateFields: DraggedDeliveryUpdateFields;
}

export interface DeliveryPlanningDraggableViewPageViewModel {
  deliveriesForKW: IDelivery[];
  dragGroupingType: DeliveryDragGroupingType;
  calendarWeeks: {
    options: CalendarWeekOption[];
    selectedWeekNumber: number | null;
    // TODO: Refactor: Check if selectedWeekNumber and selectedCalendarWeekInfo can be merged.
    selectedCalendarWeekInfo: CalendarWeekInfo;
  };
  selectedDetailsDelivery: IDelivery | null;
  deliveryPlanningNewWeekDialogVisible: boolean;
}

export class DeliveryPlanningDraggableWeekOverviewViewModelQueries {
  @Selector([
    DeliveriesState.items,
    UsersState.drivers,
    UiState.deliveryPlanningOverviewState,
    UiState.deliveryPlanningNewWeekState,
  ])
  static getViewModel(
    deliveries: IDelivery[],
    drivers: IUser[],
    deliveryPlanningOverviewState: DeliveryPlanningOverviewUiState,
    deliveryPlanningNewWeekDialog: DeliveryPlanningNewWeekDialogUiState,
  ): DeliveryPlanningDraggableViewPageViewModel {
    const selectedCalendarWeekOption = deliveryPlanningOverviewState.calendarWeeks.options.find(
      (cw) => cw.weekNumber === deliveryPlanningOverviewState.calendarWeeks.selectedWeekNumber,
    )!;

    return {
      dragGroupingType: deliveryPlanningOverviewState.dragGroupingType,
      deliveriesForKW: [
        ...deliveries.filter((delivery) =>
          dayjs(delivery.deliveryDate).isBetween(
            selectedCalendarWeekOption.fromDate,
            selectedCalendarWeekOption.toDate,
            'day',
            '[]',
          ),
        ),
      ],
      calendarWeeks: {
        options: [...deliveryPlanningOverviewState.calendarWeeks.options],
        selectedWeekNumber: deliveryPlanningOverviewState.calendarWeeks.selectedWeekNumber,
        selectedCalendarWeekInfo: {
          calendarWeekNumber: deliveryPlanningOverviewState.calendarWeeks.selectedWeekNumber,
          rangeDates: [new Date(selectedCalendarWeekOption.fromDate), new Date(selectedCalendarWeekOption.toDate)],
        }
      },
      selectedDetailsDelivery: deliveryPlanningOverviewState.selectedDetailsDelivery,
      deliveryPlanningNewWeekDialogVisible: deliveryPlanningNewWeekDialog.visible,
    };
  }
}
