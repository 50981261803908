import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorModule, PaginatorState } from "primeng/paginator";
import { CalendarWeekOption } from '../../../../state/ui.state';
import { Store } from '@ngxs/store';
import { SetDeliveryPlanningOverviewSelectedCalendarWeek } from "../../../../state/ui.actions";

@Component({
  selector: 'prf-calendar-week-paginator',
  standalone: true,
  imports: [CommonModule, PaginatorModule],
  templateUrl: './calendar-week-paginator.component.html',
  styleUrls: ['./calendar-week-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarWeekPaginatorComponent implements AfterViewInit {
  constructor(
    private store: Store,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {}

  numberOfWeekOptions = 0;

  get calendarWeekOptions(): CalendarWeekOption[] {
    return this._calendarWeekOptions;
  }

  @Input({ required: true }) set calendarWeekOptions(calendarWeeks: CalendarWeekOption[]) {
    this._calendarWeekOptions = calendarWeeks;
  }

  private _calendarWeekOptions!: CalendarWeekOption[];

  get selectedCalendarWeekNumber(): number | null {
    return this._selectedCalendarWeekNumber;
  }

  @Input({ required: true }) set selectedCalendarWeekNumber(weekNumber: number | null) {
    this._selectedCalendarWeekNumber = weekNumber;
    this.initPagination();
  }

  @Input({ required: true})
  deliveriesCountForWeek!: number;

  private _selectedCalendarWeekNumber!: number | null;

  // Offset
  selectedCalendarWeekIndex: number = 0;
  selectedCalenderWeekOption: CalendarWeekOption | null = null;


  onPageChange(event: PaginatorState): void {
    this.selectedCalenderWeekOption = this.calendarWeekOptions[event.first!];
    const selectedWeekNumber = this.selectedCalenderWeekOption.weekNumber;

    this.store.dispatch(
      new SetDeliveryPlanningOverviewSelectedCalendarWeek({
        weekNumber: selectedWeekNumber,
      }),
    );


    // TODO: keep original paginator counting intact, inside paginator element... then on each page change, recalc/reWrite CW labels... this way "infinite" pages are possible
  }

  ngAfterViewInit() {
    this.overridePaginatorPageLabels();
  }

  // Replaces the regular page labels (1, 2, 3 ,...) with "KW#" labels.
  private overridePaginatorPageLabels(): void {
    const paginatorLabels = this.el.nativeElement.querySelectorAll('.p-paginator-page');
    paginatorLabels.forEach((pageElem: any, index: any) => {
      const calendarWeekLabel = `KW${this.calendarWeekOptions[index].weekNumber}`;
      this.renderer.setProperty(pageElem, 'textContent', calendarWeekLabel);
    });
  }

  private initPagination(): void {
    this.numberOfWeekOptions = this.calendarWeekOptions.length;
    this.selectedCalenderWeekOption =
      this.calendarWeekOptions.find((cw) => cw.weekNumber === this.selectedCalendarWeekNumber) ||
      null;
    this.selectedCalendarWeekIndex = this.calendarWeekOptions.findIndex(
      (cw) => cw.weekNumber === this.selectedCalendarWeekNumber,
    );
  }
}
