import { createPerfoTableConfig } from '../../shared/components/data-table/data-table.component';
import { DataTableMarket } from '@prf/shared/domain';

export const MarketsTableConfig = createPerfoTableConfig<DataTableMarket>([
  {
    header: 'Kunden-Nr',
    field: 'customerNumber',
    sortable: true,
  },
  {
    header: 'Name',
    field: 'marketName',
    sortable: true,
  },
  {
    header: 'Betreiber',
    field: 'operator',
    sortable: true,
  },
  // Aktuell erstmal nur GLN. Später kommen ILNs für REWE. Diese dann ggf. über custom identification "type", zB am Händler. Override ggf.
  {
    header: 'RE-GLN',
    field: 'billToGLN',
    sortable: true,
    customRender: { monoFont: true },
  },
  {
    header: 'WE-GLN',
    field: 'deliverToGLN',
    sortable: true,
    customRender: { monoFont: true },
  },
  {
    header: 'Rechnungsadresse',
    field: 'billingAddressLabel',
    sortable: true,
  },
  {
    header: 'Lieferadresse',
    field: 'deliveryAddressLabel',
    sortable: true,
  },
  {
    header: 'Telefon',
    field: 'contactPhone', // TODO: customRender for concat / multi string field
    sortable: false,
  },
  // {
  //   header: 'E-Mail (Rechnung)',
  //   field: 'invoiceEmail',
  //   sortable: false,
  // },
  // {
  //   header: 'E-Mail (Lieferschein)',
  //   field: 'deliverySlipEmail',
  //   sortable: false,
  // },
  // {
  //   header: 'Notizen',
  //   field: 'notes',
  //   sortable: false,
  // },
]);
