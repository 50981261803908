import { Selector } from '@ngxs/store';
import { UiState, NewEntityDialogUiState } from '../state/ui.state';
import { RouteState } from "../state/route.state";
import { PerfoEntityType } from "@prf/shared/domain";

export interface HeaderViewModel {
  newEntityDialog: NewEntityDialogUiState;
  isSidebarOpen: boolean;
  currentEntityType: PerfoEntityType | null;
}

export class HeaderViewModelQueries {
  @Selector([UiState.newEntityDialogState, UiState.isSidebarOpen, RouteState.currentEntityType])
  static getViewModel(newEntityDialog: NewEntityDialogUiState, isSidebarOpen: boolean, currentEntityType: PerfoEntityType | null): HeaderViewModel {
    return {
      newEntityDialog,
      isSidebarOpen,
      currentEntityType
    };
  }
}
