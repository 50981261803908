<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="flex flex-row">
    <div class="col" style="min-width: 60vw;">


      <!-- TODO: refactor to pass only one object-->

<!--      TODO: enable all weeks, or at least some more week buffers/paddings around current KW-->

      <p-button styleClass="p-button-secondary ml-4"
                label="Nicht belieferte Märkte"
                icon="pi pi-ban"
                (click)="isMarketsNotServedDialogVisible = true"
      />

      <prf-calendar-week-paginator [calendarWeekOptions]="viewModel.calendarWeeks.options"
                                   [selectedCalendarWeekNumber]="viewModel.calendarWeeks.selectedWeekNumber"
                                   [deliveriesCountForWeek]="viewModel.deliveriesForKW.length"
      />

      <!--      <pre>PLANUNG starten. Zielwoche auswählen. Ausgangswoche auswählen (default -2)</pre>-->

      <div class="flex planning-view-actions-wp">
        <p-selectButton [options]="dragGroupingTypeOptions"
                        [allowEmpty]="false"
                        [ngModel]="viewModel.dragGroupingType"
                        (ngModelChange)="onDragGroupingTypeChange($event)"
                        optionLabel="label"
                        optionValue="value"></p-selectButton>

        <!--        TODO: on close dialog -> reset new week planning state, ie activeStepIndex -->

        <!--        TODO: check PORTAL positioning into main header... or make main header somehow dynamic, slottable or similar -->
        <p-button styleClass="p-button-secondary ml-4"
                  label="Neue Woche planen"
                  icon="pi pi-calendar-plus"
                  (click)="onClickPlanNewWeek()"
        />
      </div>

      <prf-delivery-planning-draggable-view [deliveries]="viewModel.deliveriesForKW"
                                            [groupingType]="viewModel.dragGroupingType"
                                            [calendarWeekInfo]="viewModel.calendarWeeks.selectedCalendarWeekInfo!"
                                            [selectedDetailsDelivery]="viewModel.selectedDetailsDelivery"
                                            (selectEntityForEdit)="onClickEditEntity($event)"
      />
    </div>
    <div *ngIf="viewModel.selectedDetailsDelivery"
         prfToggleEntityDetailsExpand
         class="col col-data-details">
      <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 20px)' }">
        <p-tabView [activeIndex]="0">
          <p-tabPanel header="Details">
            <div class="card">
              <!--      TODO -->
              <!--      delivery planning / product amounts hier anzeigen, ananlog zur lieferungsübersicht (mehrere tabs) -->

              <prf-delivery-details [entity]="viewModel.selectedDetailsDelivery"
                                    [showClose]="true"
                                    (clickClose)="onClickCloseDeliveryDetails()"
                                    (entityChange)="handleUpdateEntity($event)" />
              <!--                                    [updateInProgress]="updateInProgress"-->
            </div>
          </p-tabPanel>
        </p-tabView>
      </p-scrollPanel>
    </div>
  </div>

  <!-- Note: ngIf here triggers onDestroy to reset activeIndex -->
  <p-dialog header="Neue Woche planen"
            *ngIf="viewModel.deliveryPlanningNewWeekDialogVisible"
            [visible]="true"
            [closeOnEscape]="false"
            (visibleChange)="onPlanNewWeekDialogVisibleChange($event)"
            [style]="{width: '95vw', height: '95vh'}">
    <prf-plan-new-delivery-week-dialog />
  </p-dialog>

<!--            *ngIf="viewModel.deliveryPlanningNewWeekDialogVisible"-->
  <p-dialog header="Nicht belieferte Märkte"
            *ngIf="isMarketsNotServedDialogVisible"
            [visible]="true"
            (visibleChange)="onNotServedMarketsDialogVisibleChange($event)"
            [style]="{width: '55vw', height: '75vh'}">

    <prf-markets-not-served-dialog [servedDeliveries]="viewModel.deliveriesForKW" [calendarWeekNumber]="viewModel.calendarWeeks.selectedWeekNumber" />
  </p-dialog>
</ng-container>
