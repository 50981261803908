import {
  ChangeDetectionStrategy,
  Component,
  inject,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { RouterLink } from '@angular/router';
import { UiState } from '../../../../state/ui.state';
import { Observable } from 'rxjs';
import { Select, Store } from '@ngxs/store';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { LogoutUser, ToggleSidebar } from '../../../../state/ui.actions';

enum HostingStage {
  PROD = "PROD",
  DEVELOPMENT = "DEVELOPMENT",
}

@Component({
  selector: 'prf-layout-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    SidebarModule,
    RouterLink,
    AvatarModule,
    MenuModule,
    ButtonModule,
  ],
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  isSidebarVisible = true;

  // TODO: Check EsLint warning regarding no initialization of field.
  @Select(UiState.isSidebarOpen) isSidebarOpen$!: Observable<boolean>;
  // TODO: use viewmodel$ here ... a la NGXS-DINER

  @ViewChild('sidebar') sidebar!: Sidebar;

  userMenuItems: MenuItem[];

  private store = inject(Store);

  protected hostingStage!: HostingStage;

  constructor() {
    this.userMenuItems = [
      // {
      //   label: 'Profil',
      //   icon: 'pi pi-fw pi-cog',
      //   command: () => {
      //     this.goToProfile();
      //   },
      // },
      {
        label: 'Abmelden',
        icon: 'pi pi-fw pi-power-off',
        command: () => {
          this.logout();
        },
      },
    ];

    this.updateHostingStage();
  }

  private updateHostingStage(): void {
    if (window.location.hostname === 'perfo.paldo.de') {
      this.hostingStage = HostingStage.PROD;
    } else {
      this.hostingStage = HostingStage.DEVELOPMENT;
    }
  }

  goToProfile() {}

  logout() {
    this.store.dispatch(new LogoutUser());
  }

  onClickSidebarToggle(): void {
    this.store.dispatch(new ToggleSidebar());
  }
}
