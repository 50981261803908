import { createPerfoTableConfig } from '../../shared/components/data-table/data-table.component';
import { IUser } from '@prf/shared/domain';

export const UsersTableConfig = createPerfoTableConfig<IUser>([
  {
    header: 'Benutzername',
    field: 'username',
    sortable: true,
  },
  {
    header: 'Vorname',
    field: 'firstName',
    sortable: true,
  },
  {
    header: 'Nachname',
    field: 'lastName',
    sortable: true,
  },
  {
    header: 'Rolle',
    field: 'role',
    sortable: true,
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
]);
