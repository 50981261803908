import { inject, Injectable } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IDelivery, IInvoice } from '@prf/shared/domain';
import { Observable } from 'rxjs';
import * as dayjs from 'dayjs';
import { EntityLookupService } from '../../entity-lookup/entity-lookup.service';
import {
  SendEmailCloseResponse,
  SendEmailDialogComponent,
  SendEmailDialogData,
} from '../../../components/email/send-email-dialog/send-email-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class SendEmailDialogService {
  private dynamicDialogRef: DynamicDialogRef | undefined;

  readonly DEFAULT_DIALOG_CONFIG: Partial<DynamicDialogConfig> = {
    // width: '95vw',
    // height: '95vh',
    // contentStyle: { overflow: 'auto' },
    // baseZIndex: 10000,
    draggable: true,
  };

  private dialogService = inject(DialogService);
  private entityLookupService = inject(EntityLookupService);

  public openSendDeliverySlipEmailDialog(
    delivery: IDelivery,
  ): Observable<SendEmailCloseResponse | undefined> {
    const formattedDeliveryDate = dayjs(delivery.deliveryDate).format('DD.MM.YYYY');
    const formattedDeliverySlipNumber = this.getSubjectFormattedReceiptNumber(delivery.deliverySlipNumber!); // TODO: check null assertion
    const market = this.entityLookupService.getMarketById(delivery.marketId!);

    const dialogData: SendEmailDialogData = {
      type: 'DELIVERY_SLIP',
      recipientEmail: market?.deliverySlipEmail ?? null,
      subject: `Lieferschein ${formattedDeliverySlipNumber} vom ${formattedDeliveryDate} - PALDO GmbH`,
    };

    this.dynamicDialogRef = this.dialogService.open(SendEmailDialogComponent, {
      data: dialogData,
      header: 'Lieferschein versenden',
      ...this.DEFAULT_DIALOG_CONFIG,
    });

    return this.dynamicDialogRef.onClose;
  }

  public openSendInvoiceEmailDialog(
    invoice: IInvoice,
  ): Observable<SendEmailCloseResponse | undefined> {
    const formattedInvoiceDate = dayjs(invoice.invoiceDate).format('DD.MM.YYYY');
    const formattedInvoiceNumber = this.getSubjectFormattedReceiptNumber(invoice.invoiceNumber);
    const market = this.entityLookupService.getMarketById(invoice.marketId!);

    // Determine recipientEmail: market or retailer - via isDirectInvoice
    let recipientEmail = market?.invoiceEmail;
    if (market && !market.isDirectInvoice) {
      const retailer = this.entityLookupService.getRetailerById(market.retailerId!);
      recipientEmail = retailer?.invoiceEmail;
    }

    const dialogData: SendEmailDialogData = {
      type: 'INVOICE',
      recipientEmail: recipientEmail ?? null,
      subject: `Rechnung ${formattedInvoiceNumber} vom ${formattedInvoiceDate} - PALDO GmbH`,
    };

    this.dynamicDialogRef = this.dialogService.open(SendEmailDialogComponent, {
      data: dialogData,
      header: 'Rechnung versenden',
      ...this.DEFAULT_DIALOG_CONFIG,
    });

    return this.dynamicDialogRef.onClose;
  }

  private getSubjectFormattedReceiptNumber(receiptNumber: string): string {
    return receiptNumber.replaceAll(' ', '');
  }

}
