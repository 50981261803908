import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IRetailer } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { RetailersTableConfig } from './table.config';
import { Observable, take } from 'rxjs';
import {
  RetailersPageViewModel,
  RetailersPageViewModelQueries,
} from '../../view-models/retailer-view-model.queries';
import { RetailerDetailsComponent } from '../../shared/components/retailer/retailer-details/retailer-details.component';
import { RetailerDetailsDialogComponent } from '../../shared/components/retailer/retailer-details-dialog/retailer-details-dialog.component';
import { UpdateRetailer } from '../../state/entities/retailers.actions';
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";

@Component({
  selector: 'prf-markets-view',
  standalone: true,
  imports: [
    // TODO: Extract shared module... view module
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    RetailerDetailsComponent,
    RetailerDetailsDialogComponent,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './retailers-view.component.html',
  styleUrls: ['./retailers-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailersViewComponent {
  public TableConfig = RetailersTableConfig;
  selectedEntity: IRetailer | null = null;

  @Select(RetailersPageViewModelQueries.getViewModel)
  viewModel$!: Observable<RetailersPageViewModel>;

  loading = false;
  updateInProgress = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    this.selectedEntity = $event as IRetailer;
  }

  handleUpdateEntity(entity: IRetailer) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateRetailer({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }

  onClickCloseRetailerDetails() {
    this.selectedEntity = null;
  }
}
