import { Selector } from "@ngxs/store";
import { DataTableDelivery, IDelivery, IMarket, IProduct, IUser } from "@prf/shared/domain";
import { DeliveriesState } from "../state/entities/deliveries.state";
import { ProductsState } from "../state/entities/products.state";
import { DeliveriesUiState, UiState } from "../state/ui.state";
import * as dayjs from "dayjs";
import {
  formatDeliveryDate,
  formatDriverName,
  formatMarketDeliveryAddress,
  formatMarketName
} from "../shared/formatters/entity.formatter";
import { UsersState } from "../state/entities/users.state";
import { MarketsState } from "../state/entities/markets.state";

export interface DeliveriesPageViewModel {
  deliveries: IDelivery[];
  products: IProduct[];
  tableRangeDates: Date[] | undefined;
  selectedDetailsDeliveryState: {
    delivery: IDelivery | null;
    isUpdating: boolean;
  };
}

export class DeliveriesPageViewModelQueries {
  @Selector([DeliveriesState.items, ProductsState.items, UsersState.drivers, MarketsState.items, UiState.deliveriesState])
  static getViewModel(
    deliveries: DataTableDelivery[],
    products: IProduct[],
    drivers: IUser[],
    markets: IMarket[],
    deliveriesUiState: DeliveriesUiState
  ): DeliveriesPageViewModel {
    return {
      deliveries: DeliveriesPageViewModelQueries.getViewDeliveries(
        deliveries,
        drivers,
        markets,
        deliveriesUiState.tableRangeDates
      ),
      products: [...products],
      tableRangeDates: deliveriesUiState.tableRangeDates,
      selectedDetailsDeliveryState: {
        delivery:
          deliveries.find(
            (d: IDelivery) => d.id === deliveriesUiState.selectedDetailsDeliveryState.deliveryId
          ) || null,
        isUpdating: deliveriesUiState.selectedDetailsDeliveryState.isUpdating
      }
    };
  }

  private static getFilteredDeliveries(
    deliveries: IDelivery[],
    tableRangeDates: Date[] | undefined
  ): IDelivery[] {
    if (!tableRangeDates || tableRangeDates.length !== 2) {
      return deliveries;
    }

    const [fromDate, toDate] = tableRangeDates.map((date) => dayjs(date));
    return deliveries.filter((delivery) =>
      dayjs(delivery.deliveryDate).isBetween(fromDate, toDate, "day", "[]")
    );
  }

  private static getViewDeliveries(
    deliveries: IDelivery[],
    drivers: IUser[],
    markets: IMarket[],
    tableRangeDates: Date[] | undefined
  ): DataTableDelivery[] {
    const filteredDeliveries = DeliveriesPageViewModelQueries.getFilteredDeliveries(
      deliveries,
      tableRangeDates
    );

    return filteredDeliveries.map((delivery) => ({
      ...delivery,
      deliveryDateLabel: formatDeliveryDate(delivery.deliveryDate),
      driverLabel: delivery.driverId ? formatDriverName(delivery.driverId, drivers) : "- Ohne -",
      marketNameLabel: delivery.marketId ? formatMarketName(delivery.marketId, markets) : "- Ohne -",
      marketDeliveryAddressLabel: delivery.marketId ? formatMarketDeliveryAddress(delivery.marketId, markets) : "- Ohne -",
    }));
  }
}
