import { Selector } from '@ngxs/store';
import {
  IIngredient,
  IMarket,
  IProduct,
  IRetailer,
  IUser,
  PerfoEntityType,
} from '@prf/shared/domain';
import { IngredientsState } from '../../../state/entities/ingredients.state';
import { ProductsState } from '../../../state/entities/products.state';
import { MarketsState } from '../../../state/entities/markets.state';
import { RetailersState } from '../../../state/entities/retailers.state';
import { UsersState } from '../../../state/entities/users.state';

export class EntityTypeDropdownViewModelQueries {
  @Selector([
    IngredientsState.items,
    ProductsState.items,
    RetailersState.items,
    MarketsState.items,
    UsersState.items,
  ])
  static getOptionsForType(
    ingredients: IIngredient[],
    products: IProduct[],
    retailers: IRetailer[],
    markets: IMarket[],
    users: IUser[],
  ) {
    return (entityType: PerfoEntityType) => {
      // TODO: Refactor: Use ENTITY_LABEL_MAPPING and do direct access to value: id, and label -> via map
      // TODO: value -> id: number - as default
      switch (entityType) {
        case 'product':
          return products.map((product) => ({
            value: product.id,
            label: `${product.productNo} - ${product.description}`,
          }));
        case 'ingredient':
          return ingredients.map((ingredient) => ({
            value: ingredient.id,
            label: `${ingredient.name}`,
          }));
        case 'retailer':
          return retailers.map((retailer) => ({
            value: retailer.id,
            label: retailer.retailerName,
          }));
        case 'market':
          return markets.map((market) => ({
            value: market.id,
            label: `${market.marketName}, ${market.deliveryAddress.street} ${market.deliveryAddress.houseNumber}, ${market.deliveryAddress.postalCode} ${market.deliveryAddress.city}`,
          }));
        case 'user': {
          return [
            {
              value: null,
              label: '- Ohne Zuweisung -',
              role: null,
            },
            ...users.map((user) => ({
              value: user.id,
              role: user.role,
              label: `${user.firstName} ${user.lastName} (${user.role})`,
            })),
          ];
        }

        default:
          return [];
      }
    };
  }
}
