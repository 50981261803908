import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { AddressFieldsFragmentDoc } from './shared-fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type RetailerFieldsFragment = { __typename?: 'RetailerEntity', id: number, invoiceEmail: string, phone: string, gln: string, retailerName: string, retailerNumber: number, createdAt?: any | null, updatedAt?: any | null, billingAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } };

export type GetAllRetailersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllRetailersQuery = { __typename?: 'Query', retailers: Array<{ __typename?: 'RetailerEntity', id: number, invoiceEmail: string, phone: string, gln: string, retailerName: string, retailerNumber: number, createdAt?: any | null, updatedAt?: any | null, billingAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } }> };

export type CreateRetailerMutationVariables = Types.Exact<{
  input: Types.CreateRetailerInput;
}>;


export type CreateRetailerMutation = { __typename?: 'Mutation', createRetailer: { __typename?: 'RetailerEntity', id: number, invoiceEmail: string, phone: string, gln: string, retailerName: string, retailerNumber: number, createdAt?: any | null, updatedAt?: any | null, billingAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } } };

export type UpdateRetailerMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateRetailerInput;
}>;


export type UpdateRetailerMutation = { __typename?: 'Mutation', updateRetailer: { __typename?: 'RetailerEntity', id: number, invoiceEmail: string, phone: string, gln: string, retailerName: string, retailerNumber: number, createdAt?: any | null, updatedAt?: any | null, billingAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } } };

export const RetailerFieldsFragmentDoc = gql`
    fragment RetailerFields on RetailerEntity {
  id
  billingAddress {
    ...AddressFields
  }
  invoiceEmail
  phone
  gln
  retailerName
  retailerNumber
  createdAt
  updatedAt
}
    ${AddressFieldsFragmentDoc}`;
export const GetAllRetailersDocument = gql`
    query GetAllRetailers {
  retailers {
    ...RetailerFields
  }
}
    ${RetailerFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllRetailersService extends Apollo.Query<GetAllRetailersQuery, GetAllRetailersQueryVariables> {
    override document = GetAllRetailersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRetailerDocument = gql`
    mutation CreateRetailer($input: CreateRetailerInput!) {
  createRetailer(createRetailerInput: $input) {
    ...RetailerFields
  }
}
    ${RetailerFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRetailerService extends Apollo.Mutation<CreateRetailerMutation, CreateRetailerMutationVariables> {
    override document = CreateRetailerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRetailerDocument = gql`
    mutation UpdateRetailer($id: Int!, $input: UpdateRetailerInput!) {
  updateRetailer(id: $id, updateRetailerInput: $input) {
    ...RetailerFields
  }
}
    ${RetailerFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRetailerService extends Apollo.Mutation<UpdateRetailerMutation, UpdateRetailerMutationVariables> {
    override document = UpdateRetailerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }