import { Selector } from '@ngxs/store';
import { IRetailer } from '@prf/shared/domain';
import { RetailersState } from '../state/entities/retailers.state';

export interface RetailersPageViewModel {
  retailers: IRetailer[];
}

export class RetailersPageViewModelQueries {
  @Selector([RetailersState.items])
  static getViewModel(retailers: IRetailer[]): RetailersPageViewModel {
    return {
      retailers: [...retailers]
    };
  }
}
