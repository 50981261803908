import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IRetailer } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from "primeng/dropdown";
import { AddressInputComponent } from "../../form/address-input/address-input.component";

@Component({
  selector: 'prf-retailer-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    AddressInputComponent,
  ],
  templateUrl: './retailer-details.component.html',
  styleUrls: ['./retailer-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: IProduct;
  private _entity!: IRetailer;

  @Input() set entity(entity: IRetailer) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): IRetailer {
    return this._entity;
  }

  @Output()
  createEntity = new EventEmitter<IRetailer>();

  @Output()
  updateEntity = new EventEmitter<IRetailer>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    if (this.isCreate) {
      this.createEntity.emit(this.entity);
    } else {
      this.updateEntity.emit(this.entity);
    }
  }
}
