import { PerfoEntityType } from "@prf/shared/domain";

export class UpdateNavigationInfo {
  static readonly type = '[Route] Navigate';
  constructor(public routePath: string) {}
}

export class GoToEntityView {
  static readonly type = '[Route] Go To Entity View';
  constructor(public entityType: PerfoEntityType) {}
}
