import { IIngredient } from '@prf/shared/domain';

export class LoadIngredients {
  static type = '[Ingredients] LoadIngredients';

  constructor() {}
}

export class UpdateIngredient {
  static type = '[Ingredients] Update Ingredient';
  constructor(public payload: { entity: IIngredient }) {}
}

export class CreateIngredient {
  static type = '[Ingredients] Create Ingredient';
  constructor(public payload: { entity: IIngredient }) {}
}
