import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[prfToggleEntityDetailsExpand]',
  standalone: true,
})
export class ToggleEntityDetailsExpandDirective implements OnInit {
  private readonly ICON_NORMAL = 'pi-window-maximize';
  private readonly ICON_EXPANDED = 'pi-window-minimize';

  private isExpanded: boolean = false;
  private initialWidth!: string;
  private iconSpanEl!: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    // Capture the initial width of the host element
    this.initialWidth = this.el.nativeElement.offsetWidth + 'px';

    // Create button element
    const button = this.renderer.createElement('button');
    this.renderer.addClass(button, 'p-element');
    this.renderer.addClass(button, 'p-button-rounded');
    this.renderer.addClass(button, 'p-button-text');
    this.renderer.addClass(button, 'p-button');
    this.renderer.addClass(button, 'p-button-icon-only');
    this.renderer.setAttribute(button, 'pButton', '');
    this.renderer.setAttribute(button, 'icon', this.ICON_NORMAL);

    // Set the position of the button to absolute and position it
    this.renderer.setStyle(button, 'position', 'absolute');
    this.renderer.setStyle(button, 'top', '15px');
    this.renderer.setStyle(button, 'right', '17px');

    // Create icon element and append it to the button
    this.iconSpanEl = this.renderer.createElement('span') as HTMLElement;
    this.renderer.addClass(this.iconSpanEl, 'p-button-icon');
    this.renderer.addClass(this.iconSpanEl, 'pi');
    this.updateIcon();
    this.renderer.setAttribute(this.iconSpanEl, 'aria-hidden', 'true');
    this.renderer.appendChild(button, this.iconSpanEl);

    // Append button to the host element and listen for clicks
    this.renderer.appendChild(this.el.nativeElement, button);
    this.renderer.listen(button, 'click', () => this.toggleWidth());
  }

  private updateIcon() {
    // Clear existing icon classes
    this.renderer.removeClass(this.iconSpanEl, this.ICON_NORMAL);
    this.renderer.removeClass(this.iconSpanEl, this.ICON_EXPANDED);

    // Add the appropriate class based on the expanded state
    const iconClass = this.isExpanded ? this.ICON_EXPANDED : this.ICON_NORMAL;
    this.renderer.addClass(this.iconSpanEl, iconClass);
    // Mirror icon vertically.
    this.renderer.setStyle(this.iconSpanEl, 'transform', 'scaleX(-1)');
  }

  private toggleWidth() {
    this.isExpanded = !this.isExpanded;
    const width = this.isExpanded ? 'calc(100vw - 310px)' : this.initialWidth;
    this.renderer.setStyle(this.el.nativeElement, 'width', width);
    this.updateIcon();
  }
}
