import { Route } from '@angular/router';
import { PerfoEntityType } from '@prf/shared/domain';

// TODO: Refactor: Move/Extract.
const APP_NAME = 'Perfo';
const ROUTE_TITLE_PREFIX = `${APP_NAME} |`;

export function getRouteTitle(routeName: string | undefined) {
  if (!routeName) {
    return 'MISSING ROUTE NAME';
  } else {
    return `${ROUTE_TITLE_PREFIX} ${routeName}`;
  }
}

export enum RouteKey {
  DASHBOARD = 'dashboard',
  USERS = 'users',
  PRODUCTS = 'products',
  MARKETS = 'markets',
  RETAILERS = 'retailers',
  INGREDIENTS = 'ingredients',
  DELIVERIES = 'deliveries',
  DELIVERY_PLANNING = 'delivery-planning',
  INVOICES = 'invoices',
  VEHICLES = 'vehicles',
}

interface RouteDetails {
  path: string;
  label: string;
  entityType: PerfoEntityType | null;
}

export type RouteConfigMap = {
  [key in RouteKey]?: RouteDetails;
};

export const CLIENT_ROUTE_CONFIG: RouteConfigMap = {
  [RouteKey.DASHBOARD]: {
    path: '',
    label: 'Dashboard',
    entityType: null,
  },
  [RouteKey.USERS]: {
    path: 'users',
    label: 'Benutzer',
    entityType: 'user',
  },
  [RouteKey.PRODUCTS]: {
    path: 'products',
    label: 'Artikel',
    entityType: 'product',
  },
  [RouteKey.INGREDIENTS]: {
    path: 'ingredients',
    label: 'Rohware',
    entityType: 'ingredient',
  },
  [RouteKey.MARKETS]: {
    path: 'markets',
    label: 'Märkte',
    entityType: 'market',
  },
  [RouteKey.RETAILERS]: {
    path: 'retailers',
    label: 'Händler',
    entityType: 'retailer',
  },
  [RouteKey.DELIVERIES]: {
    path: 'deliveries',
    label: 'Lieferungen - Übersicht',
    entityType: 'delivery',
  },
  [RouteKey.DELIVERY_PLANNING]: {
    path: 'delivery-planning',
    label: 'Lieferungen - Wochenplanung',
    entityType: 'delivery',
  },
  [RouteKey.INVOICES]: {
    path: 'invoices',
    label: 'Rechnungen',
    entityType: 'invoice',
  },
  [RouteKey.VEHICLES]: {
    path: 'vehicles',
    label: 'Fahrzeuge',
    entityType: 'vehicle',
  },
};

// TODO: Refactor: Sort by position in nav.

export const MAIN_ROUTES: Route[] = [
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.DASHBOARD]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.DASHBOARD]?.label),
    loadComponent: () =>
      import('./dashboard/dashboard-view.component').then(
        (m) => m.DashboardViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.USERS]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.USERS]?.label),
    loadComponent: () =>
      import('./users/users-view.component').then((m) => m.UsersViewComponent),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.PRODUCTS]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.PRODUCTS]?.label),
    loadComponent: () =>
      import('./products/products-view.component').then(
        (m) => m.ProductsViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.MARKETS]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.MARKETS]?.label),
    loadComponent: () =>
      import('./markets/markets-view.component').then(
        (m) => m.MarketsViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.RETAILERS]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.RETAILERS]?.label),
    loadComponent: () =>
      import('./retailers/retailers-view.component').then(
        (m) => m.RetailersViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.DELIVERIES]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.DELIVERIES]?.label),
    loadComponent: () =>
      import('./deliveries/deliveries-view.component').then(
        (m) => m.DeliveriesViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.DELIVERY_PLANNING]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.DELIVERY_PLANNING]?.label),
    loadComponent: () =>
      import('./delivery-planning/delivery-planning-view.component').then(
        (m) => m.DeliveryPlanningViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.INGREDIENTS]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.INGREDIENTS]?.label),
    loadComponent: () =>
      import('./ingredients/ingredients-view.component').then(
        (m) => m.IngredientsViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.INVOICES]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.INVOICES]?.label),
    loadComponent: () =>
      import('./invoices/invoices-view.component').then(
        (m) => m.InvoicesViewComponent
      ),
  },
  {
    path: CLIENT_ROUTE_CONFIG[RouteKey.VEHICLES]?.path,
    title: getRouteTitle(CLIENT_ROUTE_CONFIG[RouteKey.VEHICLES]?.label),
    loadComponent: () =>
      import('./vehicles/vehicles-view.component').then(
        (m) => m.VehiclesViewComponent
      ),
  },
];
