import { IUser } from '@prf/shared/domain';

export class LoadUsers {
  static type = '[Users] Load Users';

  constructor() {}
}

export class UpdateUser {
  static type = '[Users] Update User';
  constructor(public payload: { entity: IUser }) {}
}

export class CreateUser {
  static type = '[Users] Create New User';
  constructor(public payload: { entity: IUser }) {}
}
