<div class="delivery-item-wp"
     [ngClass]="{'new-delivery': isNewDelivery()}"
     (dblclick)="$event.stopPropagation()"
>
  <!--  Note: (dblclick) handler makes sure that p-picklist dblclick (-> move item) handler will not be called upon dbclick -->

  <div class="new-tag">NEU</div>

  <div class="mr-2">{{ delivery.deliveryDate | prfDate }}</div>

  <div *ngIf="delivery.marketId">
    <div class="mr-2">{{ delivery.marketId | market:'marketName' }}</div>
    <div>{{ delivery.marketId | market:'deliveryAddress' }}</div>
  </div>
  <div *ngIf="!delivery.marketId">- Ohne Markt -</div>

  <div class="mt-1">
    <div *ngIf="delivery.driverId">
    <span>
      <i class="pi pi-user"></i>
      {{ delivery.driverId | user:'username' }}
    </span>
    </div>
    <div *ngIf="!delivery.driverId">
      <span>- Ohne Fahrer -</span>
    </div>
  </div>
  <div class="mt-2"
       style="max-width: 400px;"
       *ngIf="isScheduledTargetDeliveryMovedToSourceWeek()">
         <span class="mr-3 text-red-500 white-space-normal">
          <i class="pi pi-exclamation-circle mr-1"></i>
          Bereits vorgeplante Zielwochen-Lieferung Woche wurde nach Ausgangswoche verschoben!
         </span>
  </div>

</div>
<button *ngIf="showDetailsButton"
        class="action-button p-button"
        (click)="onClickDetails($event)">
  Details
</button>
