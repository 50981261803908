import { inject, Injectable } from "@angular/core";
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";

// TODO: Check service for removal.

@Injectable({
  providedIn: 'root',
})
export class PlanNewDeliveryWeekDialogService {
  private dynamicDialogRef: DynamicDialogRef | undefined;

  readonly DEFAULT_DIALOG_CONFIG: Partial<DynamicDialogConfig> = {
    width: '95vw',
    height: '95vh',
    contentStyle: { overflow: 'auto' },
    baseZIndex: 10000,
    draggable: true,
  };

  private dialogService = inject(DialogService);

  public openPlanNewWeekDialog(): void {
    // this.dynamicDialogRef = this.dialogService.open(
    //   PlanNewDeliveryWeekDialogComponent,
    //   {
    //     data: {
    //       // entity: entityInstance,
    //     },
    //     header: 'Neue Woche planen',
    //     ...this.DEFAULT_DIALOG_CONFIG,
    //   },
    // );
    //
    // this.dynamicDialogRef.onClose.subscribe((_data: any) => {});
  }
}
