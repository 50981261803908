import { Pipe, PipeTransform } from '@angular/core';
import { EntityLookupService } from '../services/entity-lookup/entity-lookup.service';
import { EntityInterfaceMap, IMarket, IUser, PerfoEntityType } from "@prf/shared/domain";

@Pipe({
  name: 'entity',
  standalone: true
})
export class EntityPipe<T extends PerfoEntityType> implements PipeTransform {

  constructor(protected entityLookupService: EntityLookupService) {}

  transform(entityId: number, property: keyof EntityInterfaceMap[T], entityType: T): unknown {
    let result = null;
    if (entityType === 'market') {
      const market = this.entityLookupService.getMarketById(entityId);
      result = market ? market[property as keyof IMarket] : null;
    } else if (entityType === 'user') {
      const user = this.entityLookupService.getUserById(entityId);
      result = user ? user[property as keyof IUser] : null;
    }
    return result;
  }
}

@Pipe({
  name: 'market',
  standalone: true,
  pure: true, // TODO: Check if component input update (with OnPush) leads to updated pipe transform value. Ie changed marketId in details -> update in mainView.
})
export class MarketPipe extends EntityPipe<'market'> implements PipeTransform {
  constructor(protected override entityLookupService: EntityLookupService) {
    super(entityLookupService);
  }

  override transform(entityId: number|null|undefined, property: keyof IMarket): any {
    if (entityId === null || entityId === undefined) {
      return "";
    }

    if (property === 'deliveryAddress') {
      const market = this.entityLookupService.getMarketById(entityId);
      const deliveryAddress = market ? market['deliveryAddress'] : null;
      if (deliveryAddress) {
        return `${deliveryAddress.street} ${deliveryAddress.houseNumber}, ${deliveryAddress.postalCode} ${deliveryAddress.city}`;
      }
    } else {
      return super.transform(entityId, property, 'market');
    }
  }
}

@Pipe({
  name: 'user',
  standalone: true,
  pure: true,
})
export class UserPipe extends EntityPipe<'user'> implements PipeTransform {
  constructor(protected override entityLookupService: EntityLookupService) {
    super(entityLookupService);
  }

  override transform(entityId: number|null, property: keyof IUser): unknown {
    if (entityId !== null) {
      return super.transform(entityId, property, 'user');
    } else {
      return 'Unbekannter Nutzer';
    }
  }
}
