import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ToastService } from '../../shared/services/toast/toast.service';
import { IInvoice, InvoiceStatus } from '@prf/shared/domain';
import { LoadInvoices, SendInvoiceEmail } from './invoices.actions';
import {
  GetAllInvoicesService,
  InvoiceFieldsFragment,
  SendInvoiceEmailMutationVariables,
  SendInvoiceEmailService,
} from '../../graphql/invoices-operations.generated';
import { catchError, map, Observable, tap, throwError } from 'rxjs';

type LocalModel = IInvoice;
type LocalStateModel = InvoicesStateModel;
type LocalStateContext = StateContext<InvoicesStateModel>;

export interface InvoicesStateModel {
  items: LocalModel[];
}

@State<InvoicesStateModel>({
  name: 'invoices',
  defaults: {
    items: [],
  },
})
@Injectable()
export class InvoicesState {
  constructor(
    private toastService: ToastService,
    private getAllInvoicesService: GetAllInvoicesService,
    private sendInvoiceEmailService: SendInvoiceEmailService,
  ) {}

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  // TODO: Refactor: Check whether this InvoicesState should extend from BaseEntityState, where this abstract Load action gets auto dispatched.

  // Currently onInit load is not used, because invoices are loaded afterViewInit of invoices-view/table.
  // ngxsOnInit(ctx: StateContext<any>): void {
  //   ctx.dispatch(new LoadInvoices());
  // }

  protected mapGqlEntityToUiEntity(entity: InvoiceFieldsFragment): IInvoice {
    return {
      id: entity.id,
      deliveryId: entity.delivery.id,
      deliverySlipNumber: entity.delivery.deliverySlipNumber,
      marketId: entity.delivery.market!.id,
      marketName: entity.delivery.market!.marketName,
      marketDeliveryAddress: entity.delivery.market!.deliveryAddress,
      invoiceNumber: entity.invoiceNumber,
      invoiceDate: new Date(entity.invoiceDate),
      status: InvoiceStatus[entity.status as keyof typeof InvoiceStatus],
      netDiscount: entity.netDiscount,
      invoiceAmount: entity.invoiceAmount,
      invoiceEmailSent: entity.invoiceEmailSent,
    };
  }

  @Action(LoadInvoices)
  protected loadInvoices(ctx: LocalStateContext, action: LoadInvoices): Observable<any> {
    return this.getAllInvoicesService.fetch().pipe(
      tap((queryResult) => {
        const invoices = queryResult?.data.invoices || [];
        const uiInvoices = invoices.map(this.mapGqlEntityToUiEntity);
        // console.table(uiInvoices);
        ctx.patchState({ items: uiInvoices });
      }),
      catchError((err) => {
        console.error('Error loading invoices:', err);
        // Handle the error appropriately here, e.g., show a toast
        return throwError(err);
      }),
    );
  }

  @Action(SendInvoiceEmail)
  sendInvoiceEmail(ctx: LocalStateContext, action: SendInvoiceEmail): Observable<any> {
    console.log('sendInvoiceEmail - action', action);
    // Currently only 1 recipient is allowed.
    if (action.payload.recipientEmails.length !== 1) {
      console.error('Too many mail recipients', action.payload.recipientEmails);
    }

    const variables: SendInvoiceEmailMutationVariables = {
      input: {
        invoiceId: action.payload.invoice.id,
        recipientEmail: action.payload.recipientEmails[0],
        subject: action.payload.subject,
        message: action.payload.message,
      },
    };

    return this.sendInvoiceEmailService.mutate(variables).pipe(
      map((result) => result?.data?.sendInvoiceEmail),
      tap((success) => {
        if (success) {
          console.log(' SEND MAIL SUCCESS - ');
          this.toastService.showEmailSentSuccess();
          // TODO SET INVOICE AS INVOICE-MAIL SENT!
        } else {
          console.log('SEND EMAIL ___ERROR___');
          this.toastService.showEmailSentError();
          // TODO: show error toast.
        }
      }),
    );
  }
}
