import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DeliveryStatus, IDelivery, IDeliveryProduct, IProduct } from '@prf/shared/domain';
import { DeliveryProductsEditorComponent } from '../delivery-products-editor/delivery-products-editor.component';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { Store } from '@ngxs/store';
import {
  OpenCompactDeliverySlipDocument,
  OpenDeliverySlipDocument,
  OpenInvoiceDocument,
  SetDeliveryAsCompleted,
  SetDeliveryManuallyAsDelivered,
} from '../../../../state/ui.actions';
import { DividerModule } from 'primeng/divider';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { take } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { SendEmailDialogComponent } from '../../../../shared/components/email/send-email-dialog/send-email-dialog.component';
import { SendEmailDialogService } from '../../../../shared/services/dialogs/send-email-dialog/send-email-dialog.service';
import { SendDeliverySlipEmail } from '../../../../state/entities/deliveries.actions';
import { images } from 'ionicons/icons';
import { GalleriaModule } from 'primeng/galleria';

@Component({
  selector: 'prf-delivery-delivered-view',
  standalone: true,
  imports: [
    CommonModule,
    DeliveryProductsEditorComponent,
    ButtonModule,
    TooltipModule,
    DividerModule,
    ConfirmPopupModule,
    DialogModule,
    SendEmailDialogComponent,
    GalleriaModule,
    NgOptimizedImage,
  ],
  templateUrl: './delivery-delivered-view.component.html',
  styleUrls: ['./delivery-delivered-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDeliveredViewComponent {
  private store = inject(Store);
  private cdRef = inject(ChangeDetectorRef);
  private confirmationService = inject(ConfirmationService);
  // private dialogService = inject(DialogService);
  // private entityLookupService = inject(EntityLookupService);
  private sendEmailDialogService = inject(SendEmailDialogService);

  // sendDeliverySlipEmailDialogRef: DynamicDialogRef | undefined;

  @Input({
    required: true,
  })
  delivery!: IDelivery;

  @Input({
    required: true,
  })
  products!: IProduct[];

  @Output()
  clickClose = new EventEmitter<void>();

  deliveredImages: any[] = [];

  isSetManuallyAsDeliveredInProgress: boolean = false;
  isSetDeliveryAsCompletedInProgress: boolean = false;
  isSendDeliverySlipEmailInProgress: boolean = false;

  // GALLERY
  displayCustom: boolean = false;
  activeIndex: number = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['delivery']?.currentValue) {
      this.deliveredImages = [];
    }

    if (changes?.['delivery']?.currentValue.photos?.length > 0) {
      const photos = changes['delivery'].currentValue.photos;

      this.deliveredImages = photos.map((photo: any) => ({
        // TODO: take base url from env

        // PROD
        itemImageSrc: `/api/delivery-photos/${photo.id}`,

        // LOCAL DEV
        // itemImageSrc: `http://localhost:3000/api/delivery-photos/${photo.id}`,

        // thumbnailImageSrc: 'https://placehold.co/80x80',
        // alt: 'Description for Image 1',
        // title: 'Title 1',
      }));
    }
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  onClickOpenDeliverySlip(delivery: IDelivery) {
    this.store.dispatch(new OpenDeliverySlipDocument({ delivery: delivery }));
  }

  onClickTransformIntoInvoice(delivery: IDelivery) {
    // this.store.dispatch(new OpenDeliverySlipDocument({delivery: delivery}));
  }

  onClickOpenCompactDeliverySlip(delivery: IDelivery) {
    this.store.dispatch(new OpenCompactDeliverySlipDocument({ delivery: delivery }));
  }

  onClickOpenInvoice(delivery: IDelivery) {
    this.store.dispatch(new OpenInvoiceDocument({ delivery: delivery }));
  }

  onClickCompleteDelivery(event: Event, delivery: IDelivery) {
    console.log('onClickCompleteDelivery - delivery', delivery);
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Lieferung wirklich abschließen?',
      // TODO: Add more specifc infos about subsequent actions/results of COMPLETING this delivery.
      //  - ? ie auto send some mails, if applicable.
      //  - Festschreibung der Daten und des PDF-Dokuments.
      //  - ? Erstellung der Rechnung
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isSetDeliveryAsCompletedInProgress = true;
        this.store
          .dispatch(new SetDeliveryAsCompleted({ delivery: delivery }))
          .pipe(take(1))
          .subscribe(
            (res) => {
              this.isSetDeliveryAsCompletedInProgress = false;
            },
            (error) => {
              this.isSetDeliveryAsCompletedInProgress = false;
            },
          );
      },
      reject: () => {
        // Just close the confirmation popup.
      },
    });
  }

  shouldShowDeliveryEditView(): boolean {
    // TODO: fine tune this check. Or rather check for additional DISABLED check.
    return this.delivery.status !== DeliveryStatus.CREATED;
  }

  shouldShowMarkAsDeliveredButton(): boolean {
    // TODO: check state READY_TO_DELIVER ? set it, as soon as DelivProducts area set?
    // probably just REMOVE it completely, because later there will be DelivProducts.AMOUNT visible in the table.
    return (
      this.delivery.status === DeliveryStatus.CREATED ||
      this.delivery.status === DeliveryStatus.READY_TO_DELIVER
    );
  }

  // Note: This action (setting as DELIVERED) is normally set by the driver, when he finishes the delivery and confirms it, when in the market.
  onClickMarkManuallyAsDelivered(event: Event, delivery: IDelivery): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Lieferung wirklich als AUSGELIEFERT setzen?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isSetManuallyAsDeliveredInProgress = true;
        this.store.dispatch(new SetDeliveryManuallyAsDelivered({ delivery })).subscribe(
          (res) => {
            // TODO: set in progress button for action button
            this.isSetManuallyAsDeliveredInProgress = false;
          },
          (error) => {
            console.error('Error during setting delivery manually as DELIVERED.', error);
            this.isSetManuallyAsDeliveredInProgress = false;
          },
        );
      },
      reject: () => {
        // Just close the confirmation popup.
      },
    });
  }

  shouldShowDeliveryCompletionActions(): boolean {
    return (
      this.delivery.status !== DeliveryStatus.CREATED &&
      this.delivery.status !== DeliveryStatus.READY_TO_DELIVER
    );
  }

  shouldShowCompleteDeliveryAction() {
    return this.delivery.status !== DeliveryStatus.COMPLETED;
  }

  isDeliveredDelivery(): boolean {
    return this.delivery.status === DeliveryStatus.DELIVERED;
  }

  isCompletedDelivery() {
    return this.delivery.status === DeliveryStatus.COMPLETED;
  }

  shouldDisableCompleteDeliveryAction() {
    // TODO: REMOVE once INVOICE is stable.
    // return true;

    if (!this.delivery.deliveryProducts) {
      return true;
    }

    return (
      this.delivery.deliveryProducts.length === 0 ||
      this.delivery.deliveryProducts.some(
        (deliveryProduct: IDeliveryProduct) =>
          deliveryProduct.productId == null ||
          deliveryProduct.actualQuantity == null ||
          deliveryProduct.returnQuantity == null,
      )
    );
  }

  onClickSendDeliverySlipEmail() {
    this.sendEmailDialogService
      .openSendDeliverySlipEmailDialog(this.delivery)
      .pipe(take(1))
      .subscribe((closeResponse) => {
        if (closeResponse) {
          console.log(closeResponse);
          this.isSendDeliverySlipEmailInProgress = true;
          this.cdRef.detectChanges();
          this.store
            .dispatch(
              new SendDeliverySlipEmail({
                delivery: this.delivery,
                recipientEmails: closeResponse.recipientEmails,
                subject: closeResponse.subject,
                message: closeResponse.message,
              }),
            )
            .pipe(take(1))
            .subscribe(
              (res) => {
                this.isSendDeliverySlipEmailInProgress = false;
                // TODO: update delivery view... needs to apply changes of deliveryEmalSent = true in order to show in SEND button
                this.cdRef.detectChanges();
              },
              (error) => {
                this.isSendDeliverySlipEmailInProgress = false;
                this.cdRef.detectChanges();
              },
            );
        }
      });
  }

  shouldDisableSendDeliverySlipEmail(): boolean {
    const deliveryStatus = this.delivery.status;

    return deliveryStatus !== DeliveryStatus.COMPLETED;
  }

  onKeyDownGalleria($event: KeyboardEvent) {
    if ($event.key === 'Escape') {
      this.displayCustom = false;
    }
  }
}
