import { IInvoice } from '@prf/shared/domain';

export class LoadInvoices {
  static type = '[Invoices] Load Invoices';

  constructor() {}
}

export class CreateInvoice {
  static type = '[Invoices] Create Invoice';
  constructor(public payload: { entity: IInvoice }) {}
}

export class UpdateInvoice {
  static type = '[Invoices] Update Invoice';
  constructor(public payload: { entity: IInvoice }) {}
}

export class SendInvoiceEmail {
  static readonly type = '[Invoices] Send Invoice Email';
  constructor(public payload: { invoice: IInvoice, recipientEmails: string[]; subject: string; message: string; }) {}
}
