<prf-delivery-details [entity]="entity"
                      [isCreate]="true"
                      [updateInProgress]="updateInProgress"
                      (entityChange)="handleCreateEntity($event)"
></prf-delivery-details>

<!--<button>Anlegen wird zu WEITER</button>-->
<!--<pre>-->
<!--  - nach WEITER öffnet sich Planlieferungsansicht, innerhalb dieses Modals.-->
<!--  - bestätigung der erfolgreichen Erstellung-->
<!--</pre>-->
