import { createPerfoTableConfig } from '../../shared/components/data-table/data-table.component';
import { IVehicle } from '@prf/shared/domain';

export const VehiclesTableConfig = createPerfoTableConfig<IVehicle>([
  {
    header: 'Kennzeichen',
    field: 'licensePlate',
    sortable: true,
    customRender: {
      monoFont: true,
    },
  },
  {
    header: 'Modell',
    field: 'vehicleModel',
    sortable: true,
  },
]);
