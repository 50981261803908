import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IInvoice } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { InvoicesTableConfig } from './table.config';
import { TabViewModule } from 'primeng/tabview';
import { Observable, take } from 'rxjs';
import { InvoiceDetailsComponent } from '../../shared/components/invoice/invoice-details/invoice-details.component';
import {
  InvoicesPageViewModel,
  InvoicesPageViewModelQueries,
} from '../../view-models/invoices-view-model.queries';
import { LoadInvoices } from '../../state/entities/invoices.actions';
import { CalendarModule } from 'primeng/calendar';
import { SetInvoicesTableRangeDates } from '../../state/ui.actions';

type LocalModel = IInvoice;

@Component({
  selector: 'prf-invoices-view',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    TabViewModule,
    InvoiceDetailsComponent,
    CalendarModule,
  ],
  templateUrl: './invoices-view.component.html',
  styleUrls: ['./invoices-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvoicesViewComponent implements AfterViewInit {
  TableConfig = InvoicesTableConfig;
  selectedEntity: LocalModel | null = null;

  @Select(InvoicesPageViewModelQueries.getViewModel)
  viewModel$!: Observable<InvoicesPageViewModel>;

  loading = false;
  updateInProgress = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.loading = false;

    setTimeout(() => {
      this.loading = true;
      this.store
        .dispatch(new LoadInvoices())
        .pipe(take(1))
        .subscribe(
          (_res) => {
            this.loading = false;
            this.cdRef.markForCheck();
          },
          (error) => {
            this.loading = false;
            this.cdRef.markForCheck();
          },
        );
    }, 150);
  }

  onEntitySelectionChange($event: IEntity | null): void {
    this.selectedEntity = $event as LocalModel;
  }

  handleUpdateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    // this.store
    //   .dispatch(new UpdateDelivery({ entity: entity }))
    //   .pipe(take(1))
    //   .subscribe((result: any) => {
    //     this.updateInProgress = false;
    //     this.cdRef.markForCheck();
    //   });
  }

  onTableRangeDatesSelect($event: Date[] | undefined) {
    this.store.dispatch(new SetInvoicesTableRangeDates({ rangeDates: $event }));
  }
}
