<!--<pre>-->
<!--  {{entity|json}}-->
<!--</pre>-->
<div *ngIf="entity" class="card">

  <button type="button"
          pButton
          icon="pi pi-file-pdf"
          label="Rechnung"
          class="p-button-secondary"
          (click)="onClickShowInvoice()"
  >
  </button>

  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="invoiceNumber">Rechnungsnummer</label>
      <input id="invoiceNumber" type="text" [(ngModel)]="entity.invoiceNumber" pInputText
             [disabled]="true"
      />
    </div>
    <!--<div class="p-field prf-field">
      <label for="invoiceDate">Rechnungsdatum</label>
      <input id="invoiceDate" type="date" [(ngModel)]="entity.invoiceDate" pInputText
             [disabled]="true"
       />
    </div>-->
    <div class="p-field prf-field">
      <label for="status">Status</label>
      <p-dropdown id="status" [(ngModel)]="entity.status"
                  [options]="[
                      {label: 'Erstellt', value: 'CREATED'},
                      {label: 'Versendet', value: 'SENT'},
                      {label: 'Bezahlt', value: 'PAID'},
                      {label: 'Storniert', value: 'CANCELLED'},
                  ]"
                  optionValue="value"
                  [disabled]="true"
                  optionLabel="label">
      </p-dropdown>
    </div>
    <!--<div class="p-field prf-field">
      <label for="netDiscount">Nettorabatt</label>
      <input id="netDiscount" type="number" [(ngModel)]="entity.netDiscount" pInputText

             [disabled]="true"
      />
    </div>-->
    <div class="p-field prf-field">
      <label for="invoiceAmount">Rechnungsbetrag (Netto)</label>
      <input id="invoiceAmount" type="number" [(ngModel)]="entity.invoiceAmount" pInputText
             [disabled]="true"
      />
    </div>
  </div>
</div>
<p-divider></p-divider>

<!--TODO: auf bezahlt ONLY if already sent. otherwise info text-->
<button type="button"
        pButton
        icon="pi pi-check-circle"
        label="Rechnung als BEZAHLT markieren"
        class="p-button-primary"
        [disabled]="true"
>
</button>

<p-divider></p-divider>

<div class="flex justify-content-between">
  <button type="button"
          pButton
          icon="pi pi-envelope"
          label="Rechnung versenden"
          [label]="entity.invoiceEmailSent ? 'Rechnung ERNEUT versenden' : 'Rechnung versenden'"
          class="p-button-secondary mr-2"
          [loading]="isSendInvoiceEmailInProgress"
          [disabled]="isSendInvoiceEmailInProgress"
          (click)="onClickSendInvoiceEmail()"
  >
  </button>

  <!--  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"-->
  <!--            styleClass="p-button-primary"-->
  <!--            (click)="onClickSave()" />-->
</div>
