import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  UsersState,
  UsersStateModel,
} from '../../../state/entities/users.state';
import { IMarket, IRetailer, IUser } from "@prf/shared/domain";
import {
  MarketsState,
  MarketsStateModel,
} from '../../../state/entities/markets.state';
import { RetailersState, RetailersStateModel } from "../../../state/entities/retailers.state";

@Injectable({
  providedIn: 'root',
})
export class EntityLookupService {
  constructor(private store: Store) {}

  getMarketById(marketId: number): IMarket | null {
    const state: MarketsStateModel = this.store.selectSnapshot(MarketsState);
    const market = state.items.find((m) => m.id === marketId);
    return market || null;
  }

  getRetailerById(retailerId: number): IRetailer | null {
    const state: RetailersStateModel = this.store.selectSnapshot(RetailersState);
    const retailer = state.items.find((r) => r.id === retailerId);
    return retailer || null;
  }

  getUserById(userId: number): IUser | null {
    const state: UsersStateModel = this.store.selectSnapshot(UsersState);
    const user = state.items.find((u) => u.id === userId);
    return user || null;
  }

  getFullUserNameById(userId: number): string {
    const user = this.getUserById(userId);
    return user ? `${user.firstName} ${user.lastName}` : '- Ohne -';
  }

}
