<ng-container *ngIf="availableDeliveries">

  <!--  <pre>-->
  <!--    # TODO-->
  <!--    - add filter zeile-->
  <!--    - TODO CHECK/CLARIFY: request fresh, or use already loaded... use already loaded makes everything easier for now &#45;&#45; ggf refresh in the background, upon open - show small refresh indicator-->
  <!--    - NEUE GRUPPE für "nicht belieferte märkte" ie, lieferungen die INAKTIV sind (hauptlieferung)-->
  <!--      - deaktivierte lieferungen werden nicht in folgewoche kopiert! nachlieferung wird nicht beachtet-->
  <!--    - NEUE dropzone: inaktive lieferungen.-->

  <!--    - ABER: nicht belieferte märkte müssen in planungs-modal sein-->

  <!--    - via call pre: wenn markt neu angelegt wird direkt lieferschein erstellt?-->

  <!--    - drag ? inaktiv automatisch aktiv machen, bei NICHT BELIEFERT in planungsmodal zu neuer woche-->

  <!--    - planung inpromptu belieferung, neuer markt zB. geht an produktions/Wochenplanung vorbei-->
  <!--    - was ist mit lieferungen, die nicht in kw0 sind @ planungszeitpunkt, zB in kw-1. aber aktive lieferung-->


  <!--  Fahrer/Nutzer kann bereits auf INAKTIV gestellt werden. Login ist dann nicht möglich.-->
  <!--  Für diese Fahrer werden weiterhin geplante Lieferungen angezeigt, jedoch wird der Fahrer deutlich als INAKTIV markiert, damit man seine Lieferungen anderen Fahrern zuweisen kann-->
  <!--  Für zukünftige Lieferungen steht er nicht mehr zur Lieferungszuweisung zur Verfügung.-->

  <!--  Gründe für INAKTIVITÄT: Krankheit, Urlaub, Aus dem Unternehmen ausgeschieden-->

  <!--  </pre>-->

  <!--  Draggable list: Klick auf Details -> DraggableItem, dessen Details gerade geöffnet sind, hervorheben-->

  <!--  neue WOCHE (KW) mehrfach, fälschlicherweise erstellt... wie nachträglich löschen - via created_At--- eigener button für admins?-->


  <!--  NewWeekDialog: Details rechts einblenden im Dialog (kein 2. Dialog) - analog zu normalen Views -->

  <!--  Details für weekdayGroups-Planning nötig-->
  <!--  Was möglich ist: 2 Hauptlieferungen eines Marktes in einer Woche (verschiedene Werktage)-->
  <!--  Ausgeschlossen jedoch: 2 Hauptlieferungen für selben Markt am selben Werktag | ie 1 Hauptlieferung + 1 Nachlieferung für selben Markt, selben Werktag jedoch erlaubt-->
  <!--  In so einem Konfliktfall, müssen die Lieferungen mit den selben "Daten" hervorgehoben werden. Der Konflikt muss manuell über Details-Bearbeitung einer Lieferung gelöst werden, zB setzen auf Inaktiv, oder Verschieben auf anderen Liefertag.-->
  <!--  Sofern Konflikt vorhanden ist -> WEITER im Dialog nicht möglich. -->

  <!--  Löschen nur für INAKTIVE Lieferungen erlauben. Nur für Admins.-->

  <!-- # Notizen/Nachricht vom Markt oder Fahrer zum Wochen-Planungszeitpunkt anzeigen-->

  <!--  Lieferung druch Fahrer abgeschlossen -> Retourenquoten können bestimmt werden. Pro Position (zB Oliven) und pro Lieferschein. Diese Quoten sind später für A) Statistiken und B) NeueWochenplanung-Liefermengen-Anpassung (Angabe bei Lieferung) -->


  <!--  Markets-View -> Tabelle+Details. Dazwischen flexiblen Slider für Breite left/right. zB mehr Breite für SubTab "Lieferungen"-->

<!--  INFO -->
  <!--  RECHNUNGEN, 1 oder 2 Belege-->
  <!--  Typ 1: soll = 4, ist = 3 , retoure = 1 . rechnungsmenge = (3-1): 2 -->
  <!-- Info: Rechnungsmenge kann auch negativ sein, wenn bspw mehr zurückgenommen wird als geliefert -->
  <!--  negativer rechnungsbetrag führt aktuell nicht zur rechnung -->
  <!--  in dem fall wird "abgebrochen/markiert" -->

  <!--  Typ 2: soll = 4, ist = 3 , retoure = 1 . rechnungsmenge = 3. rechnungskorrekturmenge/gutschrift = 1 -->

  <!--  NICE TO HAVE (später): -->
  <!--  # Rechnungsmenge ("Menge auf Rechnunge") bereits in Lieferschein als eigene (letzte) Spalte angeben, pro Artikelzeile- also 3 Spalten.. -->
  <!--  RECHNUNG HAT NUR 1 Spalte: Menge -->

  <div cdkDropListGroup>
    <ng-container *ngFor="let deliveryGroup of groupedDeliveries">
      <p-panel [style]="{marginBottom: '20px'}"
               [class]="'panel-' + this.groupingType"
               [toggleable]="true"
               >
        <ng-template pTemplate="header">
        <span class="text-primary font-semibold text-lg">
          <i class="pi font-bold" [class]="deliveryGroup.headerIconClass + ' mr-1'"></i>
          {{ deliveryGroup.title }} <span class="delivery-panel-title-count">({{ (deliveryGroup.deliveriesCount) }}
          Lieferungen)</span>
        </span>
        </ng-template>

        <!-- Nested Groups (DriverGroupingStrategy) -->
        <ng-container *ngIf="deliveryGroup.nestedGroups; else flatGroup">
          <ng-container *ngFor="let nestedGroup of deliveryGroup.nestedGroups">
            <p-panel>
              <ng-template pTemplate="header">{{ nestedGroup.title }} ({{ (nestedGroup.deliveriesCount) }} Lieferungen)
              </ng-template>

              <div cdkDropList
                   cdkDropListSortingDisabled
                   [cdkDropListData]="nestedGroup.deliveries ?? []"
                   (cdkDropListDropped)="onDrop($event, nestedGroup)">
                <ng-container *ngFor="let delivery of nestedGroup.deliveries ?? []">
                  <prf-delivery-planning-item
                    cdkDrag
                    [cdkDragData]="delivery"
                    [delivery]="delivery"
                    [isSelected]="delivery.id === selectedDetailsDelivery?.id"
                    [showDetailsButton]="showDeliveryItemDetailsButton"
                    (clickDetails)="selectEntityForEdit.emit(delivery)" />
                </ng-container>
              </div>
            </p-panel>
          </ng-container>
        </ng-container>

        <!-- Flat Group (DateGroupingStrategy) -->
        <!--        The cdkDropList directive assumes that lists are vertical by default. This can be changed by setting the orientation property to `"horizontal".-->
        <ng-template #flatGroup>
          <div cdkDropList
               cdkDropListSortingDisabled
               [cdkDropListData]="deliveryGroup.deliveries ?? []"
               (cdkDropListDropped)="onDrop($event, deliveryGroup)">
            <ng-container *ngFor="let delivery of deliveryGroup.deliveries ?? []">
              <prf-delivery-planning-item
                cdkDrag
                [cdkDragData]="delivery"
                [delivery]="delivery"
                [isSelected]="delivery.id === selectedDetailsDelivery?.id"
                [showDetailsButton]="showDeliveryItemDetailsButton"
                (clickDetails)="selectEntityForEdit.emit(delivery)" />
            </ng-container>
          </div>
        </ng-template>

      </p-panel>
    </ng-container>
  </div>


</ng-container>

