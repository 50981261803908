import { IMarket } from '@prf/shared/domain';

export class LoadMarkets {
  static type = '[Markets] LoadMarkets';
  constructor() {}
}

export class UpdateMarket {
  static type = '[Markets] Update Market';
  constructor(public payload: { entity: IMarket }) {}
}

export class CreateMarket {
  static type = '[Markets] Create New Market';
  constructor(public payload: { entity: IMarket }) {}
}
