import { CreatableEntity, IEntity, PerfoEntityType } from "@prf/shared/domain";
import { NgxsOnInit, StateContext } from "@ngxs/store";
import { NotificationActionType, ToastService } from "../../shared/services/toast/toast.service";
import { catchError, Observable, tap, throwError } from "rxjs";
import { insertItem, patch, updateItem } from "@ngxs/store/operators";
import { Mutation, Query } from "../../graphql/_types.generated";

interface EntityStateServices<TCreate, TUpdate, TFetch> {
  fetchAll: TFetch;
  create: TCreate;
  update: TUpdate;
}

export abstract class BaseEntityState<
  TServices extends EntityStateServices<any, any, any>,
  TEntity extends IEntity
> implements NgxsOnInit {
  protected abstract entityType: PerfoEntityType;

  constructor(protected entityServices: TServices,
              protected toastService: ToastService) {}

  ngxsOnInit(ctx: StateContext<any>): void {
    // TODO: Refactor: Find a single entrance point to load all entities.
    // Currently upon login navigate and upon init state (here).
    ctx.dispatch(this.getLoadAction());
  }

  protected abstract getLoadAction(): object;
  protected abstract mapGqlEntityToUiEntity(entity: any): TEntity;

  // TODO: Refactor: TGqlEntity not working properly yet.
  protected loadEntities<TGqlEntity>(
    ctx: StateContext<any>,
    queryName: keyof Query,
  ): Observable<any> {
    return this.entityServices.fetchAll.fetch().pipe(
      tap(({ data }) => {
        const queryResult = data ? data[queryName]: [];
        if (queryResult) {
          const entities = queryResult.map((entity: TGqlEntity) => this.mapGqlEntityToUiEntity(entity));
          ctx.setState(
            patch({
              items: [...entities]
            })
          );
          // console.log("--- " + this.entityType.toUpperCase() + " ---");
          // console.table(entities);
        }
      }),
      catchError((error) => {
        console.error(`Error fetching ${this.entityType}:`, error);
        return throwError(error);
      })
    );
  }

  protected createEntity<TCreateInput>(
    ctx: StateContext<any>,
    newEntity: CreatableEntity<TEntity>,
    mutationName: keyof Mutation
  ): Observable<any> {
    const variables: { input: TCreateInput } = {
      input: newEntity as unknown as TCreateInput
    };

    return this.entityServices.create.mutate(variables).pipe(
      tap(({ data }) => {
        const createdEntity = data ? this.mapGqlEntityToUiEntity(data[mutationName]) : null;
        if (createdEntity) {
          ctx.setState(
            patch({
              items: insertItem<TEntity>(createdEntity as any)
            })
          );
          this.toastService.showEntityCrudSuccess(this.entityType, NotificationActionType.CREATE);
        }
      }),
      catchError((error) => {
        console.error(`Error creating ${this.entityType}:`, error);
        this.toastService.showEntityCrudError(this.entityType, NotificationActionType.CREATE, error);
        return throwError(error);
      })
    );
  }

  protected updateEntity<TUpdateInput>(
    ctx: StateContext<any>,
    updateEntity: TEntity,
    mutationName: keyof Mutation
  ): Observable<any> {
    const { id, ...updateInput } = updateEntity;
    const variables: { id: number; input: TUpdateInput } = {
      id: id as number,
      input: updateInput as TUpdateInput,
    };

    return this.entityServices.update.mutate(variables).pipe(
      tap(({ data }) => {
        const updatedEntity = data ? this.mapGqlEntityToUiEntity(data[mutationName]) : null;
        if (updatedEntity) {
          // TODO: extract this into own method that can be callable from other methods/updates as well, ie setDeliveryStatus
          ctx.setState(
            patch({
              items: updateItem<TEntity>(
                (item: TEntity) => item.id === id,
                updatedEntity as any
              ),
            })
          );
          this.toastService.showEntityCrudSuccess(this.entityType, NotificationActionType.UPDATE);
        }
      }),
      catchError((error) => {
        console.error(`Error updating ${this.entityType}:`, error);
        this.toastService.showEntityCrudError(this.entityType, NotificationActionType.UPDATE, error);
        return throwError(error);
      }),
    );
  }

}
