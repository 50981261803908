import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { LoginService } from '../graphql/auth-operations.generated';
import { FormsModule } from '@angular/forms';
import { catchError, tap, throwError } from 'rxjs';
import { MessagesModule } from 'primeng/messages';
import { CLIENT_ROUTE_CONFIG, RouteKey } from '../main/main.routes';
import { AuthService } from '../shared/services/auth/auth.service';
import { APP_VERSION } from '@prf/shared/domain';

@Component({
  selector: 'prf-login-page',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    CheckboxModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    MessagesModule,
  ],
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent implements OnInit {
  protected readonly appVersion = APP_VERSION;

  userName: string = '';
  userPassword: string = '';

  hasLoginError = false;

  constructor(
    private loginService: LoginService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.navigateToMainApp();
    }
  }

  // TODO: emit/handle NGXS action: LoginUser
  onClickLogin(): void {
    if (this.userName && this.userPassword) {
      this.hasLoginError = false;
      this.loginService
        .mutate({
          username: this.userName,
          password: this.userPassword,
        })
        .pipe(
          tap(({ data }) => {
            if (data?.login) {
              this.authService.setSessionLoginData(data.login);
              if (this.authService.canAccessPerfoHq()) {
                this.navigateToMainApp();
              } else {
                this.authService.clearLocalstorageAuthValues();
                this.hasLoginError = true;
              }
            }
          }),
          catchError((error) => {
            console.error('Error logging user in:', error);
            this.hasLoginError = true;
            // TODO: show inline error message
            return throwError(error);
          }),
        )
        .subscribe();
    }
  }

  private navigateToMainApp(): void {
    this.router.navigate([CLIENT_ROUTE_CONFIG[RouteKey.DASHBOARD]?.path]);
  }
}
