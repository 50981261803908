import { Injectable, Type } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { CreatableEntity, IEntity, PerfoEntityType } from "@prf/shared/domain";
import { ProductDetailsDialogComponent } from '../../../components/product/product-details-dialog/product-details-dialog.component';
import { EntityFactory } from '../../../factories/entity.factory';
import { RetailerDetailsDialogComponent } from '../../../components/retailer/retailer-details-dialog/retailer-details-dialog.component';
import { MarketDetailsDialogComponent } from '../../../components/market/market-details-dialog/market-details-dialog.component';
import { IngredientDetailsDialogComponent } from '../../../components/ingredient/ingredient-details-dialog/ingredient-details-dialog.component';
import {
  DeliveryDetailsDialogComponent
} from "../../../components/delivery/delivery-details-dialog/delivery-details-dialog.component";
import {
  VehicleDetailsDialogComponent
} from "../../../components/vehicles/vehicle-details-dialog/vehicle-details-dialog.component";
import { UserDetailsDialogComponent } from "../../../components/user/user-details-dialog/user-details-dialog.component";

@Injectable({
  providedIn: 'root',
})
export class CreateEntityDialogService {
  private dynamicDialogRef: DynamicDialogRef | undefined;

  readonly DEFAULT_ENTITY_DIALOG_CONFIG: Partial<DynamicDialogConfig> = {
    width: '30vw',
    style: {
      'min-width': '680px',
    },
    contentStyle: { overflow: 'auto' },
    baseZIndex: 10000,
    draggable: true,
  };

  // TODO: This should be extracted/merged with config for NEW ENTITY button inside the header.
  readonly ENTITY_DIALOG_MAP: {
    [key in PerfoEntityType]?: {
      component: Type<any>;
      entityFactory: () => CreatableEntity<IEntity>;
      dialogHeader: string;
    };
  } = {
    user: {
      component: UserDetailsDialogComponent,
      entityFactory: EntityFactory.createNewUser,
      dialogHeader: 'Neuen Benutzer anlegen',
    },
    product: {
      component: ProductDetailsDialogComponent,
      entityFactory: EntityFactory.createNewProduct,
      dialogHeader: 'Neuen Artikel anlegen',
    },
    ingredient: {
      component: IngredientDetailsDialogComponent,
      entityFactory: EntityFactory.createNewIngredient,
      dialogHeader: 'Neue Rohware anlegen',
    },
    retailer: {
      component: RetailerDetailsDialogComponent,
      entityFactory: EntityFactory.createNewRetailer,
      dialogHeader: 'Neuen Händler anlegen',
    },
    market: {
      component: MarketDetailsDialogComponent,
      entityFactory: EntityFactory.createNewMarket,
      dialogHeader: 'Neuen Markt anlegen',
    },
    delivery: {
      component: DeliveryDetailsDialogComponent,
      entityFactory: EntityFactory.createNewDelivery,
      dialogHeader: 'Neue Lieferung anlegen',
    },
    vehicle: {
      component: VehicleDetailsDialogComponent,
      entityFactory: EntityFactory.createNewVehicle,
      dialogHeader: 'Neues Fahrzeug anlegen',
    },
  };

  constructor(public dialogService: DialogService) {}

  showCreateDialogForType(entityType: PerfoEntityType): void {
    const entityDialogData = this.ENTITY_DIALOG_MAP[entityType];

    if (!entityDialogData) {
      throw Error(
        `Unknown entityType ${entityType} in openCreateEntityDialog.`
      );
    }

    this.openCreateEntityDialog(
      entityDialogData.component,
      entityDialogData.entityFactory(),
      entityDialogData.dialogHeader
    );
  }

  private openCreateEntityDialog(
    component: Type<any>,
    entityInstance: CreatableEntity<IEntity>,
    dialogHeader: string
  ): void {
    this.dynamicDialogRef = this.dialogService.open(component, {
      data: {
        entity: entityInstance,
      },
      header: dialogHeader,
      ...this.DEFAULT_ENTITY_DIALOG_CONFIG,
    });

    this.dynamicDialogRef.onClose.subscribe((_data: any) => {});
  }
}
