import { EUserStatus, IDelivery, IUser } from "@prf/shared/domain";
import {
  GroupedDeliveries
} from "../../../../view-models/delivery-planning-draggable-week-overview-view-model.queries";
import { GroupingStrategy } from "./grouping-strategy";
import { CalendarWeekInfo } from "../../../../state/ui.state";

const UNASSIGNED_DRIVER = 'DRIVER_NOT_YET_ASSIGNED';

export class DriverGroupingStrategy extends GroupingStrategy {
  constructor(private entities: { drivers: IUser[] }) {
    super();
  }

  group(deliveries: IDelivery[], calendarWeekInfo: CalendarWeekInfo): GroupedDeliveries[] {
    const driverGroups: { [key: number|string]: IDelivery[] } = {};

    // Initialize groups for all drivers.
    for (const driver of this.entities.drivers) {
      driverGroups[driver.id] = [];
    }

    // Add a group for unassigned drivers.
    driverGroups[UNASSIGNED_DRIVER] = [];

    // Group deliveries by driver.
    for (const delivery of deliveries) {
      const driverIdKey = delivery.driverId ?? UNASSIGNED_DRIVER;
      driverGroups[driverIdKey].push(delivery);
    }

    // Further grouping each driver's deliveries by weekday.
    const result: GroupedDeliveries[] = Object.entries(driverGroups).map(([driverId, deliveries]: [number|string, IDelivery[]]) => {
      // Create unique weekDayStructure for each driver.
      const weekDayGroups = this.initializeWeekdays(calendarWeekInfo);

      for (const delivery of deliveries) {
        const date = delivery.deliveryDate as string;
        if (weekDayGroups[date]) {
          weekDayGroups[date].push(delivery);
        }
      }

      const nestedGroups = Object.entries(weekDayGroups).map(([date, deliveries]) => ({
        title: this.formatDate(date),
        headerIconClass: 'pi-calendar',
        deliveries: deliveries,
        deliveriesCount: deliveries.length,
        updateFields: {
          deliveryDate: date,
          driverId: driverId === UNASSIGNED_DRIVER ? null : +driverId,
        },
      }));

      // Sum up the deliveries count for the parent group.
      const totalDeliveriesCount = nestedGroups.reduce((sum, group) => sum + group.deliveriesCount, 0);

      return {
        title: driverId === UNASSIGNED_DRIVER ? 'Kein Fahrer zugewiesen' : this.getDriverNameById(+driverId),
        headerIconClass: 'pi-user',
        nestedGroups: nestedGroups,
        deliveriesCount: totalDeliveriesCount,
        isNotAssignedGroup: driverId === UNASSIGNED_DRIVER,
        updateFields: {}
      };
    });

    // Sort "not assigned" group to start.
    result.sort((a, b) => (b.isNotAssignedGroup ? 1 : -1));

    return result;
  }

  private getDriverNameById(userId: number): string {
    const userEntity = this.entities.drivers.find((user) => user.id === userId);

    if (!userEntity) {
      return 'Unbekannter Nutzer';
    }

    const baseName = `${userEntity.lastName}, ${userEntity.firstName}`;
    if (userEntity.status === EUserStatus.INACTIVE) {
      return `INAKTIV • ${baseName}`;
    }

    return baseName;
  }

}
