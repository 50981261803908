<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="licensePlate">Kennzeichen</label>
      <input id="licensePlate" type="text" [(ngModel)]="entity.licensePlate" pInputText />
    </div>
    <div class="p-field prf-field">
      <label for="vehicleModel">Modell</label>
      <input id="vehicleModel" type="text" [(ngModel)]="entity.vehicleModel" pInputText />
    </div>
  </div>
</div>

<div class="flex justify-content-between">
  <p-button *ngIf="showClose"
            [outlined]="true"
            label="Schließen"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="clickClose.emit()"
  />
  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
            styleClass="p-button-primary"
            (click)="onClickSave()" />
</div>

<!--CSS KENNZEICHEN-->
<!--https://sedna-soft.de/articles/kennzeichen/-->
