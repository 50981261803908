import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IUser } from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { UserDetailsComponent } from '../user-details/user-details.component';
import { CreateUser } from '../../../../state/entities/users.actions';
import { take } from "rxjs";

type LocalModel = IUser;

@Component({
  selector: 'prf-user-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    UserDetailsComponent,
  ],
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsDialogComponent {
  entity: LocalModel;
  updateInProgress = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private store: Store
  ) {
    this.entity = this.dialogConfig.data.entity;
  }

  handleCreateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new CreateUser({ entity: entity }))
      .pipe(take(1))
      .subscribe((result) => {
        this.updateInProgress = false;
        this.dialogRef.close();
      });
  }
}
