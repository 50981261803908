import {
  createPerfoTableConfig,
  PerfoDataTableCellRenderType,
} from '../../shared/components/data-table/data-table.component';
import { IInvoice } from '@prf/shared/domain';

export const InvoicesTableConfig = createPerfoTableConfig<IInvoice>([
  {
    header: 'Rechnungs-Nr.',
    field: 'invoiceNumber',
    sortable: true,
    customRender: { monoFont: true },
  },
  {
    header: 'Lieferschein-Nr.',
    field: 'deliverySlipNumber',
    sortable: true,
    customRender: { monoFont: true },
  },
  {
    header: 'Rechnungs-Datum',
    // TODO: Add custom sort. Extract YYYY_MM_DD customSort fn
    field: 'invoiceDate',
    sortable: true,
    customRender: {
      renderType: PerfoDataTableCellRenderType.DATE,
      monoFont: true,
    },
  },
  {
    header: 'Markt',
    field: 'marketName',
    sortable: true,
  },
  {
    header: 'Lieferadresse',
    field: 'marketDeliveryAddress',
    sortable: true,
    customRender: { renderType: PerfoDataTableCellRenderType.ADDRESS },
  },
  {
    header: 'Status',
    field: 'status',
    sortable: true,
  },
  {
    header: 'Versendet',
    field: 'invoiceEmailSent',
    sortable: true,
  },
  // {
  //   header: 'Nettorabatt',
  //   field: 'netDiscount',
  //   sortable: true,
  // },
  {
    header: 'Betrag Netto (EUR)',
    field: 'invoiceAmount',
    sortable: true,
    customRender: {
      renderType: PerfoDataTableCellRenderType.PRICE,
      monoFont: true,
    },
  },
]);
