import {
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from "@ngxs/store";
import { LogoutUser } from "../../state/ui.actions";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
          // Check for error codes in the GraphQL response
          if (event.body.errors) {
            const authErrors = event.body.errors.filter(
              (error: any) =>
                error.extensions && error.extensions.code === 'FORBIDDEN',
            );
            if (authErrors.length > 0) {
              this.store.dispatch(new LogoutUser({skipLogoutRequest: true}));
            }
          }
        }
      }),
    );
  }
}
