import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GoToEntityView, UpdateNavigationInfo } from "./route.actions";
import { CLIENT_ROUTE_CONFIG, RouteKey } from '../main/main.routes';
import { PerfoEntityType } from "@prf/shared/domain";
import { Router } from "@angular/router";

export interface RouteStateModel {
  routePath: string;
  routeName: string;
}

type LocalStateModel = RouteStateModel;
type LocalStateContext = StateContext<LocalStateModel>;

@State<RouteStateModel>({
  name: 'route',
  defaults: {
    routePath: '',
    routeName: '',
  },
})
@Injectable()
export class RouteState {
  constructor(private router: Router) {}

  @Selector() static currentRoute(state: LocalStateModel): string {
    return state.routePath;
  }

  @Selector() static routeName(state: LocalStateModel): string {
    return state.routeName;
  }

  @Selector() static currentEntityType(state: LocalStateModel): PerfoEntityType | null {
    return CLIENT_ROUTE_CONFIG[getRouteConfigByPath(state.routePath)]?.entityType || null;
  }

  @Action(GoToEntityView)
  goToEntityView(ctx: LocalStateContext, action: GoToEntityView): void {
    const routePath = getRoutePathByEntityType(action.entityType);
    if (routePath) {
      this.router.navigate([routePath])
    }
  }

  @Action(UpdateNavigationInfo)
  updateNavigationInfo(ctx: LocalStateContext, action: UpdateNavigationInfo): void {
    const { routePath } = action;

    const routeDetails = CLIENT_ROUTE_CONFIG[getRouteConfigByPath(routePath)];
    if (!routeDetails) {
      console.error(`Invalid route: ${routePath}`);
      return;
    }

    // Navigating like this leads to a delayed routing. CD needs to be triggered?
    // this.router.navigate([routeDetails.path]);

    ctx.patchState({
      routePath: routeDetails.path,
      routeName: routeDetails.label
    });
  }
}

// TODO: Refactor: Extract into helper.
export function getRouteConfigByPath(path: string): RouteKey {
  if (path.startsWith('/')) {
    path = path.substring(1);
  }

  for (const key in CLIENT_ROUTE_CONFIG) {
    if (CLIENT_ROUTE_CONFIG[key as RouteKey]?.path === path) {
      return key as RouteKey;
    }
  }

  // Default
  return RouteKey.DASHBOARD;
}

export function getRoutePathByEntityType(entityType: PerfoEntityType): string {
  for (const key in CLIENT_ROUTE_CONFIG) {
    if (CLIENT_ROUTE_CONFIG[key as RouteKey]?.entityType === entityType) {
      return CLIENT_ROUTE_CONFIG[key as RouteKey]?.path ?? '';
    }
  }

  throw new Error(`Path not found for entity type: ${entityType}`);
}
