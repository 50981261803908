import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeliveryProductFieldsFragment = { __typename?: 'DeliveryProductEntity', id: number, actualQuantity?: number | null, returnQuantity?: number | null, targetQuantity?: number | null, delivery: { __typename?: 'DeliveryEntity', id: number }, product: { __typename?: 'ProductEntity', id: number } };

export type UpdateDeliveryProductsMutationVariables = Types.Exact<{
  input: Types.UpdateDeliveryProductsInput;
}>;


export type UpdateDeliveryProductsMutation = { __typename?: 'Mutation', updateDeliveryProducts: Array<{ __typename?: 'DeliveryProductEntity', id: number, actualQuantity?: number | null, returnQuantity?: number | null, targetQuantity?: number | null, delivery: { __typename?: 'DeliveryEntity', id: number }, product: { __typename?: 'ProductEntity', id: number } }> };

export const DeliveryProductFieldsFragmentDoc = gql`
    fragment DeliveryProductFields on DeliveryProductEntity {
  id
  actualQuantity
  returnQuantity
  targetQuantity
  delivery {
    id
  }
  product {
    id
  }
}
    `;
export const UpdateDeliveryProductsDocument = gql`
    mutation UpdateDeliveryProducts($input: UpdateDeliveryProductsInput!) {
  updateDeliveryProducts(updateDeliveryProductsInput: $input) {
    ...DeliveryProductFields
  }
}
    ${DeliveryProductFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDeliveryProductsService extends Apollo.Mutation<UpdateDeliveryProductsMutation, UpdateDeliveryProductsMutationVariables> {
    override document = UpdateDeliveryProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }