<div class="flex justify-content-center mt-6" style="margin-left: -294px;">
  <!-- TODO: get rid of SIDEBAR-OPEN translation by 294px on login page ^ -->
  <div class="surface-card p-4 shadow-2 border-round" style="width: 400px;">
    <div class="text-center mb-5">
      <!-- <img src="assets/images/logo.svg" alt="Perfo Logo" height="50" class="mb-3">-->
      <div class="text-900 text-3xl font-medium mb-3">Perfo</div>
    </div>

    <div (keydown.enter)="onClickLogin()">
      <label for="username1" class="block text-900 font-medium mb-2">Benutzername</label>
      <input [(ngModel)]="userName"
             id="username1" type="text" pInputText class="w-full mb-3">

      <label for="password1" class="block text-900 font-medium mb-2">Passwort</label>
      <input [(ngModel)]="userPassword"
             id="password1" type="password" pInputText class="w-full mb-3">

      <p-messages severity="error" *ngIf="hasLoginError">
        <ng-template pTemplate>
          <div class="ml-2">Fehler bei der Anmeldung. <br/>Bitte prüfen Sie Ihre Eingaben.</div>
        </ng-template>
      </p-messages>

      <button pButton
              label="Anmelden"
              class="w-full mt-3"
              (click)="onClickLogin()"
      ></button>
    </div>
    <div class="text-center" style="
    position: absolute;
    margin-top: 38px;
    width: 400px;
    margin-left: -25px;">
      <span style="color: #858585;">Version {{appVersion}}</span>
    </div>
  </div>
</div>
