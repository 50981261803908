import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { CreateRetailer, LoadRetailers, UpdateRetailer } from './retailers.actions';
import { Observable } from 'rxjs';
import {
  CreateRetailerMutationVariables,
  CreateRetailerService,
  GetAllRetailersService,
  RetailerFieldsFragment,
  UpdateRetailerService,
} from '../../graphql/retailers-operations.generated';
import { IRetailer, PerfoEntityType } from '@prf/shared/domain';
import { BaseEntityState } from './base.state';
import { Injectable } from '@angular/core';
import { ToastService } from '../../shared/services/toast/toast.service';
import { UpdateRetailerInput } from '../../graphql/_types.generated';

type LocalModel = IRetailer;
type LocalStateModel = RetailersStateModel;
type LocalStateContext = StateContext<RetailersStateModel>;

export interface RetailersStateModel {
  items: LocalModel[];
}

@State<RetailersStateModel>({
  name: 'retailers',
  defaults: {
    items: [],
  },
})
@Injectable()
export class RetailersState
  extends BaseEntityState<
    {
      fetchAll: GetAllRetailersService;
      create: CreateRetailerService;
      update: UpdateRetailerService;
    },
    IRetailer
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'retailer';

  // TODO: Refactor: Extract into something abstract. Also needed for retailers.state.ts
  // TODO: Refactor: Make sure the name of this property suggests picking is for Create/Update, and not Read.
  private readonly entityPropertiesToPick: Record<keyof IRetailer, boolean> = {
    id: true,
    retailerName: true,
    billingAddress: true,
    gln: true,
    invoiceEmail: true,
    phone: true,
    retailerNumber: false,
    // discount: false,
  };

  constructor(
    protected getAllRetailersService: GetAllRetailersService,
    protected createRetailerService: CreateRetailerService,
    protected updateRetailerService: UpdateRetailerService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllRetailersService,
        create: createRetailerService,
        update: updateRetailerService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadRetailers();
  }

  protected mapGqlEntityToUiEntity(entity: RetailerFieldsFragment): IRetailer {
    return {
      id: entity.id,
      retailerName: entity.retailerName,
      retailerNumber: entity.retailerNumber,
      // discount: entity.discount,
      invoiceEmail: entity.invoiceEmail,
      phone: entity.phone,
      billingAddress: {
        street: entity.billingAddress.street,
        houseNumber: entity.billingAddress.houseNumber,
        postalCode: entity.billingAddress.postalCode,
        city: entity.billingAddress.city,
        country: entity.billingAddress.country,
      },
      gln: entity.gln,
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadRetailers)
  protected loadRetailers(ctx: LocalStateContext, action: LoadRetailers): Observable<any> {
    return this.loadEntities<RetailerFieldsFragment[]>(ctx, 'retailers');
  }

  @Action(CreateRetailer)
  createRetailer(ctx: LocalStateContext, action: CreateRetailer): Observable<any> {
    const entity = this.pickEntityProperties(action.payload.entity);
    return this.createEntity<CreateRetailerMutationVariables>(ctx, entity, 'createRetailer');
  }

  @Action(UpdateRetailer)
  updateRetailer(ctx: LocalStateContext, action: UpdateRetailer): Observable<any> {
    const entity = this.pickEntityProperties(action.payload.entity);

    return this.updateEntity<UpdateRetailerInput>(ctx, entity, 'updateRetailer');
  }

  private pickEntityProperties(entity: any): IRetailer {
    const retailer: Partial<IRetailer> = {};
    Object.keys(this.entityPropertiesToPick).forEach((key) => {
      if (this.entityPropertiesToPick[key as keyof IRetailer] && key in entity) {
        retailer[key as keyof IRetailer] = entity[key];
      }
    });
    return retailer as IRetailer;
  }
}
