import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { CreatableEntity, IProduct } from "@prf/shared/domain";
import { Store } from '@ngxs/store';
import { CreateProduct } from '../../../../state/entities/products.actions';
import { take } from "rxjs";

@Component({
  selector: 'prf-product-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    ProductDetailsComponent,
  ],
  templateUrl: './product-details-dialog.component.html',
  styleUrls: ['./product-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsDialogComponent {
  entity: IProduct;
  updateInProgress = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private store: Store
  ) {
    this.entity = this.dialogConfig.data.entity;
  }

  handleCreateEntity(entity: CreatableEntity<IProduct>) {
    const { id, ...newEntity } = entity;

    this.updateInProgress = true;
    this.store
      .dispatch(new CreateProduct({ entity: newEntity }))
      .pipe(take(1))
      .subscribe((result) => {
        this.updateInProgress = false;
        this.dialogRef.close();
      });
  }
}
