import {
  CreatableEntity,
  DeliveryStatus,
  DeliveryType,
  EIngredientUnit,
  EUserRole,
  EUserStatus, IAddress,
  IDelivery,
  IIngredient,
  IMarket,
  IProduct,
  IRetailer,
  IUser,
  IVehicle
} from "@prf/shared/domain";

export class EntityFactory {
  static createNewUser(): CreatableEntity<IUser> {
    return {
      firstName: '',
      lastName: '',
      role: EUserRole.DRIVER,
      status: EUserStatus.ACTIVE,
      username: '',
    };
  }

  static createNewProduct(): CreatableEntity<IProduct> {
    return {
      category: '',
      description: '',
      ean: '',
      grossPrice: 0,
      netPrice: 0,
      pack: '',
      productNo: '',
      vatRate: 7,
      uvp: 0,
      recipe: null,
    };
  }

  static createNewRetailer(): CreatableEntity<IRetailer> {
    return {
      retailerName: '',
      retailerNumber: null,
      billingAddress: EntityFactory.createNewAddress(),
      invoiceEmail: '',
      phone: '',
      // discount: 0,
      gln: '',
    };
  }

  static createNewMarket(): CreatableEntity<IMarket> {
    return {
      customerNumber: null,
      billingAddress: EntityFactory.createNewAddress(),
      deliveryAddress: EntityFactory.createNewAddress(),
      contactPhone: '',
      invoiceEmail: '',
      deliverySlipEmail: '',
      billToGLN: '',
      deliverToGLN: '',
      marketName: '',
      isDirectInvoice: false,
      notes: '',
      operator: '',
      retailerId: null,
      customReceiptTextsPos1: [],
      customReceiptTextsPos2: [],
    };
  }


  static createNewDelivery(): CreatableEntity<IDelivery> {
    return {
      deliveryDate: new Date(),
      deliveryDeliveredDate: null,
      deliverySlipNumber: null,
      hasPostDeliveryCorrections: false,
      deliverySlipEmailSent: false,
      isActive: true,
      driverId: 0,
      driverNote: '',
      marketId: 0,
      status: DeliveryStatus.CREATED,
      type: DeliveryType.PRIMARY,
      vehicleId: null,
    };
  }

  static createNewIngredient(): CreatableEntity<IIngredient> {
    return {
      name: '',
      unit: EIngredientUnit.GRAM,
    };
  }

  static createNewVehicle(): CreatableEntity<IVehicle> {
    return {
      licensePlate: '',
      vehicleModel: '',
    };
  }

  // Helper/fragments
  static createNewAddress(): IAddress {
    return {
      street: '',
      houseNumber: '',
      postalCode: '',
      city: '',
      country: ''
    };
  }
}
