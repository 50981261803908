import { Selector } from '@ngxs/store';
import { IInvoice } from '@prf/shared/domain';
import { InvoicesState } from '../state/entities/invoices.state';
import { InvoicesUiState, UiState } from '../state/ui.state';
import * as dayjs from "dayjs";

export interface InvoicesPageViewModel {
  invoices: IInvoice[];
  tableRangeDates: Date[] | undefined;
  selectedDetailsInvoiceState: {
    invoiceId: number | null;
    isUpdating: boolean;
  };
}

export class InvoicesPageViewModelQueries {
  @Selector([InvoicesState.items, UiState.invoicesState])
  static getViewModel(
    invoices: IInvoice[],
    invoicesUiState: InvoicesUiState,
  ): InvoicesPageViewModel {
    return {
      invoices: InvoicesPageViewModelQueries.getFilteredInvoices(
        invoices,
        invoicesUiState.tableRangeDates
      ),
      tableRangeDates: invoicesUiState.tableRangeDates,
      selectedDetailsInvoiceState: invoicesUiState.selectedDetailsInvoiceState,
    };
  }

  private static getFilteredInvoices(
    invoices: IInvoice[],
    tableRangeDates: Date[] | undefined
  ): IInvoice[] {
    if (!tableRangeDates || tableRangeDates.length !== 2) {
      return invoices;
    }

    const [fromDate, toDate] = tableRangeDates.map((date) => dayjs(date));
    return invoices.filter((invoice) =>
      dayjs(invoice.invoiceDate).isBetween(fromDate, toDate, 'day', '[]')
    );
  }
}
