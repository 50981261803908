import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IProduct } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { ProductsTableConfig } from './table.config';
import { TabViewModule } from 'primeng/tabview';
import { RecipeEditorComponent } from './components/recipe-editor/recipe-editor.component';
import { Observable, take } from 'rxjs';
import {
  ProductsPageViewModel,
  ProductsPageViewModelQueries,
} from '../../view-models/product-view-model.queries';
import { ProductDetailsComponent } from '../../shared/components/product/product-details/product-details.component';
import { UpdateProduct } from '../../state/entities/products.actions';
import { ToggleEntityDetailsExpandDirective } from '../../shared/directives/toggle-entity-details-expand.directive';

type LocalModel = IProduct;

// TODO: Component inheritance for all view comps? Generic entityType, TableConfig..., handleEntityUpdate
@Component({
  selector: 'prf-products-view',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    TabViewModule,
    RecipeEditorComponent,
    ProductDetailsComponent,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './products-view.component.html',
  styleUrls: ['./products-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsViewComponent {
  TableConfig = ProductsTableConfig;
  // TODO... rename to selectedEntity
  selectedEntity: LocalModel | null = null;

  @Select(ProductsPageViewModelQueries.getViewModel)
  viewModel$!: Observable<ProductsPageViewModel>;

  // TODO: set via ngxs select/state. or use empty list as default/convention for loading
  loading = false;

  updateInProgress = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    // TODO: put into store?
    this.selectedEntity = $event as LocalModel;
  }

  handleUpdateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateProduct({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }

  // handleUpdateEntity(entity: LocalModel) {
  //   this.updateInProgress = true;
  //   this.store
  //     .dispatch(new UpdateProduct({ entity: entity }))
  //     .pipe(
  //       take(1),
  //       catchError((error) => {
  //         console.error('Error updating product:', error);
  //         return []; // or of(null)
  //       }),
  //       finalize(() => {
  //         this.updateInProgress = false;
  //         this.cdRef.markForCheck();
  //       }),
  //     )
  //     .subscribe((result: any) => {});
  // }
}
