import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Table, TableFilterEvent, TableModule } from 'primeng/table';
import { IAddress, IEntity } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import * as FileSaver from 'file-saver';
import * as dayjs from 'dayjs';
import { EntityLookupService } from '../../services/entity-lookup/entity-lookup.service';
import { SortEvent } from 'primeng/api';

// TODO: Extract into util, if used.
export type NestedKeyOf<ObjectType extends object> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

interface ExportColumn<T> {
  title: string;
  dataKey: keyof T | string;
}

// TODO: Refactor: Merge? Col interfaces. Extract into file.
export interface PerfoTableColumn<T> {
  header: string;
  field: keyof T; // Note: if nested keys are used, like "contactData.email" this keyof doesn't work anymore.
  width?: number;
  class?: string;
  sortable?: boolean;
  customSort?: (a: T, b: T) => number;
  customRender?: {
    renderType?: PerfoDataTableCellRenderType;
    monoFont?: boolean;
  };
}

export interface PerfoTableConfig<T extends IEntity> {
  columns: PerfoTableColumn<T>[];
}

// TODO: Check whether to add table header/entityType... to be able to create pdf export header - entityType, ie "Artikel"
// OR: for now only prompt for header text...
export function createPerfoTableConfig<T extends IEntity>(
  columns: PerfoTableColumn<T>[],
): PerfoTableConfig<T> {
  return Object.freeze({ columns });
}

export enum PerfoDataTableCellRenderType {
  DATE = 'DATE',
  PRICE = 'PRICE',
  MONEY = 'MONEY',
  ADDRESS = 'ADDRESS',
  MARKET = 'MARKET',
  USER = 'USER',
}

// PAGINATION via Graphql/Apollo
// https://www.apollographql.com/docs/react/pagination/overview

@Component({
  selector: 'prf-data-table',
  standalone: true,
  imports: [CommonModule, TableModule, ButtonModule, TooltipModule],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent<T> implements OnInit {
  protected readonly PerfoDataTableCellRenderType = PerfoDataTableCellRenderType;
  private entityLookupService = inject(EntityLookupService);

  @ViewChild('pTable') pTable!: Table;

  @Input()
  selectedEntity: IEntity | null = null;

  // TODO: add typing via primeNg types.
  DEFAULT_COL_FILTER_MATCH_MODE = 'contains';

  @Input()
  rowData: IEntity[] = [];

  @Input()
  tableColumns!: PerfoTableColumn<T>[];

  @Input()
  loading = false;

  @Output()
  selectionChange: EventEmitter<IEntity | null> = new EventEmitter<IEntity | null>();

  @Output()
  tableFilter: EventEmitter<TableFilterEvent> = new EventEmitter<TableFilterEvent>();

  exportColumns!: ExportColumn<T>[];

  ngOnInit(): void {
    this.exportColumns = this.tableColumns.map((col) => ({
      title: col.header,
      dataKey: col.field,
    }));
  }

  // TODO: extract into util/service
  // TODO: Prompt for custom header text
  // checkbox: Auflistung erstellt am DATUM/ZEIT hinzufügen
  exportPdf() {
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('landscape', 'px', 'a4');
        (doc as any).autoTable({
          columns: this.exportColumns,
          body: this.getFilteredRows(),
          theme: 'grid',
          headStyles: {
            fillColor: '#1C1C1C',
            textColor: '#FFFFFF',
          },
          didDrawPage: (data: any) => {
            // Header
            doc.setFontSize(10);
            const headerText =
              'PALDO GmbH - ' + this.getActiveTableFiltersString().replace('_', '');
            doc.text(headerText, data.settings.margin.left, 16);

            // Footer
            const footerText = 'Seite ' + (doc.internal as any).getNumberOfPages();
            doc.setFontSize(10);
            doc.text(footerText, data.settings.margin.left, doc.internal.pageSize.height - 30);
          },
        });
        const formattedDate = dayjs().format('YYYY-MM-DD_HH-mm');
        const activeFiltersString = this.getActiveTableFiltersString();
        doc.save(`Perfo-Export-${formattedDate}_${activeFiltersString}.pdf`);
      });
    });
  }

  // TODO: extract into util/service
  exportExcel() {
    import('xlsx').then((xlsx) => {
      // Map the data to use headers instead of field names
      const mappedRows = this.mapDataToHeaders(this.getFilteredRows(), this.tableColumns);

      // Convert mapped data to worksheet
      const worksheet = xlsx.utils.json_to_sheet(mappedRows);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      const formattedDate = dayjs().format('YYYY-MM-DD_HH-mm');
      this.saveAsExcelFile(
        excelBuffer,
        `Perfo-Export-${formattedDate}_${this.getActiveTableFiltersString()}`,
      );
    });
  }
  // TODO: extract into util/service

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  onSelectionChange($event: IEntity | null) {
    // selectedEntity gets auto set via 2waybinding
    this.selectionChange.emit($event);
  }

  formatDate(date: string): string | null {
    if (!date) {
      return null;
    }

    // TODO: Refactor: Extract into date utils.
    return dayjs(date).format('DD.MM.YYYY - ddd');
  }

  formatMarket(marketId: number): string {
    const market = this.entityLookupService.getMarketById(marketId);
    return market
      ? `${market.marketName} - ${market.deliveryAddress.street} ${market.deliveryAddress.houseNumber}, ${market.deliveryAddress.postalCode} ${market.deliveryAddress.city}`
      : `Unbekannter Markt (ID: ${marketId})`;
  }

  formatDriver(userId: number): string {
    return this.entityLookupService.getFullUserNameById(userId);
  }

  formatAddress(address: IAddress): string {
    return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`;
  }

  customSort(event: SortEvent): void {
    if (event.data) {
      const sortField = event.field!;
      const columnConfig = this.tableColumns.find(col => col.field === sortField);

      // Custom sort defined via table config
      if (columnConfig && columnConfig.customSort) {
        // event.order defines ASC or DESC (1, -1).
        event.data.sort((a, b) => event.order! * columnConfig.customSort!(a, b));
      } else {
        // Default sorting
        event.data.sort((a, b) => {
          const value1 = a[sortField];
          const value2 = b[sortField];
          let result;
          if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
          }
          return event.order! * result;
        });
      }
    }
  }

  // Visible rows. Subset of entire row data.
  private getFilteredRows(): T[] {
    // TODO: Check if nullish coalescing ?? should be used here
    return this.pTable.filteredValue || this.rowData;
  }

  private getActiveTableFiltersString(): string {
    const filters = this.pTable.filters;

    // Convert the active filters (non-null values) to a string format
    let filtersString = '';
    Object.keys(filters).forEach((key) => {
      const filter = filters[key];
      if (Array.isArray(filter)) {
        // Handle the case where filter is an array
        filter.forEach((f) => {
          if (f.value !== null) {
            filtersString += `_${f.value}`;
          }
        });
      } else {
        // Assuming filter is a single FilterMetadata object
        if (filter.value !== null) {
          filtersString += `_${filter.value}`;
        }
      }
    });

    return filtersString;
  }

  // TODO: Refactor: Extract into util, eg export helper.
  // private mapDataToHeaders<T extends IEntity>(rows: T[], columnConfig: PerfoTableConfig<T>): any[] {
  private mapDataToHeaders(rows: any[], columnConfig: PerfoTableColumn<any>[]): any[] {
    return rows.map(row => {
      const mappedRow: any = {};
      columnConfig.forEach(column => {
        mappedRow[column.header] = row[column.field];
      });
      return mappedRow;
    });
  }
}
