<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="username">Benutzername</label>
      <input id="username" type="text" [(ngModel)]="entity.username" pInputText />
    </div>
    <div class="flex">
      <div class="p-field prf-field">
        <label for="firstName">Vorname</label>
        <input id="firstName" type="text" [(ngModel)]="entity.firstName" pInputText />
      </div>
      <div class="p-field prf-field ml-3">
        <label for="lastName">Nachname</label>
        <input id="lastName" type="text" [(ngModel)]="entity.lastName" pInputText />
      </div>
    </div>
    <div *ngIf="isCreate" class="p-field prf-field">
      <label for="password">Passwort</label>
      <p-password id="password"
                  [(ngModel)]="entity.password"
                  [toggleMask]="true"
                  promptLabel="Mindestens 5 Zeichen. Maximal 30 Zeichen."
      />
    </div>
    <div class="p-field prf-field">
      <label for="role">Rolle</label>
      <p-dropdown id="role" [(ngModel)]="entity.role"
                  [options]="[
                      {label: 'Administrator', value: 'ADMIN'},
                      {label: 'Manager', value: 'MANAGER', disabled: true},
                      {label: 'Fahrer', value: 'DRIVER'}
                  ]"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
    <div class="p-field prf-field">
      <label for="status">Status</label>
      <p-dropdown id="status" [(ngModel)]="entity.status"
                  [options]="[
                      {label: 'Aktiv', value: 'ACTIVE'},
                      {label: 'Inaktiv', value: 'INACTIVE'}
                  ]"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
    <div class="flex flex-row" *ngIf="!isCreate">
      <div class="p-field prf-field flex flex-column">
        <label>
          Passwort ändern
        </label>
        <div class="text-center pt-2">
          <p-inputSwitch [(ngModel)]="setNewPassword" />
        </div>
      </div>

      <div class="p-field prf-field flex-1 ml-4" *ngIf="setNewPassword">
        <label for="password-new">Neues Passwort</label>
        <p-password id="password-new"
                    [(ngModel)]="entity.password"
                    [disabled]="!setNewPassword"
                    [toggleMask]="true"
                    promptLabel="Mindestens 5 Zeichen. Maximal 30 Zeichen."
        />
      </div>
    </div>
  </div>
</div>

<div class="flex justify-content-between">
  <p-button *ngIf="showClose"
            [outlined]="true"
            label="Schließen"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="clickClose.emit()"
  />
  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
            styleClass="p-button-primary"
            (click)="onClickSave()" />
</div>
