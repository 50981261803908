import { Injectable } from '@angular/core';
import { LogoutService } from '../../../graphql/auth-operations.generated';
import { finalize, tap } from 'rxjs';
import { EUserRole, ILoginResponse, SESSION_STORAGE_KEYS } from '@prf/shared/domain';

// TODO: Extract/abstract into shared, reuse in client-hq and perfo-mobile.

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private logoutService: LogoutService,
  ) {}

  setSessionLoginData({ expiry, role, username }: ILoginResponse): void {
    localStorage.setItem(SESSION_STORAGE_KEYS.EXPIRY, expiry.toString());
    localStorage.setItem(SESSION_STORAGE_KEYS.ROLE, role);
    localStorage.setItem(SESSION_STORAGE_KEYS.USERNAME, username);
  }

  canAccessPerfoHq(): boolean {
    return this.isLoggedIn() && this.isAdminUser();
  }

  // TODO: Rename to something like "hasValidSession".
  isLoggedIn(): boolean {
    const expiryTimeStr = localStorage.getItem(SESSION_STORAGE_KEYS.EXPIRY);
    if (!expiryTimeStr) {
      return false;
    }

    const expiryTime = Number(expiryTimeStr);
    if (Math.floor(Date.now() / 1000) > expiryTime) {
      // Clear the expired session
      this.logout();
      return false;
    }

    return true;
  }

  isAdminUser(): boolean {
    const sessionUserRole = localStorage.getItem(SESSION_STORAGE_KEYS.ROLE);
    return sessionUserRole === EUserRole.ADMIN;
  }

  logout(skipLogoutRequest: boolean = false): void {
    this.clearLocalstorageAuthValues();

    if (skipLogoutRequest) {
      this.browserRedirectToLoginPage();
      return;
    }

    this.logoutService
      .mutate()
      .pipe(
        tap((response) => {
          if (response?.data?.logout) {
            console.log('logout SUCCESS - ');
            // show toast success
          } else {
            console.error('logout FAIL - ');
            // show toast error logout fail
          }
        }),
        // TODO: catch error (ie no connectivity)
        finalize(() => {
          this.browserRedirectToLoginPage();
        }), // Navigate to login after the operation completes
      )
      .subscribe();
    // TODO: Refactor: Abstract navigation routes/actions into service.
  }

  // Use location redirect in order to reload entire app (clear all NGXS state).
  browserRedirectToLoginPage(): void {
    window.location.href = '/login';
  }

  clearLocalstorageAuthValues(): void {
    localStorage.removeItem(SESSION_STORAGE_KEYS.EXPIRY);
    localStorage.removeItem(SESSION_STORAGE_KEYS.ROLE);
    localStorage.removeItem(SESSION_STORAGE_KEYS.USERNAME);
  }
}
