import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { AddressFieldsFragmentDoc } from './shared-fragments.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MarketFieldsFragment = { __typename?: 'MarketEntity', id: number, invoiceEmail: string, deliverySlipEmail: string, contactPhone: string, customerNumber: number, billToGLN?: string | null, deliverToGLN?: string | null, isDirectInvoice: boolean, marketName: string, notes?: string | null, operator?: string | null, createdAt?: any | null, updatedAt?: any | null, billingAddress?: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } | null, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string }, retailer: { __typename?: 'RetailerEntity', id: number }, customReceiptTextsPos1?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null, customReceiptTextsPos2?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null };

export type GetAllMarketsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllMarketsQuery = { __typename?: 'Query', markets: Array<{ __typename?: 'MarketEntity', id: number, invoiceEmail: string, deliverySlipEmail: string, contactPhone: string, customerNumber: number, billToGLN?: string | null, deliverToGLN?: string | null, isDirectInvoice: boolean, marketName: string, notes?: string | null, operator?: string | null, createdAt?: any | null, updatedAt?: any | null, billingAddress?: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } | null, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string }, retailer: { __typename?: 'RetailerEntity', id: number }, customReceiptTextsPos1?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null, customReceiptTextsPos2?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null }> };

export type CreateMarketMutationVariables = Types.Exact<{
  input: Types.CreateMarketInput;
}>;


export type CreateMarketMutation = { __typename?: 'Mutation', createMarket: { __typename?: 'MarketEntity', id: number, invoiceEmail: string, deliverySlipEmail: string, contactPhone: string, customerNumber: number, billToGLN?: string | null, deliverToGLN?: string | null, isDirectInvoice: boolean, marketName: string, notes?: string | null, operator?: string | null, createdAt?: any | null, updatedAt?: any | null, billingAddress?: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } | null, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string }, retailer: { __typename?: 'RetailerEntity', id: number }, customReceiptTextsPos1?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null, customReceiptTextsPos2?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null } };

export type UpdateMarketMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateMarketInput;
}>;


export type UpdateMarketMutation = { __typename?: 'Mutation', updateMarket: { __typename?: 'MarketEntity', id: number, invoiceEmail: string, deliverySlipEmail: string, contactPhone: string, customerNumber: number, billToGLN?: string | null, deliverToGLN?: string | null, isDirectInvoice: boolean, marketName: string, notes?: string | null, operator?: string | null, createdAt?: any | null, updatedAt?: any | null, billingAddress?: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string } | null, deliveryAddress: { __typename?: 'AddressEmbeddable', street: string, houseNumber: string, postalCode: string, city: string, country: string }, retailer: { __typename?: 'RetailerEntity', id: number }, customReceiptTextsPos1?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null, customReceiptTextsPos2?: Array<{ __typename?: 'KeyValueObject', key: string, value: string }> | null } };

export const MarketFieldsFragmentDoc = gql`
    fragment MarketFields on MarketEntity {
  id
  billingAddress {
    ...AddressFields
  }
  deliveryAddress {
    ...AddressFields
  }
  retailer {
    id
  }
  invoiceEmail
  deliverySlipEmail
  contactPhone
  customerNumber
  billToGLN
  deliverToGLN
  isDirectInvoice
  marketName
  notes
  operator
  createdAt
  updatedAt
  customReceiptTextsPos1 {
    key
    value
  }
  customReceiptTextsPos2 {
    key
    value
  }
}
    ${AddressFieldsFragmentDoc}`;
export const GetAllMarketsDocument = gql`
    query GetAllMarkets {
  markets {
    ...MarketFields
  }
}
    ${MarketFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllMarketsService extends Apollo.Query<GetAllMarketsQuery, GetAllMarketsQueryVariables> {
    override document = GetAllMarketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMarketDocument = gql`
    mutation CreateMarket($input: CreateMarketInput!) {
  createMarket(createMarketInput: $input) {
    ...MarketFields
  }
}
    ${MarketFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMarketService extends Apollo.Mutation<CreateMarketMutation, CreateMarketMutationVariables> {
    override document = CreateMarketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMarketDocument = gql`
    mutation UpdateMarket($id: Int!, $input: UpdateMarketInput!) {
  updateMarket(id: $id, updateMarketInput: $input) {
    ...MarketFields
  }
}
    ${MarketFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMarketService extends Apollo.Mutation<UpdateMarketMutation, UpdateMarketMutationVariables> {
    override document = UpdateMarketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }