import { Selector } from '@ngxs/store';
import { DeliveryType, IDelivery, IDeliveryProduct, IProduct, IUser } from '@prf/shared/domain';
import { DeliveriesState } from '../state/entities/deliveries.state';
import { UsersState } from '../state/entities/users.state';
import { CalendarWeekInfo, DeliveryPlanningNewWeekDialogUiState, UiState } from '../state/ui.state';
import * as dayjs from 'dayjs';
import {
  PickListPlanningDelivery,
  PickListPlanningState,
  ProductQuantityChange,
} from '../shared/components/delivery/plan-new-delivery-week-dialog/plan-new-delivery-week-dialog.component';
import { ProductsState } from '../state/entities/products.state';

export interface WeekPlanningOtherGroup {
  title: string; // ie Inaktive Lieferungen
  deliveries: IDelivery[];
}

export interface WeekPlanningWeekdayGroup {
  title: string; // ie MONTAG
  sourceDate: string; // 'YYYY-MM-DD'
  sourceDeliveries: PickListPlanningDelivery[];
  targetDate: string; // 'YYYY-MM-DD'
  targetDeliveries: PickListPlanningDelivery[];
}

export interface ProductQuantityInfo {
  product: IProduct;
  relativeChangeAll: number; // Initial value. Gets overriden by UI changes.
  totalAmount: number;
  totalAmountWithRelativeChange: number;
}

export interface DeliveryPlanningNewWeekDialogViewModel {
  sourceWeekInfo: CalendarWeekInfo;
  targetWeekInfo: CalendarWeekInfo;

  // These groups and their deliveries are derived from the source/target calendarWeeks.
  otherGroups: WeekPlanningOtherGroup[];
  weekdayGroups: WeekPlanningWeekdayGroup[];

  // Derived from weekdayGroups.
  plannedTargetWeekDeliveries: IDelivery[];
  newProductQuantitiesForPlannedTargetWeekDeliveries: Map<
    number,
    Map<number, ProductQuantityChange>
  >;

  selectedDetailsDelivery: IDelivery | null;

  productQuantities: ProductQuantityInfo[];
  productQuantitiesTotalAmount: number; // Initial sum value. Gets overriden by UI changes.

  validations: {
    areSourceAndTargetWeekEqual: boolean;
  };
}

export class DeliveryPlanningNewWeekDialogModelQueries {
  @Selector([
    DeliveriesState.items,
    UsersState.drivers,
    ProductsState.items,
    UiState.deliveryPlanningNewWeekState,
  ])
  static getViewModel(
    deliveries: IDelivery[],
    drivers: IUser[],
    products: IProduct[],
    deliveryPlanningNewWeekState: DeliveryPlanningNewWeekDialogUiState,
  ): DeliveryPlanningNewWeekDialogViewModel {
    const { sourceWeekInfo, targetWeekInfo } = deliveryPlanningNewWeekState;

    const primaryDeliveries = deliveries.filter(
      (delivery) => delivery.type === DeliveryType.PRIMARY,
    );

    // Extracting date ranges from week info
    const sourceWeekFromDate = sourceWeekInfo.rangeDates ? sourceWeekInfo.rangeDates[0] : null;
    const sourceWeekToDate = sourceWeekInfo.rangeDates ? sourceWeekInfo.rangeDates[1] : null;
    const targetWeekFromDate = targetWeekInfo.rangeDates ? targetWeekInfo.rangeDates[0] : null;
    const targetWeekToDate = targetWeekInfo.rangeDates ? targetWeekInfo.rangeDates[1] : null;

    // Filter for inactive deliveries
    const inactiveDeliveries = primaryDeliveries.filter((delivery) => !delivery.isActive);

    // TODO: More filtering needed. Otherwise, "all other active" deliveries will end up here.
    // Filter for active deliveries outside the range of source and target weeks
    const activeDeliveriesOutsideTargetWeek = primaryDeliveries // TODO: Get deliveries via request, as soon as not all deliveries are loaded into the client anymore.
      .filter(
        (delivery) =>
          delivery.isActive &&
          !dayjs(delivery.deliveryDate).isBetween(
            sourceWeekFromDate,
            sourceWeekToDate,
            'day',
            '[]',
          ) &&
          !dayjs(delivery.deliveryDate).isBetween(
            targetWeekFromDate,
            targetWeekToDate,
            'day',
            '[]',
          ),
      );

    // TODO: is handling for NEW/COPIED -> set to inactive | deliveries necessary? ie show copied/transferred delivs inside INACTIVE/otherGroup?
    // TODO: check non-null assertion here
    const weekdayGroups = this.createWeekdayGroups(
      sourceWeekFromDate!,
      sourceWeekToDate!,
      targetWeekFromDate!,
      primaryDeliveries.filter((delivery) => delivery.isActive),
      deliveryPlanningNewWeekState.transferredTargetWeekDeliveries,
    );

    const plannedTargetWeekDeliveries = [
      ...weekdayGroups.flatMap((group) => group.targetDeliveries),
    ];

    const productQuantitiesMap = new Map<number, number>();
    const newProductQuantitiesForPlannedTargetWeekDeliveries = new Map<
      number,
      Map<number, ProductQuantityChange>
    >();

    plannedTargetWeekDeliveries.forEach((delivery) => {
      const deliveryProductChangeMap = new Map<number, ProductQuantityChange>();
      products.forEach((product) => {
        // TODO: check newTargetQuantity 0 vs null
        deliveryProductChangeMap.set(product.id, { relativeChange: 0, newTargetQuantity: null });
      });
      newProductQuantitiesForPlannedTargetWeekDeliveries.set(delivery.id, deliveryProductChangeMap);
      delivery.deliveryProducts?.forEach((deliveryProduct: IDeliveryProduct) => {
        // TODO: check why IDeliveryProduct has productId: null | ...
        const currentQuantity = productQuantitiesMap.get(deliveryProduct.productId!) || 0;
        productQuantitiesMap.set(
          deliveryProduct.productId!,
          currentQuantity + (deliveryProduct.targetQuantity || 0),
        );
      });
    });
    let productQuantitiesTotalAmount = 0;
    if (productQuantitiesMap.size > 0) {
      productQuantitiesTotalAmount = Array.from(productQuantitiesMap.values()).reduce(
        (total, quantity) => total + quantity,
        0,
      );
    }

    return {
      sourceWeekInfo: { ...sourceWeekInfo },
      targetWeekInfo: { ...targetWeekInfo },

      otherGroups: [
        {
          title: `Inaktive Lieferungen (${inactiveDeliveries.length} Lieferungen)`,
          deliveries: inactiveDeliveries,
        },
        {
          title: `Aktive Lieferungen, die außerhalb der Ausgangs/Zielwoche liegen (${activeDeliveriesOutsideTargetWeek.length} Lieferungen)`,
          deliveries: activeDeliveriesOutsideTargetWeek,
        },
      ],
      weekdayGroups: weekdayGroups,

      plannedTargetWeekDeliveries: plannedTargetWeekDeliveries,

      selectedDetailsDelivery: deliveryPlanningNewWeekState.selectedDetailsDelivery,

      // TODO: Check for live updates (ie from other browser/db). Reload quantities?
      productQuantities: products.map((product: IProduct) => ({
        product,
        relativeChangeAll: 0,
        totalAmount: productQuantitiesMap.get(product.id) || 0,

        // currently abused as local variable "structure":
        totalAmountWithRelativeChange: productQuantitiesMap.get(product.id) || 0,
      })),
      productQuantitiesTotalAmount: productQuantitiesTotalAmount,

      newProductQuantitiesForPlannedTargetWeekDeliveries:
        newProductQuantitiesForPlannedTargetWeekDeliveries,

      validations: {
        areSourceAndTargetWeekEqual:
          sourceWeekInfo.calendarWeekNumber === targetWeekInfo.calendarWeekNumber,
      },
    };
  }

  private static createWeekdayGroups(
    sourceWeekStartDate: Date,
    sourceWeekEndDate: Date,
    targetWeekStartDate: Date,
    deliveries: IDelivery[],
    transferredTargetWeekDeliveries: IDelivery[],
  ): WeekPlanningWeekdayGroup[] {
    const extendDeliveryWithPlanningState = (
      delivery: IDelivery,
      planningOrigin: PickListPlanningState,
    ): PickListPlanningDelivery => {
      return {
        ...delivery,
        planningOrigin: planningOrigin,
        currentPlanningState: planningOrigin,
        isNewDelivery: Boolean((delivery as PickListPlanningDelivery).isNewDelivery),
      };
    };

    const days = [];
    let loopDate = dayjs(sourceWeekStartDate);
    const targetWeekStart = dayjs(targetWeekStartDate);

    while (
      loopDate.isBefore(sourceWeekEndDate, 'day') ||
      loopDate.isSame(sourceWeekEndDate, 'day')
    ) {
      // Calculate the difference in days from the start of the source week.
      const dayDifference = loopDate.diff(dayjs(sourceWeekStartDate), 'day');
      // Find the corresponding date in the target week.
      const targetDate = targetWeekStart.add(dayDifference, 'day');

      days.push({
        title: loopDate.format('dddd'),

        sourceDate: loopDate.format('YYYY-MM-DD'), // TODO: Extract global perfo date string format constant.
        sourceDeliveries: deliveries
          // Filter out already transferred deliveries ("copies" reside in the target week groups).
          .filter(
            (delivery) =>
              !transferredTargetWeekDeliveries.some(
                (transferredDelivery) => transferredDelivery.id === delivery.id,
              ),
          )
          .filter((delivery) => dayjs(delivery.deliveryDate).isSame(loopDate, 'day'))
          .map((delivery) => extendDeliveryWithPlanningState(delivery, 'SOURCE_WEEK')),

        targetDate: targetDate.format('YYYY-MM-DD'),
        targetDeliveries: [...transferredTargetWeekDeliveries, ...deliveries]
          .filter((delivery) => dayjs(delivery.deliveryDate).isSame(targetDate, 'day'))
          .map((delivery) => extendDeliveryWithPlanningState(delivery, 'TARGET_WEEK')),
      });

      loopDate = loopDate.add(1, 'day');
    }
    return days;
  }
}

// target deliveries can
// 1) be pre-scheduled (not copied)
// 2) copied from source
// only deliveries 2) need to be copied, created new in DB.
// deliveries 1) are already existing in the DB

// -----------------------------------------------------

// Szenario:
//
// # Schritt 1
// Ausgangs/Zielwoche auswählen.
// ViewModel wird gefüllt mit entsprechenden PRE-SCHEDULED Deliveries.
//
// # Schritt 2
// otherGroups: direktes Editieren von INAKTIV/Non-Planwochen -> Quelle wird angepasst
// - Frage: Wird durch dieses Editieren direkt source/target-groups/delivs angepasst?
// 	[ ] TESTEN
//
// weekDayGroups: via Picklist wird von source week nach targetweek verschoben.
// Dabei ändert sich an der Delivery selbst nichts. Nur der Kontext weiß, was die targetWeek ist.
//
// <OFFEN>
// ? Es stellt sich die Frage, wie die target delivs abgelegt werden.
//   Oder existieren sie bereits? NEIN. Denn die gedraggten picklist target delivs sind nur Referenzen auf QuellSourceDelivs.
//   Lediglich pre-scheduled targetDelivs existieren bereits.
//
// # Schritt 3 - Fahrerzuweisung
// Sicht auf TARGETWEEK deliveries only. Hier können sowohl prescheduled, als auch copied sein.
// View: drag n drop zwischen Fahrern und deren nested Werktagen.
//
// # Schritt 4
// Mengen prozentual anpassen. Für alle Delivieries. Hier sollte der View egal sein, ob es neue oder DB Deliveries sind.
//
// # Schritt 5
// Mengen absolut anpassen. Für alle Delivieries. Hier sollte der View egal sein, ob es neue oder DB Deliveries sind.
//
// ABSCHLUSS, finaler Button:
// - targetDelivs, die noch nicht existieren, müssen in der DB angelegt werden.
// - deliveryProducts müssen angelegt werden.
//
//
//
// ### Große Fragen
// - wo liegt der targetweeks state (sowohl neu anzulegen, als auch prescheduled)
//    - DNF: es gibt UiState.deliveryPlanningNewWeekDialog - besides newDialog viewModel QUERIES
// - wie werden NEUE vs PRE-SCHEDULED target week deliveries geflaggt?
// - wie werden dabei insbesondere DeliveryProducts angelegt
//   ggf neues interface/object: "CopiedDelivery" mit Referenz auf alte Delivery, und ggf Flag
//   diese CopiedDelivery könnte später auch verwendet werden, um innerhalb von PreSCheduled DelivDetails ein KopierButton anzulegen.
//   diese CopiedDelivery muss ermöglichen, dass man ALLES lokal vordefinieren/bearbeiten/hinzufügen kann, um es dann gebündelt in die DB zu speichern via Mutation
//
// # Aufgaben
// - [x] InDialog DelivDetails side für direktes Editieren
//   - [x] Prüfen, wie sich dieses Editieren bei INAKTIVEN auswirkt auf source/target pick lists -> Funktioniert direkt korrekt, also mit Einordnung in Picklist
// - [x] Prüfen/Lösung für: PreScheduled in TargetWeek, DARF NICHT in SourceWeek verschoben werden (?).
// - [ ] DeliveryProducts für normale Lieferungen umsetzen (unabhängig von Planning)
