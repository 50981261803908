<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">
    <!--    required attr/flag for form validation via submit? check primeng docs-->

    <!--    TODO: DATUM ist Pflicht an einer Lieferung, aber Sonderlogik, wenn Lieferung INAKTIV ist. Siehe unten-->
    <div class="flex flex-row">
      <div class="p-field prf-field" style="flex-grow: 1;">
        <label for="deliveryDate">Lieferdatum</label>
        <input id="deliveryDate" type="date" [(ngModel)]="entity.deliveryDate" pInputText />
      </div>


      <div class="p-field prf-field ml-3 field-medium">
        <label for="deliverySlipNumber">Lieferschein-Nr</label>
        <input id="deliverySlipNumber"
               type="text"
               [ngModel]="entity.deliverySlipNumber"
               [disabled]="true"
               placeholder="Wird automatisch generiert"
               pInputText />
      </div>

      <div class="p-field">
        <div class="p-field prf-field flex flex-column ml-4">
          <label>
            Aktiv
          </label>
          <div class="text-center pt-2">
            <p-inputSwitch [(ngModel)]="entity.isActive"></p-inputSwitch>
          </div>
        </div>
      </div>
    </div>

    <div class="p-field prf-field">
      <label for="marketId">Markt</label>
      <prf-entity-dropdown id="marketId"
                           [entityType]="'market'"
                           [(ngModel)]="entity.marketId"
      />
    </div>
    <div class="p-field prf-field">
      <label for="driverId">Fahrer</label>
      <prf-entity-dropdown id="driverId"
                           [entityType]="'user'"
                           [filterFn]="dropdownOptionsFilterUserForDrivers"
                           [filterKey]="'drivers'"
                           [(ngModel)]="entity.driverId"
      />
    </div>
    <div class="p-field prf-field">
      <label for="type">Typ</label>
      <div class="card flex justify-content-center">
        <p-selectButton id="type"
                        [(ngModel)]="entity.type"
                        [options]="deliveryTypeOptions"
                        [allowEmpty]="false"
                        optionLabel="label"
                        optionValue="value">
        </p-selectButton>
      </div>
    </div>

    <div class="p-field prf-field">
      <label for="status">Status</label>
      <!-- NOTE: nicht mehr In Rechnung gestellt, da zu diesem Zeitpunkt noch nicht versendet! das passiert via Rechnungswesen-Ansicht-->
<!--      TODO: check status dropdown... currently disabled -->
      <p-dropdown id="status"
                  [(ngModel)]="entity.status"
                  [disabled]="true"
                  [options]="deliveryStatusOptions"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
    <div class="p-field prf-field">
      <label for="driverNote">Notiz für den Fahrer</label>
      <textarea id="driverNote" pInputTextarea [(ngModel)]="entity.driverNote" rows="4"></textarea>
    </div>
  </div>
</div>
<div class="flex justify-content-between">
  <p-button *ngIf="showClose"
            [outlined]="true"
            label="Schließen"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="clickClose.emit()"
  />
  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
            styleClass="p-button-primary"
            (click)="onClickSave()" />
</div>

<!-- TODO: speichern -> automatisch zur Lieferung wechseln und Lieferplanung öffnen  -->

<!--TODO: wenn lieferung angepasst+gespeichert wird, zB fahrer zugewiesen, werden die lokalen deliveryProducts gelöscht! -> deliveryproducts mit requesten/setzen? PRÜFEN-->
