<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">

    <div class="flex">
      <div class="p-field prf-field flex-grow-1">
        <label for="marketName">Marktname</label>
        <input id="marketName" type="text" [(ngModel)]="entity.marketName" pInputText />
      </div>
      <div class="p-field prf-field ml-3" [ngClass]="entity.customerNumber ? 'field-small' : 'field-medium'">
        <label for="customerNumber">Kundennummer</label>
        <input id="customerNumber"
               type="text"
               [ngModel]="entity.customerNumber"
               [disabled]="true"
               placeholder="Wird automatisch generiert"
               pInputText />
      </div>
    </div>
    <div class="flex flex-row">
      <div class="p-field prf-field flex-1">
        <label>Händler</label>
        <prf-entity-dropdown [entityType]="'retailer'"
                             [(ngModel)]="entity.retailerId"
        />
      </div>

      <div class="p-field prf-field flex flex-column ml-4">
        <label
          pTooltip="Legt fest, ob der Markt Rechnungsempfänger ist. Sofern deaktiviert, ist der Händler Rechnungsempfänger.">
          Direktgeschäft
          <i class="pi pi-info-circle"></i>
        </label>
        <div class="text-center pt-2">
          <p-inputSwitch [(ngModel)]="entity.isDirectInvoice" />
          <!--          TODO: on change, ie off->on ON SAVE -> confirm that direct invoice is changed-->
        </div>
      </div>
    </div>

    <div class="p-field prf-field">
      <label for="operator">Betreiber</label>
      <input id="operator" type="text" [(ngModel)]="entity.operator" pInputText />
    </div>

    <!--    <div class="p-field prf-field">-->
    <!--      <label>Abrechnung</label>-->
    <!--      TODO-->
    <!--      SPLIT -->
    <!--      info i: rechnung, rechnung+gutschrift-->
    <!--      <div>1 Beleg</div>-->
    <!--      <div>2 Belege</div>-->
    <!--    </div>-->

    <prf-address-input [address]="entity.billingAddress"
                       [disabled]="!entity.isDirectInvoice"
                       label="Rechnungsadresse" />

    <div class="p-field prf-field">
      <label for="billToGLN">
        Rechnungsempfänger-GLN
        <span *ngIf="!entity.isDirectInvoice && entity.billToGLN">
         - <i class="pi pi-info-circle"></i> Markt-RE-GLN überschreibt Händler-RE-GLN!
        </span>
      </label>
      <input id="billToGLN"
             type="text"
             [(ngModel)]="entity.billToGLN"
             [maxlength]="20"
             [placeholder]="entity.isDirectInvoice ? '' : 'Wird vom Händler übernommen'"
             pInputText />
    </div>

    <prf-address-input [address]="entity.deliveryAddress"
                       label="Lieferadresse" />

    <div class="p-field prf-field">
      <label for="deliverToGLN">Warenempfänger-GLN</label>
      <input id="deliverToGLN"
             type="text"
             [(ngModel)]="entity.deliverToGLN"
             [maxlength]="20"
             pInputText />
    </div>

    <div class="p-field prf-field">
      <label for="phone">Telefon</label>
      <span class="p-input-icon-left">
        <i class="pi pi-phone"></i>
        <input id="phone" type="text" [(ngModel)]="entity.contactPhone" pInputText />
      </span>
    </div>

    <div class="p-field prf-field">
      <label for="invoiceEmail">E-Mail (Rechnungsversand)</label>
      <span class="p-input-icon-left">
        <i class="pi pi-envelope"></i>
        <input id="invoiceEmail" type="text" [(ngModel)]="entity.invoiceEmail" pInputText />
      </span>
    </div>

    <div class="p-field prf-field">
      <label for="deliverySlipEmail">E-Mail (Lieferschein)</label>
      <span class="p-input-icon-left">
        <i class="pi pi-envelope"></i>
        <input id="deliverySlipEmail" type="text" [(ngModel)]="entity.deliverySlipEmail" pInputText />
      </span>
    </div>

    <fieldset>
      <legend>Belegfreitexte für Beleg-Position 1</legend>
      <div class="flex" *ngFor="let customReceiptText of entity.customReceiptTextsPos1; let index = index;">
        <div class="p-field prf-field">
          <label for="custom-receipt-text-pos-1-item-{{index}}-key">Titel</label>
          <input id="custom-receipt-text-pos-1-item-{{index}}-key"
                 type="text"
                 [(ngModel)]="customReceiptText.key"
                 pInputText />
        </div>
        <div class="p-field prf-field ml-3">
          <label for="custom-receipt-text-pos-1-item-{{index}}-value">Wert</label>
          <input id="custom-receipt-text-pos-1-item-{{index}}-value"
                 type="text"
                 [(ngModel)]="customReceiptText.value"
                 pInputText />
        </div>
        <div class="p-field prf-field ml-3" style="flex-basis: 30px;">
          <label style="visibility: hidden;">X</label>
          <p-button icon="pi pi-trash"
                    styleClass="p-button-secondary p-button-danger p-button-text"
                    (click)="onClickDeleteCustomReceiptTextPos1(customReceiptText)"
          />
        </div>
      </div>
      <p-button label="+ Freitext hinzufügen"
                styleClass="p-button-secondary"
                size="small"
                [style]="{height: '34px'}"
                (click)="onClickAddCustomReceiptTextPos1()">
      </p-button>
    </fieldset>

    <fieldset>
      <legend>Belegfreitexte für Beleg-Position 2</legend>
      <div class="flex" *ngFor="let customReceiptText of entity.customReceiptTextsPos2; let index = index;">
        <div class="p-field prf-field">
          <label for="custom-receipt-text-pos-2-item-{{index}}-key">Titel</label>
          <input id="custom-receipt-text-pos-2-item-{{index}}-key"
                 type="text"
                 [(ngModel)]="customReceiptText.key"
                 pInputText />
        </div>
        <div class="p-field prf-field ml-3">
          <label for="custom-receipt-text-pos-2-item-{{index}}-value">Wert</label>
          <input id="custom-receipt-text-pos-2-item-{{index}}-value"
                 type="text"
                 [(ngModel)]="customReceiptText.value"
                 pInputText />
        </div>
        <div class="p-field prf-field ml-3" style="flex-basis: 30px;">
          <label style="visibility: hidden;">X</label>
          <p-button icon="pi pi-trash"
                    styleClass="p-button-secondary p-button-danger p-button-text"
                    (click)="onClickDeleteCustomReceiptTextPos2(customReceiptText)"
          />
        </div>
      </div>
      <p-button label="+ Freitext hinzufügen"
                styleClass="p-button-secondary"
                size="small"
                [style]="{height: '34px'}"
                (click)="onClickAddCustomReceiptTextPos2()">
      </p-button>
    </fieldset>

    <div class="p-field prf-field">
      <label for="notes">Notizen (wiederkehrende Markt-Notizen für Fahrer)</label>
      <textarea id="notes" pInputTextarea
                [(ngModel)]="entity.notes"
                [autoResize]="true"
                rows="3"></textarea>
    </div>
  </div>
</div>

<div class="flex">
  <div class="flex justify-content-start ml-2 flex-grow-1">
    <p-button *ngIf="showClose"
              [outlined]="true"
              label="Schließen"
              icon="pi pi-times"
              styleClass="p-button-secondary"
              (click)="clickClose.emit()"
    />
  </div>
  <div class="flex justify-content-end ml-2">
    <p-button [label]="'Neue Lieferung anlegen'"
              [disabled]="true"
              styleClass="p-button-primary"
    />
    <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
              styleClass="p-button-primary ml-2"
              (click)="onClickSave()" />
  </div>
</div>
