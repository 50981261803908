import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IIngredient } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { IngredientsTableConfig } from './table.config';
import { Observable, take } from "rxjs";
import {
  IngredientsPageViewModel,
  IngredientsPageViewModelQueries,
} from '../../view-models/ingredient-view-model.queries';
import { RetailerDetailsComponent } from '../../shared/components/retailer/retailer-details/retailer-details.component';
import { IngredientDetailsComponent } from '../../shared/components/ingredient/ingredient-details/ingredient-details.component';
import { UpdateIngredient } from '../../state/entities/ingredients.actions';
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";

@Component({
  selector: 'prf-markets-view',
  standalone: true,
  imports: [
    // TODO: Extract shared module... view module
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    RetailerDetailsComponent,
    IngredientDetailsComponent,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './ingredients-view.component.html',
  styleUrls: ['./ingredients-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngredientsViewComponent {
  public TableConfig = IngredientsTableConfig;

  selectedEntity: IIngredient | null = null;
  loading = false;
  updateInProgress = false;

  @Select(IngredientsPageViewModelQueries.getViewModel)
  viewModel$!: Observable<IngredientsPageViewModel>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    this.selectedEntity = $event as IIngredient;
  }

  handleUpdateEntity(entity: IIngredient) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateIngredient({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }
}
