import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableFilterEvent, TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IDelivery, IEntity } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { DeliveriesTableConfig } from './table.config';
import { TabViewModule } from 'primeng/tabview';
import { Observable, take } from 'rxjs';
import { LoadDelivery, UpdateDelivery } from '../../state/entities/deliveries.actions';
import {
  DeliveriesPageViewModel,
  DeliveriesPageViewModelQueries,
} from '../../view-models/delivery-view-model.queries';
import { DeliveryProductsEditorComponent } from './components/delivery-products-editor/delivery-products-editor.component';
import { DeliveryDeliveredViewComponent } from './components/delivery-delivered-view/delivery-delivered-view.component';
import { DeliveryDetailsComponent } from '../../shared/components/delivery/delivery-details/delivery-details.component';
import { CalendarModule } from 'primeng/calendar';
import {
  OpenCompactDeliverySlipsDocumentCollection,
  OpenDeliverySlipsDocumentCollection,
  SetDeliveriesSelectedDetailsDelivery,
  SetDeliveriesSelectedDetailsDeliveryIsUpdating,
  SetDeliveriesTableRangeDates,
} from '../../state/ui.actions';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ToggleEntityDetailsExpandDirective } from '../../shared/directives/toggle-entity-details-expand.directive';

type LocalModel = IDelivery;

@Component({
  selector: 'prf-deliveries-view',
  standalone: true,
  imports: [
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    PanelModule,
    DataTableComponent,
    TabViewModule,
    DeliveryDetailsComponent,
    DeliveryProductsEditorComponent,
    DeliveryDeliveredViewComponent,
    CalendarModule,
    ProgressBarModule,
    ScrollPanelModule,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './deliveries-view.component.html',
  styleUrls: ['./deliveries-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveriesViewComponent {
  TableConfig = DeliveriesTableConfig;

  @Select(DeliveriesPageViewModelQueries.getViewModel)
  viewModel$!: Observable<DeliveriesPageViewModel>;

  loading = false;
  updateInProgress = false;

  filteredRows: LocalModel[] = []; // Used for creating compactDeliverySlips for visible delivery rows.

  compactDeliverySlipCollectionExportInProgress = false;
  deliverySlipCollectionExportInProgress = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange(selectedDelivery: IEntity | null): void {
    // within that new action: trigger requesting ID details data, including deliveryProducts
    // TODO: add updating/progress boolean flag to indicate update inside details
    this.store.dispatch(
      new SetDeliveriesSelectedDetailsDelivery({
        // TODO: Check if nullish coalescing ?? should be used here
        deliveryId: (selectedDelivery as IDelivery)?.id || null,
      }),
    );

    if (selectedDelivery) {
      this.store
        .dispatch([
          new SetDeliveriesSelectedDetailsDeliveryIsUpdating({ isUpdating: true }),
          new LoadDelivery({ deliveryId: selectedDelivery.id }),
        ])
        .pipe(take(1))
        .subscribe((result) => {
          this.store.dispatch(
            new SetDeliveriesSelectedDetailsDeliveryIsUpdating({ isUpdating: false }),
          );
          // this.cdRef.detectChanges();
        });
    }
  }

  handleUpdateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateDelivery({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }

  onTableRangeDatesSelect($event: Date[] | undefined) {
    this.store.dispatch(new SetDeliveriesTableRangeDates({ rangeDates: $event }));
  }

  onClickCloseDeliveryDetails() {
    this.store.dispatch(
      new SetDeliveriesSelectedDetailsDelivery({
        deliveryId: null,
      }),
    );
  }

  onClickOpenCompactDeliverySlipsCollection(allTableDeliveries: IDelivery[]): void {
    this.compactDeliverySlipCollectionExportInProgress = true;

    // Fallback to allTableDeliveries, in case no filtering has been done yet.
    let deliveriesCollection =
      this.filteredRows.length === 0 ? allTableDeliveries : this.filteredRows;
    deliveriesCollection = deliveriesCollection.filter((delivery) => delivery.isActive);

    this.store
      .dispatch(
        new OpenCompactDeliverySlipsDocumentCollection({ deliveries: deliveriesCollection }),
      )
      .pipe(take(1))
      .subscribe((_res) => {
        this.compactDeliverySlipCollectionExportInProgress = false;
        this.cdRef.detectChanges();
      });
  }

  onClickOpenDeliverySlipsCollection(allTableDeliveries: IDelivery[]): void {
    this.deliverySlipCollectionExportInProgress = true;

    // Fallback to allTableDeliveries, in case no filtering has been done yet.
    let deliveriesCollection =
      this.filteredRows.length === 0 ? allTableDeliveries : this.filteredRows;
    deliveriesCollection = deliveriesCollection.filter((delivery) => delivery.isActive);

    this.store
      .dispatch(new OpenDeliverySlipsDocumentCollection({ deliveries: deliveriesCollection }))
      .pipe(take(1))
      .subscribe((_res) => {
        this.deliverySlipCollectionExportInProgress = false;
        this.cdRef.detectChanges();
      });
  }

  onTableFilter($event: TableFilterEvent): void {
    // Keeps filtered rows variable in sync with table component.
    this.filteredRows = $event.filteredValue;
  }
}
