import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { FormsModule } from '@angular/forms';
import { IEntity, IUser } from '@prf/shared/domain';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { SplitterModule } from 'primeng/splitter';
import { PanelModule } from 'primeng/panel';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { Select, Store } from '@ngxs/store';
import { Observable, take } from "rxjs";
import { UsersTableConfig } from './table.config';
import { UpdateUser } from '../../state/entities/users.actions';
import {
  UsersPageViewModel,
  UsersPageViewModelQueries,
} from '../../view-models/user-view-model.queries';
import { UserDetailsComponent } from '../../shared/components/user/user-details/user-details.component';
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";

type LocalModel = IUser;

@Component({
  selector: 'prf-users-view',
  standalone: true,
  imports: [
    // TODO: Extract shared module... view module
    CommonModule,
    TagModule,
    DropdownModule,
    TableModule,
    MultiSelectModule,
    ChipsModule,
    FormsModule,
    RippleModule,
    ButtonModule,
    TooltipModule,
    SplitterModule,
    PanelModule,
    DataTableComponent,
    UserDetailsComponent,
    ToggleEntityDetailsExpandDirective,
  ],
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersViewComponent {
  public TableConfig = UsersTableConfig;

  selectedEntity: LocalModel | null = null;
  loading = false;
  updateInProgress = false;

  @Select(UsersPageViewModelQueries.getViewModel)
  viewModel$!: Observable<UsersPageViewModel>;

  constructor(
    private cdRef: ChangeDetectorRef,
    private store: Store,
  ) {}

  onEntitySelectionChange($event: IEntity | null): void {
    this.selectedEntity = $event as LocalModel;
  }

  handleUpdateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new UpdateUser({ entity: entity }))
      .pipe(take(1))
      .subscribe((result: any) => {
        this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }
}
