import { Route } from '@angular/router';
import { MainLayoutComponent } from './main/_layout/components/main-layout.component';
import { getRouteTitle } from './main/main.routes';
import { AuthGuard } from "./shared/guards/auth.guard";

export const APP_ROUTES: Route[] = [
  {
    path: 'login',
    title: getRouteTitle('Login'),
    pathMatch: 'full',
    loadComponent: () =>
      import('./login/login-page.component').then((m) => m.LoginPageComponent),
  },
  {
    path: '',
    loadChildren: () => import('./main/main.routes').then((m) => m.MAIN_ROUTES),
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: '' },
];
