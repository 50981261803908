import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IVehicle } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputMaskModule } from 'primeng/inputmask';

type LocalModel = IVehicle;

@Component({
  selector: 'prf-vehicle-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    RadioButtonModule,
    InputMaskModule,
  ],
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  // updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: IProduct;
  private _entity!: LocalModel;

  @Input() set entity(entity: LocalModel) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): LocalModel {
    return this._entity;
  }

  @Output()
  entityChange = new EventEmitter<LocalModel>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    this.entityChange.emit(this.entity);
  }
}
