import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IRetailer } from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { CreateRetailer } from '../../../../state/entities/retailers.actions';
import { RetailerDetailsComponent } from "../retailer-details/retailer-details.component";
import { take } from "rxjs";

@Component({
  selector: 'prf-retailer-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    RetailerDetailsComponent,
  ],
  templateUrl: './retailer-details-dialog.component.html',
  styleUrls: ['./retailer-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetailerDetailsDialogComponent {
  entity: IRetailer;
  updateInProgress = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private store: Store
  ) {
    this.entity = this.dialogConfig.data.entity;
  }

  handleCreateEntity(retailer: IRetailer) {
    this.updateInProgress = true;
    this.store
      .dispatch(new CreateRetailer({ entity: retailer }))
      .pipe(take(1))
      .subscribe((result) => {
        this.updateInProgress = false;
        this.dialogRef.close();
      });
  }
}
