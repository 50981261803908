import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  EUserRole,
  EUserStatus,
  IUser,
  PerfoEntityType,
} from '@prf/shared/domain';
import { CreateUser, LoadUsers, UpdateUser } from './users.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateUserMutationVariables,
  CreateUserService,
  GetAllUsersService,
  UpdateUserService,
  UserFieldsFragment,
} from '../../graphql/users-operations.generated';
import { BaseEntityState } from './base.state';
import { UpdateUserInput } from '../../graphql/_types.generated';

type LocalModel = IUser;
type LocalStateModel = UsersStateModel;
type LocalStateContext = StateContext<UsersStateModel>;

export interface UsersStateModel {
  items: IUser[];
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    items: [],
  },
})
@Injectable()
export class UsersState extends BaseEntityState<
  {
    fetchAll: GetAllUsersService;
    create: CreateUserService;
    update: UpdateUserService;
  },
  IUser
> {
  protected entityType: PerfoEntityType = 'user';

  constructor(
    protected getAllUsersService: GetAllUsersService,
    protected createUserService: CreateUserService,
    protected updateUserService: UpdateUserService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllUsersService,
        create: createUserService,
        update: updateUserService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadUsers();
  }

  protected mapGqlEntityToUiEntity(entity: UserFieldsFragment): IUser {
    return {
      id: entity.id,
      username: entity.username,
      firstName: entity.firstName,
      lastName: entity.lastName,
      role: EUserRole[entity.role as keyof typeof EUserRole],
      status: EUserStatus[entity.status as keyof typeof EUserStatus],
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Selector()
  static drivers(state: LocalStateModel): LocalModel[] {
    return state.items.filter((user) => user.role === EUserRole.DRIVER);
  }

  @Action(LoadUsers)
  protected loadUsers(
    ctx: LocalStateContext,
    action: LoadUsers,
  ): Observable<any> {
    return this.loadEntities<UserFieldsFragment[]>(ctx, 'users');
  }

  @Action(CreateUser)
  createUser(
    ctx: LocalStateContext,
    action: CreateUser,
  ): Observable<any> {
    return this.createEntity<CreateUserMutationVariables>(
      ctx,
      action.payload.entity,
      'createUser',
    );
  }

  @Action(UpdateUser)
  updateUser(ctx: LocalStateContext, action: UpdateUser): Observable<any> {
    return this.updateEntity<UpdateUserInput>(
      ctx,
      action.payload.entity,
      'updateUser',
    );
  }
}
