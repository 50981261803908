import { DeliveryDragGroupingType } from '../view-models/delivery-planning-draggable-week-overview-view-model.queries';
import { DeliveryPlanningWeekType } from './ui.state';
import { IDelivery, IInvoice } from '@prf/shared/domain';

export class InitDynamicUiState {
  static readonly type = '[UI] Init UI State';
  constructor() {}
}

// TODO: Refactor: Find better place for this action, or this concept in general.
export class LoadAllEntities {
  static readonly type = '[UI|Global|Entities] Load All Entities';
  constructor() {}
}

export class OpenSidebar {
  static readonly type = '[UI] Open Sidebar';
  constructor() {}
}

export class CloseSidebar {
  static readonly type = '[UI] Close Sidebar';
  constructor() {}
}

export class ToggleSidebar {
  static readonly type = '[UI] Toggle Sidebar';
  constructor() {}
}

export class ShowPlanNewDeliveryWeekDialog {
  static type = '[UI] Show Plan New DeliveryWeek Dialog';
  constructor() {}
}

export class SetDeliveryPlanningDragGroupingType {
  static type = '[UI|DeliveryPlanningOverview] Set DeliveryPlanningOverview DragGroupingType';
  constructor(public payload: {dragGroupingType: DeliveryDragGroupingType}) {}
}

export class SetDeliveryPlanningOverviewSelectedCalendarWeek {
  static type = '[UI|DeliveryPlanningOverview] Set DeliveryPlanningOverview SelectedCalendarWeek';
  constructor(public payload: {weekNumber: number}) {}
}

export class SetDeliveryPlanningOverviewSelectedDelivery {
  static readonly type = '[UI|DeliveryPlanningOverview] Set SelectedDetailsDelivery';
  constructor(public payload: { selectedDetailsDelivery: IDelivery | null }) {}
}

export class ClearDeliveryPlanningOverviewSelectedDelivery {
  static readonly type = '[UI|DeliveryPlanningOverview] Clear SelectedDetailsDelivery';
  constructor() {}
}

export class SetNewWeekPlanningCalendarWeek {
  static readonly type = '[UI|DeliveryPlanningNewWeek] Set New Week Planning Calendar Week';
  constructor(public payload: { weekType: DeliveryPlanningWeekType; date: Date }) {}
}

export class OpenNewWeekPlanningDialog {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Open New Week Planning Dialog';
}

export class CloseNewWeekPlanningDialog {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Close New Week Planning Dialog';
}

export class SetDeliveryPlanningNewWeekDialogSelectedDelivery {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Set SelectedDetailsDelivery';
  constructor(public payload: { selectedDetailsDelivery: IDelivery | null }) {}
}

export class ClearDeliveryPlanningNewWeekDialogSelectedDelivery {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Clear SelectedDetailsDelivery';
  constructor() {}
}

export class SetInvoicesTableRangeDates {
  static type = '[UI|Invoices] Set Invoices TableRangeDates';
  constructor(public payload: {rangeDates: Date[] | undefined}) {}
}

export class SetDeliveriesTableRangeDates {
  static type = '[UI|Deliveries] Set Deliveries TableRangeDates';
  constructor(public payload: {rangeDates: Date[] | undefined}) {}
}

export class OpenDeliverySlipDocument {
  static readonly type = '[UI|Deliveries] Open Delivery Slip Document';
  constructor(public payload: { delivery: IDelivery } ) {}
}

export class OpenInvoiceDocument {
  static readonly type = '[UI|Deliveries] Open Invoice Document';
  constructor(public payload: { delivery: IDelivery } ) {}
}

// TODO: Check for naming... open* ?
export class GetPersistedInvoicePdf {
  static readonly type = '[Invoice] Get Persisted Invoice PDF';
  constructor(public payload: { invoice: IInvoice }) {}
}

export class OpenCompactDeliverySlipDocument {
  static readonly type = '[UI|Deliveries] Open Compact Delivery Slip Document';
  constructor(public payload: { delivery: IDelivery } ) {}
}

export class OpenCompactDeliverySlipsDocumentCollection {
  static readonly type = '[UI|Deliveries] Open Compact Delivery Slips Document Collection';
  constructor(public payload: { deliveries: IDelivery[] } ) {}
}

export class OpenDeliverySlipsDocumentCollection {
  static readonly type = '[UI|Deliveries] Open Delivery Slips Document Collection';
  constructor(public payload: { deliveries: IDelivery[] } ) {}
}

export class SetDeliveriesSelectedDetailsDelivery {
  static readonly type = '[UI|Deliveries] Set SelectedDetailsDeliveryState';
  constructor(public payload: { deliveryId: number | null } ) {}
}

export class SetDeliveriesSelectedDetailsDeliveryIsUpdating {
  static readonly type = '[UI|Deliveries] Set SelectedDetailsDeliveryIsUpdatingState';
  constructor(public payload: { isUpdating: boolean } ) {}
}

// TODO: move into deliveries.action.ts
export class SetDeliveryManuallyAsDelivered {
  static readonly type = '[UI|Deliveries] Set Delivery Manually As Delivered';
  constructor(public payload: { delivery: IDelivery }) {}
}

// TODO: move into deliveries.action.ts
export class SetDeliveryAsCompleted {
  static readonly type = '[UI|Deliveries] Set Delivery As Completed';
  constructor(public payload: { delivery: IDelivery }) {}
}

export class AddDeliveryToTransferredTargetWeek {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Add Delivery to Transferred Target Week';
  constructor(public payload: { delivery: IDelivery }) {}
}

export class UpdateTransferredTargetWeekDelivery {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Update Transferred Target Week Delivery';

  constructor(public payload: { updatedDelivery: IDelivery }) {}
}

export class RemoveDeliveryFromTransferredTargetWeek {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Remove Delivery from Transferred Target Week';
  constructor(public payload: { deliveryId: number }) {}
}

export class ClearAllTransferredTargetWeekDeliveries {
  static readonly type = '[UI|DeliveryPlanningNewWeekDialog] Clear All Transferred Target Week Deliveries';
}

export class LogoutUser {
  static readonly type = '[UI|App] Logout User';
  constructor(public payload?: {skipLogoutRequest: boolean}) {}
}
