import { Selector } from '@ngxs/store';
import { IUser } from '@prf/shared/domain';
import { UsersState } from '../state/entities/users.state';

export interface UsersPageViewModel {
  users: IUser[];
}

export class UsersPageViewModelQueries {
  @Selector([UsersState.items])
  static getViewModel(users: IUser[]): UsersPageViewModel {
    return {
      users: [...users]
    };
  }
}
