import { CalendarWeekOption } from './ui.state';
import * as dayjs from 'dayjs';

export function buildCurrentCalendarWeekOptions(
  numOfWeeks: number,
  weekOffset: number = 0,
): CalendarWeekOption[] {
  const weeks: CalendarWeekOption[] = [];

  // Set the starting date to Monday of the current week with the weekOffset applied
  let currentWeekStartDay = dayjs().startOf('isoWeek').add(weekOffset, 'week');

  for (let i = 0; i < numOfWeeks; i++) {
    const week: CalendarWeekOption = {
      weekNumber: currentWeekStartDay.isoWeek(),
      fromDate: currentWeekStartDay.format('YYYY-MM-DD'),
      toDate: currentWeekStartDay.endOf('isoWeek').format('YYYY-MM-DD'),
      formattedFromDate: currentWeekStartDay.format('dd. DD.MM.YYYY'),
      formattedToDate: currentWeekStartDay.endOf('isoWeek').format('dd. DD.MM.YYYY'),
    };

    weeks.push(week);
    currentWeekStartDay = currentWeekStartDay.add(1, 'week');
  }

  return weeks;
}
