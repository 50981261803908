import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type IngredientFieldsFragment = { __typename?: 'IngredientEntity', id: number, name: string, unit: Types.EIngredientUnit, createdAt?: any | null, updatedAt?: any | null };

export type GetAllIngredientsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllIngredientsQuery = { __typename?: 'Query', ingredients: Array<{ __typename?: 'IngredientEntity', id: number, name: string, unit: Types.EIngredientUnit, createdAt?: any | null, updatedAt?: any | null }> };

export type CreateIngredientMutationVariables = Types.Exact<{
  input: Types.CreateIngredientInput;
}>;


export type CreateIngredientMutation = { __typename?: 'Mutation', createIngredient: { __typename?: 'IngredientEntity', id: number, name: string, unit: Types.EIngredientUnit, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateIngredientMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateIngredientInput;
}>;


export type UpdateIngredientMutation = { __typename?: 'Mutation', updateIngredient: { __typename?: 'IngredientEntity', id: number, name: string, unit: Types.EIngredientUnit, createdAt?: any | null, updatedAt?: any | null } };

export const IngredientFieldsFragmentDoc = gql`
    fragment IngredientFields on IngredientEntity {
  id
  name
  unit
  createdAt
  updatedAt
}
    `;
export const GetAllIngredientsDocument = gql`
    query GetAllIngredients {
  ingredients {
    ...IngredientFields
  }
}
    ${IngredientFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllIngredientsService extends Apollo.Query<GetAllIngredientsQuery, GetAllIngredientsQueryVariables> {
    override document = GetAllIngredientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateIngredientDocument = gql`
    mutation CreateIngredient($input: CreateIngredientInput!) {
  createIngredient(createIngredientInput: $input) {
    ...IngredientFields
  }
}
    ${IngredientFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateIngredientService extends Apollo.Mutation<CreateIngredientMutation, CreateIngredientMutationVariables> {
    override document = CreateIngredientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateIngredientDocument = gql`
    mutation UpdateIngredient($id: Int!, $input: UpdateIngredientInput!) {
  updateIngredient(id: $id, updateIngredientInput: $input) {
    ...IngredientFields
  }
}
    ${IngredientFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateIngredientService extends Apollo.Mutation<UpdateIngredientMutation, UpdateIngredientMutationVariables> {
    override document = UpdateIngredientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }