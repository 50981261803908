import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InputTextModule } from "primeng/inputtext";
import { FormsModule } from "@angular/forms";
import { IProduct, PRODUCT_CATEGORIES_CONFIG, PRODUCT_CATEGORY_OPTIONS, ProductCategoryName } from "@prf/shared/domain";
import { ButtonModule } from "primeng/button";
import { DropdownModule } from "primeng/dropdown";

// TODO: Make dynamic, usable for different entity types.
@Component({
  selector: 'prf-product-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    DropdownModule,
  ],
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  productCategoryOptions = PRODUCT_CATEGORY_OPTIONS;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalProduct: IProduct;
  private _product!: IProduct;

  @Input() set product(product: IProduct) {
    // this._originalProduct = JSON.parse(JSON.stringify(product));
    this._product = JSON.parse(JSON.stringify(product));
  }

  get product(): IProduct {
    return this._product;
  }

  @Output()
  createEntity = new EventEmitter<IProduct>();

  @Output()
  updateEntity = new EventEmitter<IProduct>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    if (this.isCreate) {
      this.createEntity.emit(this.product);
    } else {
      this.updateEntity.emit(this.product);
    }
  }

  handleProductNoChange(productNo: string): void {
    let newCategory: ProductCategoryName | null = null;

    for (const categoryConfig of PRODUCT_CATEGORIES_CONFIG) {
      if (productNo.startsWith(categoryConfig.productNoStartsWith)) {
        newCategory = categoryConfig.name;
        break;
      }
    }

    if (newCategory) {
      this.product.category = newCategory;
    }
  }
}
