import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IUser } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from "primeng/dropdown";
import { PasswordModule } from "primeng/password";
import { InputSwitchModule } from "primeng/inputswitch";

type LocalModel = IUser;

@Component({
  selector: 'prf-user-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    RadioButtonModule,
    DropdownModule,
    PasswordModule,
    InputSwitchModule,
  ],
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  setNewPassword: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: IProduct;
  private _entity!: LocalModel;

  @Input() set entity(entity: LocalModel) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): LocalModel {
    return this._entity;
  }

  @Output()
  entityChange = new EventEmitter<LocalModel>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    const emitEntity = JSON.parse(JSON.stringify(this.entity));
    const isEdit = !this.isCreate

    if (isEdit && !this.setNewPassword) {
      // Remove password from payload, as it shouldn't be changed.
      delete emitEntity.password;
    }

    this.entityChange.emit(emitEntity);
    this.setNewPassword = false;
  }
}
