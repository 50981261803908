import {
  createPerfoTableConfig,
  PerfoDataTableCellRenderType,
} from '../../shared/components/data-table/data-table.component';
import { IProduct } from '@prf/shared/domain';

// TODO: Zuletzt bearbeitet am: DATUM - für jede Entity

export const ProductsTableConfig = createPerfoTableConfig<IProduct>([
  {
    header: 'Nr.',
    field: 'productNo',
    sortable: true,
    width: 210,
    customRender: { monoFont: true },
  },
  {
    header: 'Beschreibung',
    field: 'description',
    sortable: true,
  },
  {
    header: 'EAN',
    field: 'ean',
    sortable: true,
    customRender: { monoFont: true },
  },
  {
    header: 'Kategorie',
    field: 'category',
    sortable: true,
    width: 280,
  },
  {
    header: 'Nettopreis (€)',
    field: 'netPrice',
    sortable: true,
    width: 220,
    customRender: {
      renderType: PerfoDataTableCellRenderType.PRICE
    }
  },
  {
    header: 'Bruttopreis UVP (€)',
    field: 'grossPrice',
    sortable: true,
    width: 220,
    customRender: {
      renderType: PerfoDataTableCellRenderType.PRICE
    }
  },
  {
    header: 'Grammatur (Gramm)',
    field: 'pack',
    sortable: true,
    width: 220,
    customRender: { monoFont: true },
  }
]);
