import { Injectable } from '@angular/core';
import { IEntity, PerfoEntityType } from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { EntityTypeDropdownViewModelQueries } from './entity-dropdown-view-model.queries';
import { FilterEntityOptionsFn } from './entity-dropdown.component';

// type EntityLabelMappingType = {
//   [K in PerfoEntityType]: keyof EntityInterfaceMap[K];
// };

type EntityPlaceholderMappingType = {
  [K in PerfoEntityType]: string;
};

// TODO: Check whether to move into view-model file.
// const ENTITY_LABEL_MAPPING: EntityLabelMappingType = {
//   delivery: 'id',
//   invoice: 'id',
//   recipe: 'id',
//   retailer: 'retailerName',
//   product: 'productNo',
//   market: 'marketName',
//   ingredient: 'unit',
// };

const ENTITY_PLACEHOLDER_MAPPING: EntityPlaceholderMappingType = {
  delivery: 'Lieferung auswählen',
  invoice: 'Rechnung auswählen',
  recipe: 'Rezept auswählen',
  retailer: 'Händler auswählen',
  product: 'Artikel auswählen',
  market: 'Markt auswählen',
  ingredient: 'Rohware auswählen',
  user: 'Benutzer auswählen',
  vehicle: 'Fahrzeug auswählen',
  deliveryProduct: '<CURRENTLY NOT USED>',
};

type EntityOptionsCacheKey = `${PerfoEntityType}-${string}` | PerfoEntityType;

@Injectable({
  providedIn: 'root',
})
export class EntityDropdownOptionsService {
  private entityOptions: Partial<Record<EntityOptionsCacheKey, any[]>> = {};

  constructor(private store: Store) {}

  // TODO: create EntityLookupService... selectSnapshot from store
  // merge/refactor: private getDriverNameById(userId: number): string {

  // TODO: when/how do entity options get updated? ie adding an entity -> needs updated options
  getEntityOptions(
    entityType: PerfoEntityType,
    filterFn?: (entity: IEntity) => boolean,
    filterKey?: string,
  ): any[] {
    const entityCacheKey = (
      filterKey ? `${entityType}-${filterKey}` : entityType
    ) as EntityOptionsCacheKey;

    if (!this.entityOptions[entityCacheKey]) {
      if (!this.entityOptions[entityType]) {
        this.entityOptions[entityType] = this.store.selectSnapshot(
          EntityTypeDropdownViewModelQueries.getOptionsForType,
        )(entityType);
      }

      let options = [...(this.entityOptions[entityType] || [])];

      if (filterFn) {
        if (!filterKey) {
          throw Error(
            `Filter key missing, in order to store/cache options. \nentityType=${entityType}. \nfilterFn=${filterFn}`,
          );
        }
        options = options.filter(filterFn);
        // Cache the filtered options with the filterKey
        this.entityOptions[entityCacheKey] = options;
      }
    }

    return this.entityOptions[entityCacheKey] || [];
  }

  // TODO: Add types for filter*
  filterEntities(
    entityType: PerfoEntityType,
    query: string,
    filterFn?: FilterEntityOptionsFn,
    filterKey?: string,
  ): any[] {
    const options = this.getEntityOptions(entityType, filterFn, filterKey);
    return options.filter((option) =>
      option[this.getLabelField(entityType)]
        .toLowerCase()
        .includes(query.toLowerCase()),
    );
  }

  getLabelField(entityType: PerfoEntityType): string {
    return 'label'; // Currently set to "label" for every type.
    // return ENTITY_LABEL_MAPPING[entityType] || 'id';
  }

  getPlaceholderText(entityType: PerfoEntityType) {
    return ENTITY_PLACEHOLDER_MAPPING[entityType] || '';
  }
}
