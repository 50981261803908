import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  AutoCompleteCompleteEvent,
  AutoCompleteModule, AutoCompleteSelectEvent
} from "primeng/autocomplete";
import { EntityDropdownOptionsService } from "./entity-dropdown-options.service";
import {
  EntityInterfaceMap,
  IEntity,
  PerfoEntityType
} from "@prf/shared/domain";
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from "@angular/forms";

export type FilterEntityOptionsFn = (entity: IEntity) => boolean;

interface EntityOption {
  label: string;
  value: number; // Entity ID
}

@Component({
  selector: "prf-entity-dropdown",
  standalone: true,
  imports: [CommonModule, AutoCompleteModule, FormsModule],
  templateUrl: "./entity-dropdown.component.html",
  styleUrls: ["./entity-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EntityDropdownComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityDropdownComponent<T extends PerfoEntityType>
  implements OnInit, ControlValueAccessor {
  @Input({
    required: true
  })
  entityType!: T; // TODO: passing arg in markup is currently not type safe

  @Input()
  filterFn?: FilterEntityOptionsFn;

  @Input()
  filterKey?: string;

  @Input()
  disabled: boolean = false;

  @Output() selectEntity = new EventEmitter<EntityInterfaceMap[T]>();

  selectedEntityOption: EntityOption | null = null;
  options:EntityOption[] = [];
  filteredOptions: EntityInterfaceMap[T][] = [];

  labelField!: string;
  placeholderText!: string;

  private onChange = (value: any) => {
  };
  private onTouched = () => {
  };

  constructor(public entityDropdownService: EntityDropdownOptionsService) {
  }

  ngOnInit(): void {
    this.options = this.entityDropdownService.getEntityOptions(this.entityType, this.filterFn, this.filterKey);
    this.labelField = this.entityDropdownService.getLabelField(this.entityType);
    this.placeholderText = this.entityDropdownService.getPlaceholderText(
      this.entityType
    );
  }

  // TODO: better naming, that doesnt collide with (usage of) filterFn input, used here and in service.
  filterOptions(event: AutoCompleteCompleteEvent) {
    this.filteredOptions = this.entityDropdownService.filterEntities(
      this.entityType,
      event.query,
      this.filterFn,
      this.filterKey
    );
  }

  onSelectEntityFromDropdown(event: AutoCompleteSelectEvent) {
    if (event) {
      this.setEntityOptionValue(event.value);
    }
  }

  writeValue(entityId: number | null): void {
    const selectedDropdownOption = this.options.find((option: any) => option.value === entityId) || null;
    if (selectedDropdownOption) {
      this.selectedEntityOption = selectedDropdownOption;
    } else {
      this.selectedEntityOption = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setEntityOptionValue(entityOption: EntityOption): void {
    const entityId = entityOption.value;

    this.writeValue(entityOption.value); // Sets the selectedEntity
    this.onChange(entityId);
    this.onTouched();
  }
}
