<ng-container *ngIf="viewModel$ | async as viewModel">
  <div class="flex flex-row">
    <div class="col">
      <prf-data-table
        [rowData]="viewModel.products"
        [tableColumns]="TableConfig.columns"
        [loading]="loading"
        [selectedEntity]="selectedEntity"
        (selectionChange)="onEntitySelectionChange($event)" />
    </div>
    <div *ngIf="selectedEntity"
         prfToggleEntityDetailsExpand
         class="col col-data-details">
      <div class="card">
        <p-tabView [activeIndex]="0">
          <p-tabPanel header="Details">
            <prf-product-details [product]="selectedEntity"
                                 [updateInProgress]="updateInProgress"
                                 [showClose]="true"
                                 (clickClose)="selectedEntity = null;"
                                 (updateEntity)="handleUpdateEntity($event)"
            />
            <div class="flex justify-content-end">
              <div class="flex justify-content-end ">
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Rezept" [disabled]="true">
          </p-tabPanel>
          <!--<p-tabPanel header="Rezept">
            <prf-recipe-editor [product]="selectedEntity" />
          </p-tabPanel>-->
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>
