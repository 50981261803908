import * as dayjs from 'dayjs';
import { IAddress, IMarket, IUser } from '@prf/shared/domain';

export enum DeliveryDateFormat {
  Short,
  Long,
}

// TODO: pass arg for different formats, lengths
export function formatDeliveryDate(
  date: string | Date,
  format: DeliveryDateFormat = DeliveryDateFormat.Short,
): string {
  let dateFormat;
  switch (format) {
    case DeliveryDateFormat.Short:
      dateFormat = 'dd, DD.MM.YYYY';
      break;
    case DeliveryDateFormat.Long:
      dateFormat = 'dddd, DD.MM.YYYY';
      break;
  }
  return dayjs(date).format(dateFormat);
}

export function formatDriverName(driverId: number, allDrivers: IUser[]): string {
  const driver = allDrivers.find((driver) => driver.id === driverId);
  if (driver) {
    return `${driver.firstName} ${driver.lastName}`;
  } else {
    return `Unbekannter Fahrer (ID: ${driverId})`;
  }
}

export function formatMarketName(
  marketId: number,
  allMarkets: IMarket[],
  options: {
    addDeliveryAddress: boolean;
  } = { addDeliveryAddress: false },
): string {
  const market = allMarkets.find((market) => market.id === marketId);
  if (market) {
    const deliveryAddress = formatMarketDeliveryAddress(marketId, allMarkets);
    return (options.addDeliveryAddress && deliveryAddress) ? `${market.marketName}, ${deliveryAddress}` : market.marketName;
  } else {
    return `Unbekannter Markt (ID: ${marketId})`;
  }
}

export function formatMarketDeliveryAddress(
  marketId: number,
  allMarkets: IMarket[],
): string {
  const market = allMarkets.find((market) => market.id === marketId);
  if (market && market.deliveryAddress?.street) {
    return formatAddress(market.deliveryAddress);
  } else {
    return '- Ohne Lieferadresse -';
  }
}

export function formatMarketBillingAddress(
  marketId: number,
  allMarkets: IMarket[],
): string {
  const market = allMarkets.find((market) => market.id === marketId);
  if (market && market.billingAddress?.street) {
    return formatAddress(market.billingAddress);
  } else {
    return '- Ohne Rechnungsadresse -';
  }
}

// TODO: Refactor: Extract into shared, in order to use across apps.
export function formatAddress(address: IAddress): string {
  if (address) {
    return `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`;
  } else {
    return '- Ohne Adresse -';
  }
}
