import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IIngredient, EIngredientUnit } from "@prf/shared/domain";
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from "primeng/radiobutton";

@Component({
  selector: 'prf-ingredient-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    RadioButtonModule,
  ],
  templateUrl: './ingredient-details.component.html',
  styleUrls: ['./ingredient-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngredientDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  showClose: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  ingredientUnits = [EIngredientUnit.GRAM, EIngredientUnit.LITER];

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  // TODO: Use for hasChanges -> toggle save button disabled
  // private _originalEntity: IProduct;
  private _entity!: IIngredient;

  @Input() set entity(entity: IIngredient) {
    // this._originalEntity = JSON.parse(JSON.stringify(entity));
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): IIngredient {
    return this._entity;
  }

  @Output()
  entityChange = new EventEmitter<IIngredient>();

  @Output()
  clickClose = new EventEmitter<void>();

  constructor() {}

  onClickSave() {
    this.entityChange.emit(this.entity);
  }
}
