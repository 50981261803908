import { Selector } from '@ngxs/store';
import { DataTableMarket, IMarket } from '@prf/shared/domain';
import { MarketsState } from '../state/entities/markets.state';
import { formatMarketBillingAddress, formatMarketDeliveryAddress } from '../shared/formatters/entity.formatter';

export interface MarketsPageViewModel {
  markets: DataTableMarket[];
}

export class MarketsPageViewModelQueries {
  @Selector([MarketsState.items])
  static getViewModel(markets: IMarket[]): MarketsPageViewModel {
    return {
      markets: markets.map(m => ({
        ...m,
        billingAddressLabel: formatMarketBillingAddress(m.id, markets),
        deliveryAddressLabel: formatMarketDeliveryAddress(m.id, markets),
      })),
    };
  }

}
