import { createPerfoTableConfig } from '../../shared/components/data-table/data-table.component';
import { IIngredient } from "@prf/shared/domain";

export const IngredientsTableConfig = createPerfoTableConfig<IIngredient>([
  {
    header: 'Name',
    field: 'name',
    sortable: true,
  },
  {
    header: 'Einheit',
    field: 'unit',
    sortable: true,
  },
]);
