import { Injectable, NgZone } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import {
  DeliveryStatus,
  DeliveryType,
  IDelivery,
  IDeliveryProduct,
  PerfoEntityType,
} from '@prf/shared/domain';
import { catchError, map, Observable, tap, throwError } from 'rxjs';
import { NotificationActionType, ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateDelivery,
  CreateNewDeliveriesFromPlanning,
  LoadAllDeliveries,
  LoadDelivery,
  LoadSpecificDeliveries,
  SendDeliverySlipEmail,
  UpdateDelivery,
  UpdateDeliveryProducts,
  UpdateDraggedDelivery,
} from './deliveries.actions';
import {
  CreateDeliveryService,
  CreateNewDeliveriesFromPlanningService,
  DeliveryFieldsFragment,
  DeliveryWithDeliveryProductsAndProcessedDataFieldsFragment,
  GetAllDeliveriesService,
  GetDeliveryByIdService,
  GetSpecificDeliveriesService,
  SendDeliverySlipEmailMutationVariables,
  SendDeliverySlipEmailService,
  SetDeliveryAsCompletedMutationVariables,
  SetDeliveryAsCompletedService,
  SetDeliveryAsDeliveredMutationVariables,
  SetDeliveryAsDeliveredService,
  SingleDeliveryProductFieldsFragment,
  UpdateDeliveryService,
} from '../../graphql/deliveries-operations.generated';
import * as dayjs from 'dayjs';
import { CreateMarketInput, UpdateDeliveryProductsInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';
import { patch, updateItem } from '@ngxs/store/operators';
import { UpdateDeliveryProductsService } from '../../graphql/delivery-products-operations.generated';
import { SetDeliveryAsCompleted, SetDeliveryManuallyAsDelivered } from '../ui.actions';

type LocalModel = IDelivery;
type LocalStateModel = DeliveriesStateModel;
type LocalStateContext = StateContext<DeliveriesStateModel>;

export interface DeliveriesStateModel {
  items: LocalModel[];
}

@State<DeliveriesStateModel>({
  name: 'deliveries',
  defaults: {
    items: [],
  },
})
@Injectable()
export class DeliveriesState
  extends BaseEntityState<
    {
      fetchAll: GetAllDeliveriesService;
      create: CreateDeliveryService;
      update: UpdateDeliveryService;
    },
    IDelivery
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'delivery';

  // TODO: Refactor: Extract into something abstract. Also needed for retailers.state.ts
  // Note: These properties are used for CREATE and UPDATE.
  private readonly entityPropertiesToPick: Record<keyof IDelivery, boolean> = {
    id: true,
    deliveryDate: true,
    driverId: true,
    driverNote: true,
    isActive: true,
    marketId: true,
    status: true,
    type: true,
    vehicleId: true,

    // Skip
    deliverySlipNumber: false,
    deliveryProducts: false,
    hasPostDeliveryCorrections: false,
    deliverySlipEmailSent: false,
    deliveryDeliveredDate: false,
    processedDelivery: false,
    photos: false,
  };

  constructor(
    protected getAllDeliveriesService: GetAllDeliveriesService,
    protected getDeliveryByIdService: GetDeliveryByIdService,
    protected createDeliveryService: CreateDeliveryService,
    protected updateDeliveryService: UpdateDeliveryService,
    protected updateDeliveryProductsService: UpdateDeliveryProductsService,
    protected createNewDeliveriesFromPlanningService: CreateNewDeliveriesFromPlanningService,
    protected setDeliveryAsDeliveredService: SetDeliveryAsDeliveredService,
    protected setDeliveryAsCompletedService: SetDeliveryAsCompletedService,
    protected getSpecificDeliveriesService: GetSpecificDeliveriesService,
    protected sendDeliverySlipEmailService: SendDeliverySlipEmailService,
    protected ngZone: NgZone,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllDeliveriesService,
        create: createDeliveryService,
        update: updateDeliveryService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadAllDeliveries();
  }

  protected mapGqlEntityToUiEntity(
    entity: DeliveryFieldsFragment | DeliveryWithDeliveryProductsAndProcessedDataFieldsFragment,
  ): IDelivery {
    // Type guard to check if entity includes deliveryProducts
    const isWithDeliveryProducts = (
      e: DeliveryFieldsFragment | DeliveryWithDeliveryProductsAndProcessedDataFieldsFragment,
    ): e is DeliveryWithDeliveryProductsAndProcessedDataFieldsFragment => 'deliveryProducts' in e;

    return {
      id: entity.id,
      status: DeliveryStatus[entity.status as keyof typeof DeliveryStatus],
      type: DeliveryType[entity.type as keyof typeof DeliveryType],
      deliverySlipNumber: entity.deliverySlipNumber,
      deliveryDate: dayjs(entity.deliveryDate).format('YYYY-MM-DD'),
      deliveryDeliveredDate:
        entity.deliveryDeliveredDate !== null
          ? dayjs(entity.deliveryDeliveredDate).format('YYYY-MM-DD')
          : null,
      isActive: entity.isActive,
      marketId: entity.market?.id ?? null,
      driverId: entity.driver?.id ?? null,
      driverNote: entity.driverNote,
      vehicleId: entity.vehicleId,
      hasPostDeliveryCorrections: entity.hasPostDeliveryCorrections,
      deliverySlipEmailSent: entity.deliverySlipEmailSent,
      deliveryProducts: isWithDeliveryProducts(entity)
        ? (entity.deliveryProducts || []).map((delivProduct) =>
            DeliveriesState.mapDeliveryProductGqlEntityToUiEntity(delivProduct, entity.id),
          )
        : undefined,
      processedDelivery: isWithDeliveryProducts(entity) ? entity.processedDelivery : undefined,
      photos: isWithDeliveryProducts(entity) ? entity.photos : undefined,
    };
  }

  // TODO: Refactor: Extract, make reusable.
  public static mapDeliveryProductGqlEntityToUiEntity(
    entity: SingleDeliveryProductFieldsFragment,
    deliveryId: number,
  ): IDeliveryProduct {
    return {
      id: entity.id,
      deliveryId: deliveryId,
      productId: entity.product.id,
      targetQuantity: entity.targetQuantity ?? 0,
      actualQuantity: entity.actualQuantity ?? null,
      returnQuantity: entity.returnQuantity ?? null,
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadAllDeliveries)
  protected loadAllDeliveries(ctx: LocalStateContext, action: LoadAllDeliveries): Observable<any> {
    return this.loadEntities<DeliveryFieldsFragment[]>(ctx, 'deliveries');
  }

  @Action(LoadDelivery)
  fetchDelivery(ctx: LocalStateContext, action: LoadDelivery): Observable<any> {
    const deliveryId = action.payload.deliveryId;

    return this.getDeliveryByIdService.fetch({ id: deliveryId }).pipe(
      // delay(2000),
      tap(({ data }) => {
        const delivery = data && data.findDeliveryById ? data.findDeliveryById : null;
        if (delivery) {
          const uiEntity = this.mapGqlEntityToUiEntity(delivery);
          // TODO: Refactor: Proper typing for GetDelivery (via LoadAll) vs FetchById
          uiEntity.deliveryProducts = (delivery.deliveryProducts || []).map((item) =>
            DeliveriesState.mapDeliveryProductGqlEntityToUiEntity(item, deliveryId),
          );

          ctx.setState(
            patch({
              items: updateItem<LocalModel>(
                (item: LocalModel) => item.id === delivery.id,
                uiEntity as any,
              ),
            }),
          );
        }
      }),
      catchError((error) => {
        console.error(`Error fetching delivery with ID ${deliveryId}:`, error);
        this.toastService.showEntityCrudError('delivery', NotificationActionType.READ);
        return throwError(error);
      }),
    );
  }

  // LOAD BULK - loads deliveries by id and also populates deliveryProducts
  @Action(LoadSpecificDeliveries)
  fetchSpecificDeliveries(ctx: LocalStateContext, action: LoadSpecificDeliveries): Observable<any> {
    const deliveryIds = action.payload.deliveries.map((delivery) => delivery.id);

    return this.getSpecificDeliveriesService.fetch({ ids: deliveryIds }).pipe(
      tap(({ data }) => {
        const deliveries = data && data.loadSpecificDeliveries ? data.loadSpecificDeliveries : [];
        const uiEntities = deliveries.map((delivery) => {
          const uiEntity = this.mapGqlEntityToUiEntity(delivery);
          uiEntity.deliveryProducts = (delivery.deliveryProducts || []).map((item) =>
            DeliveriesState.mapDeliveryProductGqlEntityToUiEntity(item, delivery.id),
          );
          return uiEntity;
        });

        ctx.setState(
          patch({
            items: [...uiEntities],
          }),
        );
      }),
      catchError((error) => {
        console.error(`Error fetching specific deliveries:`, error);
        this.toastService.showEntityCrudError('delivery', NotificationActionType.READ);
        return throwError(error);
      }),
    );
  }

  @Action(CreateDelivery)
  createDelivery(ctx: LocalStateContext, action: CreateDelivery): Observable<any> {
    const delivery = this.pickEntityProperties(action.payload.entity);
    return this.createEntity<CreateMarketInput>(ctx, delivery, 'createDelivery');
  }

  @Action(UpdateDelivery)
  updateDelivery(ctx: LocalStateContext, action: UpdateDelivery): Observable<any> {
    const delivery = this.pickEntityProperties(action.payload.entity);
    return this.updateEntity<IDelivery>(ctx, delivery, 'updateDelivery');
  }

  @Action(UpdateDraggedDelivery)
  updateDraggedDelivery(ctx: LocalStateContext, action: UpdateDraggedDelivery): Observable<any> {
    console.log('updateDraggedDelivery - action', action);
    return ctx.dispatch(
      new UpdateDelivery({
        entity: {
          ...action.payload.entity,
          ...action.payload.updateFields,
        },
      }),
    );
  }

  @Action(CreateNewDeliveriesFromPlanning)
  createNewDeliveriesFromPlanning(
    ctx: LocalStateContext,
    action: CreateNewDeliveriesFromPlanning,
  ): Observable<any> {
    // TODO: Refactor Typing of map fn
    const deliveries = action.payload.deliveries.map((delivery: any) => ({
      ...this.pickEntityProperties(delivery),
      deliveryProducts: delivery.deliveryProducts,
      isNewDelivery: delivery.isNewDelivery, // TODO
    }));

    return this.createNewDeliveriesFromPlanningService
      .mutate({
        createNewDeliveriesFromPlanningInput: {
          deliveries,
        },
      })
      .pipe(
        tap((mutationResult) => {
          // Update your state as needed
          console.log('res - mutationResult', mutationResult);
          // ...
        }),
        // catchError((error) => {
        //   console.log('err - error', error);
        //   // Handle error
        //   // ...
        // }),
      );
  }

  private pickEntityProperties(entity: any): IDelivery {
    const delivery: Partial<IDelivery> = {};
    Object.keys(this.entityPropertiesToPick).forEach((key) => {
      if (this.entityPropertiesToPick[key as keyof IDelivery] && key in entity) {
        delivery[key as keyof IDelivery] = entity[key];
      }
    });
    return delivery as IDelivery;
  }

  // DeliveryProducts
  @Action(UpdateDeliveryProducts)
  updateDeliveryProducts(ctx: LocalStateContext, action: UpdateDeliveryProducts): Observable<any> {
    // assuming you have updateDeliveryProductsService method to handle the actual logic

    const variables: UpdateDeliveryProductsInput = {
      deliveryId: action.payload.deliveryId,
      deliveryProducts: action.payload.entities.filter((dP) => dP.productId !== null), // Removes the last (auto added) empty input field which has no product assigned yet.
      hasPostDeliveryCorrections: action.payload.hasPostDeliveryCorrections,
    };

    return this.updateDeliveryProductsService.mutate({ input: variables }).pipe(
      tap((mutationResult) => {
        ctx.patchState({
          items: ctx.getState().items.map((delivery) => {
            if (delivery.id === action.payload.deliveryId) {
              return {
                ...delivery,
                hasPostDeliveryCorrections: action.payload.hasPostDeliveryCorrections,
                deliveryProducts: mutationResult?.data?.updateDeliveryProducts.map((item) =>
                  DeliveriesState.mapDeliveryProductGqlEntityToUiEntity(
                    item,
                    action.payload.deliveryId,
                  ),
                ),
              };
            }

            return delivery;
          }),
        });
        this.toastService.showEntityCrudSuccess('deliveryProduct', NotificationActionType.UPDATE);
      }),
      catchError((error) => {
        console.error(
          `Error updating delivery products for delivery ID ${action.payload.deliveryId}:`,
          error,
        );
        this.toastService.showEntityCrudError(
          'deliveryProduct',
          NotificationActionType.UPDATE,
          error,
        );
        return throwError(error);
      }),
    );
  }

  @Action(SetDeliveryManuallyAsDelivered)
  setDeliveryManuallyAsDelivered(ctx: LocalStateContext, action: SetDeliveryManuallyAsDelivered) {
    const delivery = action.payload.delivery;

    const variables: SetDeliveryAsDeliveredMutationVariables = {
      deliveryId: delivery.id,
    };

    return this.setDeliveryAsDeliveredService.mutate(variables).pipe(
      map((result) => result?.data?.setDeliveryAsDelivered),
      tap((updatedDelivery) => {
        if (updatedDelivery) {
          // TODO: extract this into own method that can be callable from other methods/updates as well, ie setDeliveryStatus
          const uiEntity = this.mapGqlEntityToUiEntity(updatedDelivery);

          ctx.setState(
            patch({
              items: updateItem<IDelivery>(
                (item: IDelivery) => item.id === uiEntity.id,
                uiEntity as any,
              ),
            }),
          );
          this.toastService.showEntityCrudSuccess(this.entityType, NotificationActionType.UPDATE);
        }
      }),
    );
  }

  @Action(SetDeliveryAsCompleted)
  setDeliveryAsCompleted(ctx: LocalStateContext, action: SetDeliveryAsCompleted) {
    const delivery = action.payload.delivery;

    const variables: SetDeliveryAsCompletedMutationVariables = {
      input: {
        deliveryId: delivery.id,
      },
    };

    // TODO: return invoiceNumber from backend and show in toast.
    return this.setDeliveryAsCompletedService.mutate(variables).pipe(
      map((result) => result?.data?.setDeliveryAsCompleted),
      tap((updatedDelivery) => {
        if (updatedDelivery) {
          // TODO: extract this into own method that can be callable from other methods/updates as well, ie setDeliveryStatus
          console.log('setDelAsComp - updatedDelivery', updatedDelivery);
          const uiEntity = this.mapGqlEntityToUiEntity(updatedDelivery);
          console.log('setDelASsComp - uiEntity', uiEntity);

          ctx.setState(
            patch({
              items: updateItem<IDelivery>(
                (item: IDelivery) => item.id === updatedDelivery.id,
                uiEntity as any,
              ),
            }),
          );
          this.toastService.showEntityCrudSuccess(this.entityType, NotificationActionType.UPDATE);
          // TODO: Rechnung angelegt.
        }
      }),
    );
  }

  @Action(SendDeliverySlipEmail)
  sendDeliverySlipEmail(ctx: LocalStateContext, action: SendDeliverySlipEmail): Observable<any> {
    console.log('sendDeliverySlipEmail - action', action);
    // Currently only 1 recipient is allowed.
    if (action.payload.recipientEmails.length !== 1) {
      console.error('Too many mail recipients', action.payload.recipientEmails);
    }

    const variables: SendDeliverySlipEmailMutationVariables = {
      input: {
        deliveryId: action.payload.delivery.id,
        recipientEmail: action.payload.recipientEmails[0],
        subject: action.payload.subject,
        message: action.payload.message,
      },
    };

    return this.sendDeliverySlipEmailService.mutate(variables).pipe(
      map((result) => result?.data?.sendDeliverySlipEmail),
      tap((success) => {
        if (success) {
          console.log(' SEND MAIL SUCCESS - ');
          this.toastService.showEmailSentSuccess();
          // TODO SET DELIVERY AS DELIV MAIL SENT!
        } else {
          console.log('SEND EMAIL ___ERROR___');
          this.toastService.showEmailSentError();
          // TODO: show error toast.
        }
      }),
    );
  }
}
