import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsModule } from 'primeng/steps';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { PickListModule, PickListMoveToTargetEvent } from 'primeng/picklist';
import { PanelModule } from 'primeng/panel';
import { DeliveryPlanningWeekType } from '../../../../state/ui.state';
import {
  AddDeliveryToTransferredTargetWeek,
  ClearAllTransferredTargetWeekDeliveries,
  ClearDeliveryPlanningNewWeekDialogSelectedDelivery,
  SetDeliveryPlanningNewWeekDialogSelectedDelivery,
  SetNewWeekPlanningCalendarWeek,
  UpdateTransferredTargetWeekDelivery,
} from '../../../../state/ui.actions';
import { Select, Store } from '@ngxs/store';
import {
  DeliveryPlanningNewWeekDialogModelQueries,
  DeliveryPlanningNewWeekDialogViewModel,
  ProductQuantityInfo,
} from '../../../../view-models/delivery-planning-new-week-view-model.queries';
import { Observable, take } from 'rxjs';
import { DeliveryPlanningItemComponent } from '../../../../main/delivery-planning/components/delivery-drag-item/delivery-planning-item.component';
import { FormatDatePipe } from '../../../pipes/format-date.pipe';
import { MarketPipe, UserPipe } from '../../../pipes/entity.pipe';
import { DeliveryDetailsComponent } from '../delivery-details/delivery-details.component';
import { IDelivery, IDeliveryProduct } from '@prf/shared/domain';
import {
  CreateNewDeliveriesFromPlanning,
  LoadSpecificDeliveries,
  UpdateDelivery,
} from '../../../../state/entities/deliveries.actions';
import { InputTextModule } from 'primeng/inputtext';
import { SliderModule } from 'primeng/slider';
import { DeliveryPlanningDraggableViewComponent } from '../../../../main/delivery-planning/components/delivery-planning-draggable-view/delivery-planning-draggable-view.component';
import { EntityLookupService } from '../../../services/entity-lookup/entity-lookup.service';
import { formatAddress } from '../../../formatters/entity.formatter';
import { ToastService } from '../../../services/toast/toast.service';

export type PickListPlanningState = 'SOURCE_WEEK' | 'TARGET_WEEK' | 'COPY_FROM_SOURCE';

export interface PickListPlanningDelivery extends IDelivery {
  currentPlanningState: PickListPlanningState;
  planningOrigin: PickListPlanningState;
  isNewDelivery: boolean;
}

export type ProductQuantityChange = {
  relativeChange: number;
  newTargetQuantity: number | null;
};

@Pipe({
  name: 'prfFilterDeliveries',
  pure: true,
  standalone: true,
})
export class FilterDeliveriesPipe implements PipeTransform {
  private entityLookupService = inject(EntityLookupService);
  private formatDatePipe = new FormatDatePipe();

  private getDeliveryFilterString(delivery: IDelivery): string {
    const market = this.entityLookupService.getMarketById(delivery.marketId!);
    const marketName = market?.marketName || '';
    const marketAddress = market?.deliveryAddress ? formatAddress(market?.deliveryAddress) : '';
    const driverFullName = this.entityLookupService.getFullUserNameById(delivery.driverId!) || '';
    const deliveryDate =
      this.formatDatePipe.transform(delivery.deliveryDate, 'dddd, DD.MM.YYYY') || '';

    return `${marketName} ${marketAddress} ${driverFullName} ${deliveryDate}`;
  }

  transform(deliveries: IDelivery[], filterValue: string): IDelivery[] {
    if (!deliveries || !filterValue) {
      return deliveries;
    }

    const term = filterValue.toLowerCase();
    return deliveries.filter((delivery) => {
      const deliveryFilterString = this.getDeliveryFilterString(delivery);
      return deliveryFilterString.toLowerCase().includes(term);
    });
  }
}

@Component({
  selector: 'prf-plan-new-delivery-week-dialog',
  standalone: true,
  imports: [
    CommonModule,
    StepsModule,
    CalendarModule,
    FormsModule,
    ConfirmPopupModule,
    PickListModule,
    PanelModule,
    DeliveryPlanningItemComponent,
    FormatDatePipe,
    MarketPipe,
    UserPipe,
    DeliveryDetailsComponent,
    InputTextModule,
    SliderModule,
    DeliveryPlanningDraggableViewComponent,
    FilterDeliveriesPipe,
  ],
  templateUrl: './plan-new-delivery-week-dialog.component.html',
  styleUrls: ['./plan-new-delivery-week-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanNewDeliveryWeekDialogComponent implements OnInit, OnDestroy {
  private store = inject(Store);
  private confirmationService = inject(ConfirmationService);
  private cdRef = inject(ChangeDetectorRef);
  private toastService = inject(ToastService);

  @Select(DeliveryPlanningNewWeekDialogModelQueries.getViewModel)
  viewModel$!: Observable<DeliveryPlanningNewWeekDialogViewModel>;

  // TODO: upon close dialog: CONFIRM closage... ggf unbewusst. temporäre änderungen gehen verloren
  // TODO: REMOVE 1 DEV
  activeStepIndex = 0;
  isFetchingPlanningDeliveries = false;
  // activeStepIndex = 3;
  isCompletePlanningInProgress = false;

  deliveriesSingleDeliveryAmountsFilterValue = '';

  dialogStepItems: MenuItem[] = [
    {
      label: 'Woche festlegen',
    },
    {
      label: 'Lieferungen übertragen',
    },
    {
      label: 'Fahrerzuweisung Zielwoche',
    },
    {
      label: 'Produktmengen Prozentual',
    },
    {
      label: 'Produktmengen Absolut',
      // Schalensumme anzeigen, unter allen inputs/total - siehe screenshot 2
    },
  ];

  allRelativeProductQuantity = 0;
  selectedAbsoluteProductQuantitiesDelivery: IDelivery | PickListPlanningDelivery | null = null;

  ngOnInit(): void {
    this.fetchPlanningDeliveries();
  }

  ngOnDestroy(): void {
    this.activeStepIndex = 0;
  }

  public onWeekDateSelect($event: Date, weekType: DeliveryPlanningWeekType) {
    this.store
      .dispatch([
        new ClearAllTransferredTargetWeekDeliveries(),
        new SetNewWeekPlanningCalendarWeek({ weekType, date: $event }),
      ])
      .pipe(take(1))
      .subscribe(() => {
        this.fetchPlanningDeliveries();
      });
  }

  onClickPrevStep(): void {
    this.store.dispatch(new ClearDeliveryPlanningNewWeekDialogSelectedDelivery());
    this.activeStepIndex = this.activeStepIndex - 1;
  }

  onClickNextStep(): void {
    this.store.dispatch(new ClearDeliveryPlanningNewWeekDialogSelectedDelivery());
    this.activeStepIndex = this.activeStepIndex + 1;

    // TODO: Extract if body into method.
    if (this.activeStepIndex === 3) {
      setTimeout(() => {
        // Fetch deliveries or rather their deliveryProducts, that might have been transferred/updated over from inactive/other groups.
        this.fetchPlanningDeliveries(true);
      });
    }

    // TODO: Extract magic numbers into named step constants
    // if (this.activeStepIndex === 4) {
    //   this.calculatedRelativeTotalAmountMap.clear();
    // }
  }

  // Updates all planning deliveries and loads their DeliveryProducts.
  // private fetchPlanningDeliveries(loadOnlyMissingTargetDeliveryProducts: boolean = false): void {
  //   const viewModel = this.store.selectSnapshot(
  //     DeliveryPlanningNewWeekDialogModelQueries.getViewModel,
  //   );
  //
  //   let allPlanningDeliveries: IDelivery[];
  //   if (loadOnlyMissingTargetDeliveryProducts) {
  //     allPlanningDeliveries = viewModel.plannedTargetWeekDeliveries.filter(
  //       (delivery) => !delivery.deliveryProducts,
  //     );
  //   } else {
  //     allPlanningDeliveries = [
  //       ...viewModel.otherGroups.flatMap((group) => group.deliveries),
  //       ...viewModel.weekdayGroups.flatMap((group) => [
  //         ...group.sourceDeliveries,
  //         ...group.targetDeliveries,
  //       ]),
  //     ];
  //   }
  //
  //   // TODO: Check for isNewDelivery?
  //   const actions = allPlanningDeliveries.map(
  //     (delivery) => new LoadDelivery({ deliveryId: delivery.id }),
  //   );
  //
  //   this.isFetchingPlanningDeliveries = true;
  //   setTimeout(() => {
  //     this.store
  //       .dispatch(actions)
  //       .pipe(take(1))
  //       .subscribe(() => {
  //         this.isFetchingPlanningDeliveries = false;
  //         this.cdRef.detectChanges();
  //       }, error => {
  //         this.isFetchingPlanningDeliveries = false;
  //         this.cdRef.detectChanges();
  //       });
  //   });
  // }

  // Load bulk - includes deliveryProducts
  private fetchPlanningDeliveries(loadOnlyMissingTargetDeliveryProducts: boolean = false): void {
    const viewModel = this.store.selectSnapshot(
      DeliveryPlanningNewWeekDialogModelQueries.getViewModel,
    );

    let allPlanningDeliveries: IDelivery[];
    if (loadOnlyMissingTargetDeliveryProducts) {
      allPlanningDeliveries = viewModel.plannedTargetWeekDeliveries.filter(
        (delivery) => !delivery.deliveryProducts,
      );
    } else {
      allPlanningDeliveries = [
        ...viewModel.otherGroups.flatMap((group) => group.deliveries),
        ...viewModel.weekdayGroups.flatMap((group) => [
          ...group.sourceDeliveries,
          ...group.targetDeliveries,
        ]),
      ];
    }

    if (allPlanningDeliveries.length === 0) {
      // Nothing to refetch, all data is "fresh".
      return;
    }

    this.isFetchingPlanningDeliveries = true;
    setTimeout(() => {
      this.store
        .dispatch(new LoadSpecificDeliveries({ deliveries: allPlanningDeliveries }))
        .pipe(take(1))
        .subscribe(() => {
          this.isFetchingPlanningDeliveries = false;
          this.cdRef.detectChanges();
        }, error => {
          this.isFetchingPlanningDeliveries = false;
          this.cdRef.detectChanges();
        });
    });
  }

  onClickCompletePlanning(event: any, viewModel: DeliveryPlanningNewWeekDialogViewModel): void {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: 'Planung jetzt abschließen?',
      // icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.isCompletePlanningInProgress = true;
        const updatedDeliveries = viewModel.plannedTargetWeekDeliveries.map((delivery) => {
          const deliveryProductUpdates =
            viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(delivery.id);

          if (deliveryProductUpdates) {
            delivery.deliveryProducts = delivery.deliveryProducts.map((dp: IDeliveryProduct) => {
              const update = deliveryProductUpdates.get(dp.productId);

              // Check if newTargetQuantity was changed/set.
              if (update && update.newTargetQuantity !== null) {
                return { ...dp, targetQuantity: update.newTargetQuantity };
              }

              return dp;
            });
          }

          return delivery;
        });
        console.warn('POST accept - updatedDeliveries', updatedDeliveries);

        this.selectedAbsoluteProductQuantitiesDelivery = null; // Hide amount details.
        this.store
          .dispatch(new CreateNewDeliveriesFromPlanning({ deliveries: updatedDeliveries }))
          .pipe(take(1))
          .subscribe(
            (response) => {
              // Handle success
              console.log('create new deliv from planning action success - response', response);
              this.toastService.showSuccess('Wochenplanung erfolgreich abgeschlossen');
              // TODO: X neue Lieferungen erstellt.
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            },
            (error) => {
              this.toastService.showError('Fehler beim Abschließen der Wochenplanung. Seite wird neu geladen.', error);
              console.error('create new deliv error - error', error);
              this.isCompletePlanningInProgress = false;

              setTimeout(() => {
                window.location.reload();
              }, 4000);
            },
          );
      },
      reject: () => {
        // Do nothing.
      },
    });
  }

  get isButtonPrevStepEnabled(): boolean {
    // Temporarily disabled, because of possible delivAmount bugs / feature-incompletion.
    return false;

    return this.activeStepIndex > 0;
  }

  isButtonNextStepEnabled(
    viewModelValidations: DeliveryPlanningNewWeekDialogViewModel['validations'],
  ): boolean {
    if (viewModelValidations.areSourceAndTargetWeekEqual) return false;

    if (this.isFetchingPlanningDeliveries) {
      return false;
    }

    return this.activeStepIndex < this.dialogStepItems.length - 1;
  }

  get isLastStep(): boolean {
    return this.activeStepIndex === this.dialogStepItems.length - 1;
  }

  onClickShowDeliveryItemDetails(delivery: IDelivery): void {
    this.store.dispatch(
      new SetDeliveryPlanningNewWeekDialogSelectedDelivery({
        selectedDetailsDelivery: delivery,
      }),
    );
  }

  onClickCloseDeliveryDetails(): void {
    this.store.dispatch(new ClearDeliveryPlanningNewWeekDialogSelectedDelivery());
  }

  onSaveDeliveryDetails(delivery: IDelivery): void {
    const savedDelivery = delivery as PickListPlanningDelivery;
    if (savedDelivery.isNewDelivery) {
      this.store.dispatch(
        new UpdateTransferredTargetWeekDelivery({
          updatedDelivery: savedDelivery,
        }),
      );
    } else {
      this.store
        .dispatch(new UpdateDelivery({ entity: delivery }))
        .pipe(take(1))
        .subscribe((result: any) => {
          this.cdRef.markForCheck(); // TODO: Check for necessity.
        });
    }
  }

  // Note: Currently not used, as only move from source to target is allowed.
  // onPickListMoveToSource(event: PickListMoveToSourceEvent, sourceDate: string): void {
  //   console.log('onPickListMoveToSource - event', event.items, sourceDate);
  //   this.updateDeliveries(event.items, sourceDate, 'SOURCE_WEEK');
  // }

  onPickListMoveToTarget(event: PickListMoveToTargetEvent, targetDate: string): void {
    this.updateDeliveries(event.items, targetDate, 'TARGET_WEEK');
  }

  private updateDeliveries(
    items: PickListPlanningDelivery[],
    date: string,
    state: PickListPlanningState,
  ): void {
    items.forEach((delivery: PickListPlanningDelivery) => {
      const copiedDelivery = JSON.parse(JSON.stringify(delivery));

      copiedDelivery.deliveryDate = date;
      copiedDelivery.currentPlanningState = state;

      copiedDelivery.isNewDelivery =
        copiedDelivery.planningOrigin === 'SOURCE_WEEK' && state === 'TARGET_WEEK';

      this.store.dispatch(new AddDeliveryToTransferredTargetWeek({ delivery: copiedDelivery }));
    });
  }

  onAllRelativeChange($event: number, viewModel: DeliveryPlanningNewWeekDialogViewModel) {
    this.allRelativeProductQuantity = $event;

    // TODO: Extract and merge with initial viewModel total sum calc
    viewModel.productQuantitiesTotalAmount = 0;
    viewModel.productQuantities.forEach((pQ) => {
      this.calculateSingleProductUpdatedTotalAmountViaRelativAll(pQ, $event, viewModel);
      viewModel.productQuantitiesTotalAmount =
        viewModel.productQuantitiesTotalAmount + pQ.totalAmountWithRelativeChange;
    });
  }

  private updateRelativeTotalQuantitiesAmount(viewModel: DeliveryPlanningNewWeekDialogViewModel) {
    viewModel.productQuantitiesTotalAmount = 0;
    viewModel.productQuantities.forEach((pQ) => {
      viewModel.productQuantitiesTotalAmount =
        viewModel.productQuantitiesTotalAmount + pQ.totalAmountWithRelativeChange;
    });
  }

  private MIN_PRODUCT_AMOUNT = 2;

  calculateSingleProductUpdatedTotalAmountViaRelativAll(
    productQuantity: ProductQuantityInfo,
    relativeChangeAllValue: number,
    viewModel: DeliveryPlanningNewWeekDialogViewModel,
  ): void {
    productQuantity.relativeChangeAll = relativeChangeAllValue;
    let value;
    if (productQuantity.totalAmount === 0) {
      value = 0;
      productQuantity.totalAmountWithRelativeChange = value;
    }

    // Check for -100% relative change
    if (productQuantity.relativeChangeAll === -100) {
      value = 0;
      productQuantity.totalAmountWithRelativeChange = value;
    } else {
      const calculatedAmount = Math.round(
        productQuantity.totalAmount * (1 + productQuantity.relativeChangeAll / 100),
      );
      // Apply the minimum amount rule only if calculatedAmount is greater than 0
      value =
        calculatedAmount > 0
          ? Math.max(calculatedAmount, this.MIN_PRODUCT_AMOUNT)
          : calculatedAmount;
      productQuantity.totalAmountWithRelativeChange = value;
    }

    this.updateRelativeTotalQuantitiesAmount(viewModel);
  }

  calculateSingleProductUpdatedTotalAmountViaAbsoluteDeliveryValue(
    productQuantity: ProductQuantityInfo,
    relativeChangeForDeliveryProductValue: number,
    viewModel: DeliveryPlanningNewWeekDialogViewModel,
    delivery: IDelivery,
  ): void {
    // TODO: Refactor: Calc newTargetQuantity here and access it in view.
    const deliveryProdRelMap = viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries.get(
      delivery.id,
    )!;
    const productChange = deliveryProdRelMap.get(productQuantity.product.id)!;
    productChange.relativeChange = relativeChangeForDeliveryProductValue;

    // deliveryProdRelMap.set(productQuantity.product.id, relativeChangeForDeliveryProductValue);
    // productQuantity.relativeChangeAll = relativeChangeForDeliveryProductValue;
    // let value;
    // if (productQuantity.totalAmount === 0) {
    //   value = 0;
    //   productQuantity.totalAmountWithRelativeChange = value;
    // }
    //
    // // Check for -100% relative change
    // if (productQuantity.relativeChangeAll === -100) {
    //   value = 0;
    //   productQuantity.totalAmountWithRelativeChange = value;
    // } else {
    //   const calculatedAmount = Math.round(
    //     productQuantity.totalAmount * (1 + productQuantity.relativeChangeAll / 100),
    //   );
    //   // Apply the minimum amount rule only if calculatedAmount is greater than 0
    //   value =
    //     calculatedAmount > 0
    //       ? Math.max(calculatedAmount, this.MIN_PRODUCT_AMOUNT)
    //       : calculatedAmount;
    //   productQuantity.totalAmountWithRelativeChange = value;
    // }
    //
    // this.updateRelativeTotalQuantitiesAmount(viewModel);
  }

  getDeliveryProductNewTargetQuantity(
    selectedAbsoluteProductQuantitiesDelivery: IDelivery,
    productQuantity: ProductQuantityInfo,
    productForDeliveryRelativeSingleChange: number,
    viewModel: DeliveryPlanningNewWeekDialogViewModel,
  ): number {
    // Find the delivery product matching the given product ID
    const deliveryProductForProductId: IDeliveryProduct =
      selectedAbsoluteProductQuantitiesDelivery.deliveryProducts.find((dP: IDeliveryProduct) => {
        return dP.productId === productQuantity.product.id;
      });

    // If no matching product found, return 0
    if (!deliveryProductForProductId) {
      return 0;
    }

    // Calculate new target quantity after applying the relative change all
    let newTargetQuantity = Math.round(
      (deliveryProductForProductId.targetQuantity || 0) *
        (1 + productQuantity.relativeChangeAll / 100),
    );

    // Apply the productForDeliveryRelativeSingleChange and round the result
    newTargetQuantity = Math.round(
      newTargetQuantity * (1 + productForDeliveryRelativeSingleChange / 100),
    );

    const productChange = viewModel.newProductQuantitiesForPlannedTargetWeekDeliveries
      .get(selectedAbsoluteProductQuantitiesDelivery.id)!
      .get(productQuantity.product.id)!;
    productChange.newTargetQuantity = newTargetQuantity;

    return newTargetQuantity;
  }
}
