import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { EntityDropdownComponent } from '../../../../shared/components/entity-dropdown/entity-dropdown.component';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CreatableEntity,
  DeliveryStatus,
  IDelivery,
  IDeliveryProduct,
  IProduct,
} from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { UpdateDeliveryProducts } from '../../../../state/entities/deliveries.actions';
import { FormatDatePipe } from '../../../../shared/pipes/format-date.pipe';
import { MarketPipe, UserPipe } from '../../../../shared/pipes/entity.pipe';
import { take } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { ChipModule } from 'primeng/chip';
import { MessageModule } from 'primeng/message';

@Component({
  selector: 'prf-delivery-products-editor',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    EntityDropdownComponent,
    InputTextModule,
    ReactiveFormsModule,
    FormsModule,
    FormatDatePipe,
    UserPipe,
    MarketPipe,
    DividerModule,
    ChipModule,
    MessageModule,
  ],
  templateUrl: './delivery-products-editor.component.html',
  styleUrls: ['./delivery-products-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryProductsEditorComponent {
  @Input()
  isDeliveredView: boolean = false;

  private _delivery!: IDelivery;
  @Input() set delivery(delivery: IDelivery) {
    this._delivery = JSON.parse(JSON.stringify(delivery));

    try {
      this._deliveryProducts = JSON.parse(JSON.stringify(delivery.deliveryProducts));
    } catch (e) {
      this._deliveryProducts = [];
    }

    if (this._deliveryProducts.length === 0) {
      this.addNewDeliveryProductRow();
    }
  }

  get delivery(): IDelivery {
    return this._delivery;
  }

  get isDelivered(): boolean {
    return this.delivery.status === DeliveryStatus.DELIVERED;
  }

  get isCompleted(): boolean {
    return this.delivery.status === DeliveryStatus.COMPLETED;
  }

  private _deliveryProducts!: CreatableEntity<IDeliveryProduct>[];
  get deliveryProducts(): CreatableEntity<IDeliveryProduct>[] {
    return this._deliveryProducts;
  }

  @Input({
    required: true,
  })
  products!: IProduct[];

  @Output()
  clickClose = new EventEmitter<void>();

  isSavingDeliveryProducts: boolean = false;
  isManualEditingDeliveryProducts: boolean = false;

  constructor(private store: Store) {}

  canDeleteProduct(isLast: boolean): boolean {
    if (this.isDelivered || this.isCompleted) {
      return false;
    }

    if (isLast) {
      return false;
    }

    return this.deliveryProducts.length > 1;
  }

  // onClickAddDeliveryProduct(): void {
  //   this.addNewDeliveryProductRow();
  // }

  quantityIsAbove99(value: number | null | undefined): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return value > 99;
  }

  onClickSaveDeliveryProducts(hasPostDeliveryCorrections: boolean = false): void {
    this.isSavingDeliveryProducts = true;
    this.store
      .dispatch(
        new UpdateDeliveryProducts({
          deliveryId: this.delivery.id,
          entities: this.deliveryProducts,
          hasPostDeliveryCorrections,
        }),
      )
      .pipe(take(1))
      .subscribe((_res) => {
        this.isSavingDeliveryProducts = false;
        this.isManualEditingDeliveryProducts = false;
      });
  }

  onClickDeleteDeliveryProduct(deliveryProduct: CreatableEntity<IDeliveryProduct>): void {
    this._deliveryProducts = this.deliveryProducts.filter((dP) => dP !== deliveryProduct);
    this.checkForNewDeliveryProductRow();
  }

  onClickInsertTemplateDelivery(): void {
    this._deliveryProducts = this.products.map((product) => ({
      deliveryId: this.delivery.id,
      productId: product.id,
      targetQuantity: 1,
    }));
  }

  checkForNewDeliveryProductRow() {
    if (this.deliveryProducts.length === 0) {
      return;
    }

    const lastDeliveryProduct = this.deliveryProducts[this.deliveryProducts.length - 1];
    if (lastDeliveryProduct.productId && lastDeliveryProduct.targetQuantity) {
      this.addNewDeliveryProductRow();
    }
  }

  onClickManualEditDeliveryProducts() {
    // TODO: editable only for admins
    this.isManualEditingDeliveryProducts = true;
    this.setDeliveryProductsManualEditingValues();
  }

  shouldDisablePreDeliveryAmountEditing(): boolean {
    if (this.isSavingDeliveryProducts) {
      return true;
    }

    return this.isDelivered || this.isCompleted;
  }

  // Note: Assuming "reset" of manual edits via CANCEL-button is done via CANCEL -> deselect delivery -> reOpen details / reload backend data.
  private setDeliveryProductsManualEditingValues(): void {
    this.deliveryProducts.forEach((dP) => {
      if (dP.actualQuantity === null) {
        dP.actualQuantity = dP.targetQuantity;
      }
      if (dP.returnQuantity === null) {
        dP.returnQuantity = 0;
      }
    });
  }

  private addNewDeliveryProductRow(): void {
    this.deliveryProducts.push({
      deliveryId: this.delivery.id,
      productId: null as any,
      targetQuantity: 0,
      actualQuantity: null,
      returnQuantity: null,
    });
  }

  isDeliveryCompleted() {
    return this.delivery.status === DeliveryStatus.COMPLETED;
  }

  protected readonly DeliveryStatus = DeliveryStatus;
}
