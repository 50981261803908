<p-autoComplete
  styleClass="p-button-secondary"
  appendTo="body"
  dataKey="value"
  [tabindex]="-1"
  [ngModel]="selectedEntityOption"
  [suggestions]="filteredOptions"
  [disabled]="disabled"
  [dropdown]="!disabled"
  [optionLabel]="labelField"
  [forceSelection]="true"
  [placeholder]="disabled ? 'Artikelname' : placeholderText"
  (onSelect)="onSelectEntityFromDropdown($event)"
  (completeMethod)="filterOptions($event)">
</p-autoComplete>
