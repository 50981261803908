import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { CreateVehicle, LoadVehicles, UpdateVehicle } from './vehicles.actions';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast/toast.service';
import {
  CreateVehicleMutation,
  CreateVehicleMutationVariables,
  CreateVehicleService,
  GetAllVehiclesService,
  UpdateVehicleService,
  VehicleFieldsFragment,
} from '../../graphql/vehicles-operations.generated';
import { IVehicle, PerfoEntityType } from '@prf/shared/domain';
import { MutationResult } from 'apollo-angular';
import { UpdateVehicleInput } from '../../graphql/_types.generated';
import { BaseEntityState } from './base.state';

type LocalModel = IVehicle;
type LocalStateModel = VehiclesStateModel;
type LocalStateContext = StateContext<VehiclesStateModel>;

export interface VehiclesStateModel {
  items: IVehicle[];
}

@State<VehiclesStateModel>({
  name: 'vehicles',
  defaults: {
    items: [],
  },
})
@Injectable()
export class VehiclesState
  extends BaseEntityState<
    {
      fetchAll: GetAllVehiclesService;
      create: CreateVehicleService;
      update: UpdateVehicleService;
    },
    IVehicle
  >
  implements NgxsOnInit
{
  protected entityType: PerfoEntityType = 'vehicle';

  constructor(
    protected getAllVehiclesService: GetAllVehiclesService,
    protected createVehicleService: CreateVehicleService,
    protected updateVehicleService: UpdateVehicleService,
    toastService: ToastService,
  ) {
    super(
      {
        fetchAll: getAllVehiclesService,
        create: createVehicleService,
        update: updateVehicleService,
      },
      toastService,
    );
  }

  protected getLoadAction(): object {
    return new LoadVehicles();
  }

  protected mapGqlEntityToUiEntity(entity: VehicleFieldsFragment): IVehicle {
    return {
      id: entity.id,
      licensePlate: entity.licensePlate,
      vehicleModel: entity.vehicleModel,
    };
  }

  @Selector()
  static items(state: LocalStateModel): LocalModel[] {
    return state.items;
  }

  @Action(LoadVehicles)
  protected loadVehicles(
    ctx: LocalStateContext,
    action: LoadVehicles,
  ): Observable<any> {
    return this.loadEntities<VehicleFieldsFragment[]>(ctx, 'vehicles');
  }

  @Action(CreateVehicle)
  createVehicle(
    ctx: LocalStateContext,
    action: CreateVehicle,
  ): Observable<MutationResult<CreateVehicleMutation>> {
    return this.createEntity<CreateVehicleMutationVariables>(
      ctx,
      action.payload.entity,
      'createVehicle',
    );
  }

  @Action(UpdateVehicle)
  updateVehicle(
    ctx: LocalStateContext,
    action: UpdateVehicle,
  ): Observable<any> {
    return this.updateEntity<UpdateVehicleInput>(
      ctx,
      action.payload.entity,
      'updateVehicle',
    );
  }
}
