import { ChangeDetectionStrategy, Component, NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ContentComponent } from "./content/content.component";
import { HeaderComponent } from "./header/header.component";
import { NgxsModule } from "@ngxs/store";
import { ProductsState } from "../../../state/entities/products.state";
import { VehiclesState } from "../../../state/entities/vehicles.state";
import { UiState } from "../../../state/ui.state";
import { RouteState } from "../../../state/route.state";
import { MarketsState } from "../../../state/entities/markets.state";
import { IngredientsState } from "../../../state/entities/ingredients.state";
import { RetailersState } from "../../../state/entities/retailers.state";
import { DeliveriesState } from "../../../state/entities/deliveries.state";
import { UsersState } from "../../../state/entities/users.state";
import { InvoicesState } from "../../../state/entities/invoices.state";

@NgModule({
  imports: [NgxsModule.forFeature([
    UiState,
    RouteState,
    ProductsState,
    MarketsState,
    IngredientsState,
    RetailersState,
    DeliveriesState,
    UsersState,
    InvoicesState,
    VehiclesState,
  ])],
})
export class NgxsMainStateModule {}


@Component({
  selector: 'prf-main-layout',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SidebarComponent,
    ContentComponent,
    HeaderComponent,
    NgxsMainStateModule,
  ],
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLayoutComponent {}

