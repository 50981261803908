<!--<pre>-->
<!--  {{numberOfWeekOptions | json}}-->
<!--  {{selectedCalendarWeekNumber | json}}-->
<!--  {{selectedCalendarWeekIndex | json}}-->
<!--</pre>-->

<p-paginator [rows]="1"
             [first]="selectedCalendarWeekIndex"
             [totalRecords]="numberOfWeekOptions"
             (onPageChange)="onPageChange($event)"
             [showJumpToPageDropdown]="false"
             [showPageLinks]="true"
             [showFirstLastIcon]="false"
></p-paginator>

<div style="color: #A0A0A0; text-align: center;">{{selectedCalenderWeekOption?.formattedFromDate}} – {{selectedCalenderWeekOption?.formattedToDate}}</div>
<div style="color: #A0A0A0; text-align: center; font-weight: bold;" class="mt-1">{{deliveriesCountForWeek}} Lieferungen</div>
<!--<div style="color: #A0A0A0; text-align: center;">{{selectedCalenderWeekOption?.formattedFromDate}} bis {{selectedCalenderWeekOption?.formattedToDate}}</div>-->
