import { Injectable } from '@angular/core';
import { ToggleSidebar } from '../../../state/ui.actions';
import { GoToEntityView } from '../../../state/route.actions';
import {
  Hotkey,
  HotkeysHelpComponent,
  HotkeysService as NgNeatHotkeysService,
} from '@ngneat/hotkeys';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';

@Injectable({
  providedIn: 'root',
})
export class PerfoHotkeysService {
  private readonly GOTO_SEQ_INIT_HK = 'g';
  private readonly CREATE_SEQ_INIT_HK = 'n';
  private registeredHotkeys = new Set<string>();

  constructor(
    private hotkeys: NgNeatHotkeysService,
    private store: Store,
    public dialogService: DialogService
  ) {
    // this.initHotkeysHelpDialog();
  }

  public enableHotkeys(): void {
    this.initHotkeys();
  }

  // TODO: add loading spinner oÄ (eg next to header route title), to inidicate that route loading/switching is triggered/inProgress.
  private initHotkeys(): void {
    // this.hotkeys
    //   .addShortcut({ keys: 's' })
    //   .subscribe((e) => this.store.dispatch(new ToggleSidebar()));

    const hotkeyConfig = [
      { keys: this.buildGoToKey('a'), action: new GoToEntityView('product') },
      {
        keys: this.buildGoToKey('z'),
        action: new GoToEntityView('ingredient'),
      },
      { keys: this.buildGoToKey('m'), action: new GoToEntityView('market') },
      { keys: this.buildGoToKey('h'), action: new GoToEntityView('retailer') },
      { keys: this.buildGoToKey('l'), action: new GoToEntityView('delivery') },
    ];

    hotkeyConfig.forEach((hotkey) => {
      this.addHotkeyWithAction(hotkey.keys, hotkey.action);
    });
  }
  public addHotkeyWithAction(keys: string, action: any): void {
    if (!this.registeredHotkeys.has(keys)) {
      this.registeredHotkeys.add(keys);
      if (keys.length === 1) {
        this.hotkeys.addShortcut({ keys }).subscribe(() => {
          this.store.dispatch(action);
        });
      } else {
        this.hotkeys.addSequenceShortcut({ keys }).subscribe(() => {
          this.store.dispatch(action);
        });
      }
    }
  }

  public addHotkeyWithCallback(
    keys: string,
    callback: (arg: Hotkey | KeyboardEvent) => void
  ): void {
    if (!this.registeredHotkeys.has(keys)) {
      this.registeredHotkeys.add(keys);
      if (keys.length === 1) {
        this.hotkeys.addShortcut({ keys }).subscribe(callback);
      } else {
        this.hotkeys.addSequenceShortcut({ keys }).subscribe(callback);
      }
    }
  }

  private buildGoToKey(suffix: string): string {
    return `${this.GOTO_SEQ_INIT_HK}>${suffix}`;
  }

  private initHotkeysHelpDialog() {
    this.hotkeys.registerHelpModal(() => {
      const ref = this.dialogService.open(HotkeysHelpComponent, {
        width: '500px',
      });
      ref.onClose.subscribe(() => ref.close());
    });
  }
}
