<div *ngIf="entity" class="card">
  <div class="p-fluid" style="padding: 23px;">
    <div class="p-field prf-field">
      <label for="ingredientName">Name</label>
      <input id="ingredientName" type="text" [(ngModel)]="entity.name" pInputText />
    </div>
    <div class="p-field prf-field">
      <label>Einheit</label>
      <div class="flex align-items-center">
        <ng-container  *ngFor="let unit of ingredientUnits; let i = index">
          <p-radioButton [name]="unit" [value]="unit" [(ngModel)]="entity.unit" [inputId]="'unit-' + i" />
          <label [for]="'unit-' + i" class="ml-2 mr-3">{{ unit }}</label>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<div class="flex justify-content-between">
  <p-button *ngIf="showClose"
            [outlined]="true"
            label="Schließen"
            icon="pi pi-times"
            styleClass="p-button-secondary"
            (click)="clickClose.emit()"
  />
  <p-button [label]="isCreate ? 'Anlegen' : 'Speichern'"
            styleClass="p-button-primary"
            (click)="onClickSave()" />
</div>

