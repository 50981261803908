import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ProductFieldsFragment = { __typename?: 'ProductEntity', id: number, productNo: string, description: string, category: string, ean: string, pack: string, uvp: number, grossPrice: number, netPrice: number, vatRate: number, recipe?: string | null, createdAt?: any | null, updatedAt?: any | null };

export type GetAllProductsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'ProductEntity', id: number, productNo: string, description: string, category: string, ean: string, pack: string, uvp: number, grossPrice: number, netPrice: number, vatRate: number, recipe?: string | null, createdAt?: any | null, updatedAt?: any | null }> };

export type CreateProductMutationVariables = Types.Exact<{
  input: Types.CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'ProductEntity', id: number, productNo: string, description: string, category: string, ean: string, pack: string, uvp: number, grossPrice: number, netPrice: number, vatRate: number, recipe?: string | null, createdAt?: any | null, updatedAt?: any | null } };

export type UpdateProductMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'ProductEntity', id: number, productNo: string, description: string, category: string, ean: string, pack: string, uvp: number, grossPrice: number, netPrice: number, vatRate: number, recipe?: string | null, createdAt?: any | null, updatedAt?: any | null } };

export const ProductFieldsFragmentDoc = gql`
    fragment ProductFields on ProductEntity {
  id
  productNo
  description
  category
  ean
  pack
  uvp
  grossPrice
  netPrice
  vatRate
  recipe
  createdAt
  updatedAt
}
    `;
export const GetAllProductsDocument = gql`
    query GetAllProducts {
  products {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllProductsService extends Apollo.Query<GetAllProductsQuery, GetAllProductsQueryVariables> {
    override document = GetAllProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation CreateProduct($input: CreateProductInput!) {
  createProduct(createProductInput: $input) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductService extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    override document = CreateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: Int!, $input: UpdateProductInput!) {
  updateProduct(id: $id, updateProductInput: $input) {
    ...ProductFields
  }
}
    ${ProductFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductService extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    override document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }