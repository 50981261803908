import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { IInvoice } from '@prf/shared/domain';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { SelectButtonModule } from 'primeng/selectbutton';
import { EntityDropdownComponent } from '../../entity-dropdown/entity-dropdown.component';
import { DividerModule } from 'primeng/divider';
import { take } from 'rxjs';
import { SendEmailDialogService } from '../../../services/dialogs/send-email-dialog/send-email-dialog.service';
import { Store } from '@ngxs/store';
import { SendInvoiceEmail } from '../../../../state/entities/invoices.actions';
import { GetPersistedInvoicePdf } from '../../../../state/ui.actions';

type LocalModel = IInvoice;

@Component({
  selector: 'prf-invoice-details',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    DropdownModule,
    EntityDropdownComponent,
    SelectButtonModule,
    DividerModule
  ],
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InvoiceDetailsComponent {
  @Input()
  isCreate: boolean = false;

  @Input()
  updateInProgress: boolean = false;

  isSendInvoiceEmailInProgress: boolean = false;

  // TODO: Temp "block" of additional changes after saving. Need to re-select.
  updateDone = false;

  private _entity!: LocalModel;

  @Input() set entity(entity: LocalModel) {
    this._entity = JSON.parse(JSON.stringify(entity));
  }

  get entity(): LocalModel {
    return this._entity;
  }

  @Output()
  entityChange = new EventEmitter<LocalModel>();

  private store = inject(Store);
  private cdRef = inject(ChangeDetectorRef);
  private sendEmailDialogService = inject(SendEmailDialogService);

  constructor() {
  }

  onClickSave() {
    this.entityChange.emit(this.entity);
  }

  onClickSendInvoiceEmail() {
    this.sendEmailDialogService
      .openSendInvoiceEmailDialog(this.entity)
      .pipe(take(1))
      .subscribe((closeResponse) => {
        if (closeResponse) {
          console.log(closeResponse);
          this.isSendInvoiceEmailInProgress = true;
          this.cdRef.detectChanges();
          this.store
            .dispatch(
              new SendInvoiceEmail({
                invoice: this.entity,
                recipientEmails: closeResponse.recipientEmails,
                subject: closeResponse.subject,
                message: closeResponse.message
              })
            )
            .pipe(take(1))
            .subscribe(
              (res) => {
                this.isSendInvoiceEmailInProgress = false;
                // TODO: update invoice view... needs to apply changes of invoiceEmailSent = true in order to show in SEND button
                this.cdRef.detectChanges();
              },
              (error) => {
                this.isSendInvoiceEmailInProgress = false;
                this.cdRef.detectChanges();
              }
            );
        }
      });
  }

  onClickShowInvoice() {
    this.store.dispatch(new GetPersistedInvoicePdf({ invoice: this.entity }));
  }
}
