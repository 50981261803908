<ng-container *ngIf="viewModel$ | async as viewModel">
  <p-toolbar>
    <div class="p-toolbar-group-start">
      <p-button *ngIf="!viewModel.isSidebarOpen"
                styleClass="p-button-outlined p-button-secondary"
                icon="pi pi-bars"
                pTooltip="Seitenleiste an/aus"
                size="small"
                (click)="onClickSidebarToggle()"></p-button>
    </div>
    <div class="p-toolbar-group-center">
      <span style="font-size: 1.5rem; letter-spacing: 0.1rem;">{{routeName$ | async}}</span>
    </div>
    <div class="p-toolbar-group-end" style="padding-right: 52px;">
      <p-splitButton styleClass="p-button-secondary"
                     label="Neu"
                     icon="pi pi-plus"
                     [model]="addEntityButtonItems"
                     (onClick)="onClickDefaultNew(viewModel.currentEntityType)"
                     [disabled]="viewModel.newEntityDialog.visible"
      ></p-splitButton>
    </div>
  </p-toolbar>
</ng-container>
