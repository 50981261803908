<!--<div style="background: #ffe4cd; padding: 10px;">-->
<div>
<!--  MainLayoutComponent-->
  <div class="sidebar">
    <prf-layout-sidebar />
  </div>
  <div class="header">
    <prf-layout-header />
  </div>
  <div class="content">
    <prf-layout-content />
  </div>
</div>
