import { Selector } from '@ngxs/store';
import { IIngredient } from '@prf/shared/domain';
import { IngredientsState } from '../state/entities/ingredients.state';

export interface IngredientsPageViewModel {
  ingredients: IIngredient[];
}

export class IngredientsPageViewModelQueries {
  @Selector([IngredientsState.items])
  static getViewModel(ingredients: IIngredient[]): IngredientsPageViewModel {
    return {
      ingredients: [...ingredients]
    };
  }
}
