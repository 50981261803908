import { IVehicle } from "@prf/shared/domain";

export class LoadVehicles {
  static type = '[Vehicles] Load Vehicles';

  constructor() {}
}

export class UpdateVehicle {
  static type = '[Vehicles] Update Vehicle';

  constructor(public payload: { entity: IVehicle }) {}
}

export class CreateVehicle {
  static type = '[Vehicles] Create New Vehicle';

  constructor(public payload: { entity: IVehicle }) {}
}
