import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from '../../shared/components/data-table/data-table.component';
import { DeliveryDeliveredViewComponent } from '../deliveries/components/delivery-delivered-view/delivery-delivered-view.component';
import { DeliveryDetailsComponent } from '../../shared/components/delivery/delivery-details/delivery-details.component';
import { DeliveryProductsEditorComponent } from '../deliveries/components/delivery-products-editor/delivery-products-editor.component';
import { TabViewModule } from 'primeng/tabview';
import { DeliveryPlanningDraggableViewComponent } from './components/delivery-planning-draggable-view/delivery-planning-draggable-view.component';
import { Select, Store } from '@ngxs/store';
import {
  DeliveryDragGroupingType,
  DeliveryPlanningDraggableViewPageViewModel,
  DeliveryPlanningDraggableWeekOverviewViewModelQueries,
} from '../../view-models/delivery-planning-draggable-week-overview-view-model.queries';
import { Observable, take } from 'rxjs';
import { IDelivery } from '@prf/shared/domain';
import { SelectButtonModule } from 'primeng/selectbutton';
import { FormsModule } from '@angular/forms';
import {
  ClearDeliveryPlanningOverviewSelectedDelivery,
  CloseNewWeekPlanningDialog,
  OpenNewWeekPlanningDialog,
  SetDeliveryPlanningDragGroupingType,
  SetDeliveryPlanningOverviewSelectedDelivery,
} from '../../state/ui.actions';
import { CalendarWeekPaginatorComponent } from './components/calendar-week-paginator/calendar-week-paginator.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { UpdateDelivery } from '../../state/entities/deliveries.actions';
import { DialogModule } from 'primeng/dialog';
import { PlanNewDeliveryWeekDialogComponent } from '../../shared/components/delivery/plan-new-delivery-week-dialog/plan-new-delivery-week-dialog.component';
import { ToggleEntityDetailsExpandDirective } from "../../shared/directives/toggle-entity-details-expand.directive";
import { ScrollPanelModule } from "primeng/scrollpanel";
import {
  MarketsNotServedDialogComponent
} from '../../shared/components/delivery/markets-not-served-dialog/markets-not-served-dialog.component';

// TODO: pass delivGroupViewMode (BY_WEEKDAY, BY_DRIVER) via route params

@Component({
  selector: 'prf-delivery-planning-view',
  standalone: true,
  imports: [
    CommonModule,
    DataTableComponent,
    DeliveryDeliveredViewComponent,
    DeliveryDetailsComponent,
    DeliveryProductsEditorComponent,
    TabViewModule,
    DeliveryPlanningDraggableViewComponent,
    SelectButtonModule,
    FormsModule,
    CalendarWeekPaginatorComponent,
    SplitButtonModule,
    DialogModule,
    PlanNewDeliveryWeekDialogComponent,
    ToggleEntityDetailsExpandDirective,
    ScrollPanelModule,
    MarketsNotServedDialogComponent,
  ],
  templateUrl: './delivery-planning-view.component.html',
  styleUrls: ['./delivery-planning-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryPlanningViewComponent {
  @Select(DeliveryPlanningDraggableWeekOverviewViewModelQueries.getViewModel)
  viewModel$!: Observable<DeliveryPlanningDraggableViewPageViewModel>;

  dragGroupingTypeOptions: {
    label: string;
    value: DeliveryDragGroupingType;
  }[] = [
    { label: 'Wochentage', value: 'BY_WEEKDAY' },
    { label: 'Fahrer', value: 'BY_DRIVER' },
  ];

  private store = inject(Store);
  private cdRef = inject(ChangeDetectorRef);

  // TODO REMOVE DEV:
  ngOnInit() {
    // this.onClickPlanNewWeek();
  }

  // </ REMOVE DEV
  isMarketsNotServedDialogVisible: boolean = false;

  onClickEditEntity($event: IDelivery) {
    this.store.dispatch(
      new SetDeliveryPlanningOverviewSelectedDelivery({
        selectedDetailsDelivery: $event,
      }),
    );
  }

  onDragGroupingTypeChange($event: DeliveryDragGroupingType): void {
    this.store.dispatch(
      new SetDeliveryPlanningDragGroupingType({
        dragGroupingType: $event,
      }),
    );
  }

  onClickPlanNewWeek() {
    this.store.dispatch(new OpenNewWeekPlanningDialog());
  }

  handleUpdateEntity($event: IDelivery) {
    this.store
      .dispatch(new UpdateDelivery({ entity: $event }))
      .pipe(take(1))
      .subscribe((result: any) => {
        // this.updateInProgress = false;
        this.cdRef.markForCheck();
      });
  }

  onPlanNewWeekDialogVisibleChange($event: boolean): void {
    if (!$event) {
      this.store.dispatch(new CloseNewWeekPlanningDialog());
    }
  }

  onNotServedMarketsDialogVisibleChange($event: boolean): void {
    if (!$event) {
      this.isMarketsNotServedDialogVisible = false;
    }
  }

  onClickCloseDeliveryDetails(): void {
    this.store.dispatch(new ClearDeliveryPlanningOverviewSelectedDelivery());
  }
}
