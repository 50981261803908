import { Selector } from '@ngxs/store';
import { ProductsState } from '../state/entities/products.state';
import { EIngredientUnit, IIngredient, IProduct, IRecipeIngredient } from "@prf/shared/domain";
import { IngredientsState } from "../state/entities/ingredients.state";

export interface ProductsPageViewModel {
  products: IProduct[];
  ingredients: IIngredient[];
}

export class ProductsPageViewModelQueries {
  @Selector([ProductsState.items, IngredientsState.items])
  static getViewModel(
    products: IProduct[],
    ingredients: IIngredient[]
  ): ProductsPageViewModel {
    const data = {
      products: [...products],
      // NOTE: v this calculation here leads to view model data in products view being UNDEFINED -> no data shown at all in view
      // products: products.map((p) => {
      //   return {
      //     ...p,
      //     recipe: {
      //       ...p.recipe,
      //       recipeIngredients: p.recipe.recipeIngredients.map((recIngr: IRecipeIngredient) => {
      //         return {
      //           ...recIngr,
      //           ingredient: {
      //             ...recIngr.ingredient
      //           }
      //         };
      //       }),
      //     },
      //   };
      // }),
      ingredients: [...ingredients],
    }
    return data;
  }
}
