import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IDelivery } from '@prf/shared/domain';
import { MarketPipe, UserPipe } from '../../../../shared/pipes/entity.pipe';
import { FormatDatePipe } from '../../../../shared/pipes/format-date.pipe';
import { PickListPlanningDelivery } from '../../../../shared/components/delivery/plan-new-delivery-week-dialog/plan-new-delivery-week-dialog.component';

type PickListPlanningDeliveryKeysToRemove = Record<keyof PickListPlanningDelivery, boolean>;

@Component({
  selector: 'prf-delivery-planning-item',
  standalone: true,
  imports: [CommonModule, UserPipe, FormatDatePipe, MarketPipe],
  templateUrl: './delivery-planning-item.component.html',
  styleUrls: ['./delivery-planning-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryPlanningItemComponent {

  @Input({ required: true })
  delivery!: IDelivery | PickListPlanningDelivery;

  @HostBinding('class.show-border')
  @Input()
  showBorder: boolean = true;

  // TODO: Refactor: Remove this and pass delivItem as template (slot) within draggable component and ggf other components.
  @Input()
  showDetailsButton: boolean = true;

  @HostBinding('class.selected')
  @Input()
  isSelected: boolean = false;

  // TODO: on hit details / open details: highlight selected entity in drag list.
  // TODO: provide (generic/same UX) way to close details col on the right side.
  @Output()
  clickDetails = new EventEmitter<IDelivery>();

  onClickDetails($event: MouseEvent): void {
    $event.stopPropagation();
    this.clickDetails.emit(this.prepareDeliveryForEmit(this.delivery));
  }

  isNewDelivery(): boolean {
    const delivery = this.delivery as PickListPlanningDelivery;

    return Boolean(delivery.isNewDelivery);
  }

  isScheduledTargetDeliveryMovedToSourceWeek(): boolean {
    const delivery = this.delivery as PickListPlanningDelivery;
    return (
      delivery.currentPlanningState &&
      delivery.planningOrigin &&
      delivery.currentPlanningState === 'SOURCE_WEEK' &&
      delivery.planningOrigin === 'TARGET_WEEK'
    );
  }


  // TODO(FIX):
    // szenario: move deliv from source to target => NEW deliv. open details of moved NEW target deliv.
    // problem: Deliv gets moved back to SOURCE.

  // TODO: Generell next: move from source to target <- MARK...
  // Frage: Was wird direkt editiert, was nicht?
  // Alles was von Source nach Target geschoben wird - NEU=TARGET, wird nicht auf dem Server editiert
  // ALles andere wird direkt auf dem Server editiert?
  // Oder allge

  // Alles was in ursprünglicher W... ?


  // TODO: Typing.
  private prepareDeliveryForEmit(delivery: any): IDelivery {
    // Remove keys from DTO.
    const keysToRemove: PickListPlanningDeliveryKeysToRemove = {
      // PickListPlanningDelivery
      currentPlanningState: true,
      planningOrigin: true,
      isNewDelivery: true,
      processedDelivery: true,
      photos: true,

      // IDelivery - keep all keys
      id: false,
      deliveryDate: false,
      deliveryDeliveredDate: false,
      driverId: false,
      driverNote: false,
      isActive: false,
      marketId: false,
      status: false,
      type: false,
      vehicleId: false,
      deliveryProducts: false,
      deliverySlipNumber: false,
      // TODO: Check if these keys should be removed...
      hasPostDeliveryCorrections: false,
      deliverySlipEmailSent: false,
    };

    // Check if delivery is of type PickListPlanningDelivery
    if ('planningOrigin' in delivery) {
      const strippedDelivery = this.stripPropertiesUsingRecord<
        PickListPlanningDelivery,
        typeof keysToRemove
      >(delivery, keysToRemove);
      return strippedDelivery as IDelivery;
    }

    // If this.delivery is not of type PickListPlanningDelivery, return it directly
    return this.delivery;
  }

  private stripPropertiesUsingRecord<T extends object, R extends Record<keyof T, boolean>>(
    obj: T,
    keysToRemove: R,
  ): Partial<T> {
    const result: Partial<T> = {};
    Object.keys(obj).forEach((key) => {
      if (!keysToRemove[key as keyof T]) {
        result[key as keyof T] = obj[key as keyof T];
      }
    });
    return result;
  }
}
