<fieldset>
  <div *ngIf="disabled" class="disabled-fieldset">
    <div style="margin-top: -14px">
      Wird vom Händler übernommen
    </div>
  </div>

    <legend>{{ label }}</legend>
    <div class="flex">
      <div class="p-field prf-field flex-grow-1">
        <label for="street">Straße</label>
        <input id="street"
               type="text"
               [(ngModel)]="address.street"
               [disabled]="disabled"
               pInputText />
      </div>
      <div class="p-field prf-field field-small ml-3">
        <label for="houseNumber">Hausnummer</label>
        <input id="houseNumber"
               type="text"
               [(ngModel)]="address.houseNumber"
               [disabled]="disabled"
               pInputText />
      </div>
    </div>

    <div class="flex">
      <div class="p-field prf-field field-small">
        <label for="postalCode">Postleitzahl</label>
        <input id="postalCode"
               type="text"
               [(ngModel)]="address.postalCode"
               [disabled]="disabled"
               pInputText />
      </div>
      <div class="p-field prf-field flex-grow-1 ml-3">
        <label for="city">Stadt</label>
        <input id="city"
               type="text"
               [(ngModel)]="address.city"
               [disabled]="disabled"
               pInputText />
      </div>
    </div>

    <div class="p-field prf-field">
      <label for="country">Land</label>
      <p-dropdown id="country" [(ngModel)]="address.country"
                  [options]="[
                    {label: 'Deutschland', value: 'Deutschland'}
                ]"
                  [disabled]="disabled"
                  optionValue="value"
                  optionLabel="label">
      </p-dropdown>
    </div>
</fieldset>
