import * as Types from './_types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type VehicleFieldsFragment = { __typename?: 'VehicleEntity', id: number, licensePlate: string, updatedAt?: any | null, createdAt?: any | null, vehicleModel: string };

export type GetAllVehiclesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllVehiclesQuery = { __typename?: 'Query', vehicles: Array<{ __typename?: 'VehicleEntity', id: number, licensePlate: string, updatedAt?: any | null, createdAt?: any | null, vehicleModel: string }> };

export type CreateVehicleMutationVariables = Types.Exact<{
  input: Types.CreateVehicleInput;
}>;


export type CreateVehicleMutation = { __typename?: 'Mutation', createVehicle: { __typename?: 'VehicleEntity', id: number, licensePlate: string, updatedAt?: any | null, createdAt?: any | null, vehicleModel: string } };

export type UpdateVehicleMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
  input: Types.UpdateVehicleInput;
}>;


export type UpdateVehicleMutation = { __typename?: 'Mutation', updateVehicle: { __typename?: 'VehicleEntity', id: number, licensePlate: string, updatedAt?: any | null, createdAt?: any | null, vehicleModel: string } };

export const VehicleFieldsFragmentDoc = gql`
    fragment VehicleFields on VehicleEntity {
  id
  licensePlate
  updatedAt
  createdAt
  vehicleModel
}
    `;
export const GetAllVehiclesDocument = gql`
    query GetAllVehicles {
  vehicles {
    ...VehicleFields
  }
}
    ${VehicleFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllVehiclesService extends Apollo.Query<GetAllVehiclesQuery, GetAllVehiclesQueryVariables> {
    override document = GetAllVehiclesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateVehicleDocument = gql`
    mutation CreateVehicle($input: CreateVehicleInput!) {
  createVehicle(createVehicleInput: $input) {
    ...VehicleFields
  }
}
    ${VehicleFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateVehicleService extends Apollo.Mutation<CreateVehicleMutation, CreateVehicleMutationVariables> {
    override document = CreateVehicleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateVehicleDocument = gql`
    mutation UpdateVehicle($id: Int!, $input: UpdateVehicleInput!) {
  updateVehicle(id: $id, updateVehicleInput: $input) {
    ...VehicleFields
  }
}
    ${VehicleFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateVehicleService extends Apollo.Mutation<UpdateVehicleMutation, UpdateVehicleMutationVariables> {
    override document = UpdateVehicleDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }