<div class="flex flex-row">
  <div class="col">
    <ng-container *ngIf="viewModel$ | async as viewModel">
      <prf-data-table
        [rowData]="viewModel.markets"
        [tableColumns]="TableConfig.columns"
        [loading]="loading"
        [selectedEntity]="selectedEntity"
        (selectionChange)="onEntitySelectionChange($event)" />
    </ng-container>
  </div>
  <div *ngIf="selectedEntity"
       prfToggleEntityDetailsExpand
       class="col col-data-details">
    <p-scrollPanel [style]="{ width: '100%', height: 'calc(100% - 20px)' }">

      <div class="card">
        <p-tabView [activeIndex]="0">
          <p-tabPanel header="Details">
            <prf-market-details [entity]="selectedEntity"
                                [updateInProgress]="updateInProgress"
                                [showClose]="true"
                                (clickClose)="onClickCloseMarketDetails()"
                                (updateEntity)="handleUpdateEntity($event)"
            />
            <div class="flex justify-content-end">
            </div>
          </p-tabPanel>
          <p-tabPanel header="Lieferungen" [disabled]="true">
            <div>filter by date</div>
            <div>delivery table (ggf mit collapsed columns, konfigurierbar)</div>
            <ul>
              <li>
                Eine Lieferugnszeile muss Aktionsbutton: Lieferung kopieren besitzen.
                <ul>
                  <li>Szenario: Wochenplanung bereits abgeschlossen. Markt ruft nachträglich an und will für Zielwoche X
                    "doch" eine Lieferung.
                  </li>
                </ul>
              </li>
            </ul>
          </p-tabPanel>
        </p-tabView>
      </div>
    </p-scrollPanel>
  </div>
</div>
