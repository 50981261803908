import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IVehicle } from '@prf/shared/domain';
import { Store } from '@ngxs/store';
import { VehicleDetailsComponent } from '../vehicle-details/vehicle-details.component';
import { CreateVehicle } from '../../../../state/entities/vehicles.actions';
import { take } from "rxjs";

type LocalModel = IVehicle;

@Component({
  selector: 'prf-vehicle-details-dialog',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    VehicleDetailsComponent,
  ],
  templateUrl: './vehicle-details-dialog.component.html',
  styleUrls: ['./vehicle-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleDetailsDialogComponent {
  entity: LocalModel;
  updateInProgress = false;

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private store: Store
  ) {
    this.entity = this.dialogConfig.data.entity;
  }

  handleCreateEntity(entity: LocalModel) {
    this.updateInProgress = true;
    this.store
      .dispatch(new CreateVehicle({ entity: entity }))
      .pipe(take(1))
      .subscribe((result) => {
        this.updateInProgress = false;
        this.dialogRef.close();
      });
  }
}
